
interface Props {
    text: string;
}
const DetailSubTitle = ({ text }: Props) => {
    return (
        <div className="font-noto-sans-kr font-bold text-[14px] leading-[20.27px] text-[#555555]">
            {text}
        </div>
    )
}

export default DetailSubTitle;