import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { styled } from "styled-components";

interface Props {
  tenantId: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  show: boolean;
  educationFlag?: boolean;
}

const ToolTipManage = ({
  tenantId,
  onMouseEnter,
  onMouseLeave,
  show,
  educationFlag,
}: Props) => {
  const educationType = educationFlag ? "edu" : "std";
  return (
    <Container
      $onShow={show}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {`${process.env.REACT_APP_ADMIN_URL}?tenantid=${tenantId}&type=${educationType}`}
    </Container>
  );
};
const Container = styled.span<{ $onShow: boolean }>`
  // display: ${(props) => (props.$onShow ? "block" : "none")};
  position: absolute;
  top: 35px;
  left: -35px;
  background-color: #000000;
  color: #fff;
  border-radius: 5px;
  padding: 4px;
  font-size: 12px;
  z-index: 1000;
  opacity: ${(props) => (props.$onShow ? 1 : 0)};
  visibility: ${(props) => (props.$onShow ? "visible" : "hidden")};
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
`;
export default ToolTipManage;
