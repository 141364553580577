import { styled } from "styled-components";
import WorkspaceHeader from "../../components/Header/WorkspaceHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { Cancellation, Hourglass } from "../../icons";
import { useEffect, useState } from "react";
import { toastError } from "../../utils/toast";
import { bool, boolean } from "yup";
import HourglassComponent from "../../components/Hourglass";
import usePolling from "../../hooks/use.http.polling";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../utils/request/axios.instance";
import LoadingDots from "../../components/Lodable/LoadingDots";

interface LocationState {
  adminId?: string;
  tenantId?: string;
}

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state: LocationState = location.state as LocationState;

  const { t } = useTranslation();

  const tenantId: string = useSelector(
    (state: RootState) => state.workspaceData.tenantId
  );
  const [startClick, setStartClick] = useState<boolean>(false);
  const { start, stop, data } = usePolling(30000, tenantId, () => {});

  const handleStartClick = () => {
    start();
    setStartClick(true);
  };

  useEffect(() => {
    axiosInstance.get("/member/warm");
    if (!state || !state.adminId || !state.tenantId) {
      toastError(t("common.wrongApproach"));
      navigate("/");
    }
    if (data) {
      stop();
      navigate("/team");
    }
  }, [data]);

  return (
    <>
      <div className="h-full w-full relative">
        <Box>
          <span
            onClick={() => {
              navigate("/");
            }}
            className="mr-2"
          >
            <Cancellation />
          </span>
        </Box>
        {startClick ? (
          <div className="h-screen flex justify-center items-center flex-col">
            <img
              className="rounded-[1000px]"
              src={`${process.env.PUBLIC_URL}/images/hourglass.gif`}
            />
            {/* <HourglassComponent className="mt-40"/> */}
            <LoadingDots
              text={t("workspace.create.ing")}
              className="mt-9 font-NotoSansKR text-[24px] text-[#1F62B9] font-semibold leading-35 tracking-normal text-center"
            />
            <div className="mt-3 font-noto-sans-kr text-[14px]  font-normal leading-20 tracking-normal text-center">
              {t("workspace.create.time")}
            </div>
          </div>
        ) : (
          <div className="absolute  w-full h-full flex flex-col pt-[130px] items-center">
            <div className="w-[400px] h-[130px] flex flex-col gap-4 items-center bg-[#F1F3F6] rounded-[4px]">
              <span className="mt-3 font-NotoSansKR text-[16px] font-semibold leading-23 tracking-normal text-center">
                {t("workspace.create.adminId")}
              </span>
              <span className="font-NotoSansKR text-[24px] font-semibold leading-35 tracking-normal text-center">
                {state?.adminId}
              </span>
            </div>

            <div className="mt-6 w-[400px] h-[130px] flex flex-col gap-4 items-center bg-[#F1F3F6] rounded-[4px]">
              <span className="mt-3 font-NotoSansKR text-[16px] font-semibold leading-23 tracking-normal text-center">
                {t("workspace.create.teamCode")}
              </span>
              <span className="font-NotoSansKR text-[24px] font-semibold leading-35 tracking-normal text-center">
                {state?.tenantId}
              </span>
            </div>

            <span className="mt-[25px] font-NotoSansKR text-[14px] font-normal leading-[20px] tracking-normal text-center text-[#555555]">
              {t("workspace.create.title")}
            </span>

            <button
              className="mt-5 w-[320px] h-[42px] rounded-[4px] bg-[#1F62B9] text-[#F1F3F6]"
              onClick={handleStartClick}
            >
              <p className="font-NotoSansKR text-[16px] font-semibold lading-23 tracking-normal text-center">
                {t("workspace.create.start")}
              </p>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

const Box = styled.div`
  background-color: white;
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 7%;
  max-height: 54px;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const HourglassWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
  svg {
    animation: rotate 2s linear infinite;
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export default SuccessPage;
