import React from "react";
import styled from "styled-components";
import { WhiteLogo } from "../../icons";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

interface Props{
    className?: string;
}

const Footer = ({ className }: Props) => {
    const { t } = useTranslation();
    const isScreenSM = useMediaQuery({maxWidth: 600});

    return (
        <FooterWrapper className={`${className ?? ''}`}>
            <WhiteLogoWarpper className="test">
                <Logo>
                    <WhiteLogo override={ isScreenSM ? {width: '140px', height: '30px'} : {}}/>
                </Logo>
            </WhiteLogoWarpper>
            <TextWrapper>
                <Text> <Strong>{t('footer.company')}</Strong> {t('footer.compnayName')}&emsp;|&emsp;<Strong>{t('footer.representative')}</Strong> {t('footer.name')}&emsp;|&emsp;<Strong>{t('footer.businessRegistrationNumber')}</Strong> 138-81-43598</Text>
                <Text><Strong>{t('footer.office')}</Strong> 031-786-7961&emsp;|&emsp;<Strong>{t('footer.email')}</Strong> sales@ucware.net</Text>
                <Text><Strong>{t('footer.address')}</Strong>&emsp; {t('footer.addressDetail')}</Text>
            </TextWrapper>
        </FooterWrapper>
    );
}

const FooterWrapper = styled.div`
    width: 100%;
    height: 240px;
    flex:1;
    background: #252525;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;
    gap: 80px;
    @media screen and (max-width: 600px) {
        padding-top: 5px;
        gap:20px;
        flex-direction: column;
        height: 210px;
        padding-bottom: 20px;
    }
`;

const Logo = styled.span`
    float: left;
`;


const WhiteLogoWarpper = styled.div`
    float: left;
    @media screen and (max-width: 658px) {
        padding-left: 30px;
    }
`;

const TextWrapper = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 600px) {
        width:100%;
        gap: 10px;
    }

    @media screen and (max-width: 658px) {
        padding-left: 30px;
        padding-right: 30px;
    }
`;

const Strong = styled.span`
    color: #D1D1D1;
    font-family: "Noto Sans KR";
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    @media screen and (max-width: 600px) {
        font-size: 10px;
    }
`;

const Text = styled.span`
    color: #D1D1D1;
    font-family: "Noto Sans KR";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media screen and (max-width: 600px) {
        font-size: 10px;
    }
`;

export default Footer;
