import { Controller } from "react-hook-form";
import { CardNumberForm, ReactHookFormProps } from "../../@types";
import Input from "../../ui/Input";
import Text from "../../ui/Text";
/**
 * @note 생년월일(YYYYMMDD) 또는 사업자번호
 */
const Identification = ({
  control,
  errors,
  setValue,
  getValues,
}: ReactHookFormProps<CardNumberForm>) => {
  return (
    <div className="relative">
      <Controller
        name="identification"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            maxLength={30}
            className="w-full h-[42px]"
            placeholder="생년월일(YYMMDD) 또는 사업자번호"
          />
        )}
      />
      {errors["identification"] && (
        <Text variant="danger" className="-top-5 right-0 absolute">
          {errors["identification"].message}
        </Text>
      )}
    </div>
  );
};

export default Identification;
