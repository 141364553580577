import Text from "../../ui/Text";
import Input from "../../ui/Input";
import InputCustom from "../Form/Input";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Checkbox from "../../ui/Checkbox";
import Button from "../../ui/Button";
import clsx from "clsx";
import MonthlyAnnualSelection from "./MonthlyAnnualSelection";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import PaymentInfoMethod from "./PaymentInfoMethod";
import ModalLayout from "../Modal/Layout";
import { ENTERPRISE, FREE, STANDARD } from "../../utils/common/license.price";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
// import { worksapcePaymentInfoSchema } from "../../utils/yupCustom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { AdditionalDataRequiredForProvisioning } from "../../@types";
import { setResetCardInfo } from "../../redux/slice/cardInfoListSlice";
import { useNavigate, useNavigationType } from "react-router-dom";
import * as yup from "yup";
import { setChecked } from "../../redux/slice/paymentChecboxSlice";
interface Props {
  workspaceName: string;
  licenseType: "Free" | "Standard" | "Enterprise" | "NONE";
  personnelLimit: number;
  region: string;
  provisioning: (paymentInfo: AdditionalDataRequiredForProvisioning) => void;
}

/**
 * @note email 뺴고 다 필요업고, 정확한 데이터가 들어오지않음
 */
interface PaymentInfoFormSubmitData {
  email?: string;
  workspaceName?: string; // 선택적 필드
  licenseType?: string; // 선택적 필드
  personnelLimit?: string; // 선택적 필드
  storage?: string; // 선택적 필드
}

const PaymentInfoForm = ({
  workspaceName,
  licenseType,
  region,
  personnelLimit,
  provisioning,
}: Props) => {
  const { t, i18n } = useTranslation();
  // yup 다른 파일에서 export시 인식 안되는 버그가 좀 다국어 떔누에 일루뺌
  const worksapcePaymentInfoSchema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        t("payment.valid.email")
      ),
    workspaceName: yup.string(),
    licenseType: yup.string(),
    personnelLimit: yup.string(),
    storage: yup.string(),
  });

  const selectedCardKey = useSelector(
    (state: RootState) => state.cardInfoList.selectedKey
  );

  const paymentCheckboxState = useSelector(
    (state: RootState) => state.paymentAgreedCheckbox.value
  );
  const dispatch = useDispatch<AppDispatch>();
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [monthlyAnnual, setMonthlyAnnual] = useState<"M" | "Y">("M");
  const [submitButton, setSubmitButton] = useState<"success" | "disable">(
    "disable"
  );
  const regularPaymentCheckbox = useRef<HTMLInputElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(worksapcePaymentInfoSchema),
  });

  const onSubmit = (data: PaymentInfoFormSubmitData) => {
    provisioning({
      email: data.email!,
      selectedCardKey: selectedCardKey!,
      monthlyAnnual: monthlyAnnual,
    });
  };
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [workspaceName, licenseType, personnelLimit]);
  return (
    <form
      className="flex flex-col gap-[30px] mb-10 w-[396px]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ReadOnlyInputWrapper>
        <InputCustom
          text={"워크스페이스 명"}
          name="workspaceName"
          register={register}
          errors={errors}
          tabIndex={-1}
          value={workspaceName}
          readOnly
        />
        <InputCustom
          text={"플랜"}
          name="licenseType"
          register={register}
          errors={errors}
          tabIndex={-1}
          value={licenseType}
          readOnly
        />
        <InputCustom
          text={"멤버 수"}
          name="personnelLimit"
          register={register}
          errors={errors}
          tabIndex={-1}
          value={personnelLimit.toString()}
          readOnly
        />
        <InputCustom
          text={"용량"}
          name="storage"
          register={register}
          errors={errors}
          tabIndex={-1}
          value={`${licenseType === "Enterprise" ? "1TB" : "100GB"}`}
          readOnly
        />
      </ReadOnlyInputWrapper>
      <InputWrapper>
        <InputCustom
          text={"이메일"}
          name="email"
          register={register}
          errors={errors}
          tabIndex={-1}
        />
      </InputWrapper>
      {/* <MonthlyAnnualSelection
        monthlyAnnual={monthlyAnnual}
        setMonthlyAnnual={setMonthlyAnnual}
      /> */}

      <div className="flex flex-col gap-2">
        <div className="w-[396px] h-[134px] space-y-2 p-[16px] flex flex-col border-[1px] border-solid border-[#DFDFDF] rounded-[4px]">
          <Text className="font-bold text-[14px]">Standard</Text>
          <Text className="font-normal text-[14px]">
            멤버 : {personnelLimit} x{" "}
            {costPerLicense({
              licenseType: licenseType,
              lang: i18n.language,
            })}
          </Text>
          <Text className="font-normal text-[14px]">
            용량 : {storageDefaultSize(licenseType)} (기본 제공)
          </Text>

          <Text className="font-bold text-[20px]">
            ={" "}
            {calculationOfLicenseCosts({
              licenseType,
              personnelLimit,
              lang: i18n.language,
            })}
          </Text>
        </div>

        <Text className="font-normal text-[14px] text-[#FF0000] mb-4">
          연간 정기 결제 시, 약 20%를 절약할 수 있습니다.
        </Text>
        <PaymentInfoMethod />
        <div className="flex felx-row gap-2 items-center mt-10 mb-2">
          <Checkbox
            ref={checkboxRef}
            checked={paymentCheckboxState}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              dispatch(setChecked({ value: e.target.checked }));
              if (e.target.checked && !!selectedCardKey) {
                setSubmitButton("success");
              } else {
                setSubmitButton("disable");
              }
            }}
          />
          <Text className="text-[#555555]">정기 결제에 동의합니다.</Text>
        </div>

        <Button
          variant={submitButton}
          className="w-[396px]"
          disabled={submitButton === "disable"}
          type="submit"
        >
          결제
        </Button>
      </div>
    </form>
  );
};

/**
 * @note 라이센스 타입에 따른 기본 디폴트 용량
 */
function storageDefaultSize(
  licenseType: "Free" | "Standard" | "Enterprise" | "NONE"
) {
  if (licenseType === "Free") {
    return "5GB";
  } else if (licenseType === "Standard") {
    return "100GB";
  } else if (licenseType === "Enterprise") {
    return "1TB";
  } else {
    return "0GB";
  }
}

/**
 * TODO 이 함수 고쳐야함
 * @returns
 */
function costPerLicense({
  licenseType,
  lang,
}: {
  licenseType: "Free" | "Standard" | "Enterprise" | "NONE";
  lang: string;
}): string {
  const getLicenseInfo = (
    license: "Free" | "Standard" | "Enterprise" | "NONE"
  ) => {
    switch (license) {
      case "Free":
        return lang === "JP" ? FREE.JP : FREE.KO;
      case "Standard":
        return lang === "JP" ? STANDARD.JP : STANDARD.KO;
      case "Enterprise":
        return lang === "JP" ? ENTERPRISE.JP : ENTERPRISE.KO;
      default:
        return { price: 0, currencySymbol: "" };
    }
  };

  const licenseInfo = getLicenseInfo(licenseType);

  return lang === "JP"
    ? licenseInfo.currencySymbol + licenseInfo.price
    : licenseInfo.price + licenseInfo.currencySymbol;
}

/**
 * @note 라이센스 비용계산
 */
function calculationOfLicenseCosts({
  licenseType,
  personnelLimit,
  lang,
}: {
  licenseType: "Free" | "Standard" | "Enterprise" | "NONE";
  personnelLimit: number;
  lang: string;
}) {
  const licenseMap = new Map<
    "Free" | "Standard" | "Enterprise" | "NONE",
    (options: { personnelLimit: number; lang: string }) => string
  >([
    ["Free", (options) => freeLicenseCost(options)],
    ["Standard", (options) => standardLicenseCost(options)],
    ["Enterprise", (options) => enterPriseLicenseCost(options)],
    ["NONE", (options) => "0"],
  ]);

  return licenseMap.get(licenseType)!({ personnelLimit, lang });
}

/**
 * @note Free 라이센스 비용계산
 */
function freeLicenseCost({
  personnelLimit,
  lang,
}: {
  personnelLimit: number;
  lang: string;
}): string {
  let licensePriceInfo;
  if (lang === "JP") {
    licensePriceInfo = FREE.JP;
    const licensePrice = licensePriceInfo.price * personnelLimit;
    return FREE.JP.currencySymbol + licensePrice.toLocaleString();
  } else {
    licensePriceInfo = FREE.KO;
    const licensePrice = licensePriceInfo.price * personnelLimit;
    return "₩" + licensePrice.toLocaleString() + FREE.KO.currencySymbol;
  }
}

/**
 * @note Standard 라이센스 비용계산
 */
function standardLicenseCost({
  personnelLimit,
  lang,
}: {
  personnelLimit: number;
  lang: string;
}) {
  let licensePriceInfo;
  if (lang === "JP") {
    licensePriceInfo = STANDARD.JP;
    const licensePrice = licensePriceInfo.price * personnelLimit;
    return STANDARD.JP.currencySymbol + licensePrice.toLocaleString();
  } else {
    licensePriceInfo = STANDARD.KO;
    const licensePrice = licensePriceInfo.price * personnelLimit;
    return "₩" + licensePrice.toLocaleString() + STANDARD.KO.currencySymbol;
  }
}

/**
 * @note Enterprise 라이센스 비용계산
 */
function enterPriseLicenseCost({
  personnelLimit,
  lang,
}: {
  personnelLimit: number;
  lang: string;
}) {
  let licensePriceInfo;
  if (lang === "JP") {
    licensePriceInfo = ENTERPRISE.JP;
    const licensePrice = licensePriceInfo.price * personnelLimit;
    return ENTERPRISE.JP.currencySymbol + licensePrice.toLocaleString();
  } else {
    licensePriceInfo = ENTERPRISE.KO;
    const licensePrice = licensePriceInfo.price * personnelLimit;
    return "₩" + licensePrice.toLocaleString() + ENTERPRISE.KO.currencySymbol;
  }
}

const Wrapper = styled.div`
  div > div > input {
    height: 42px;
    width: 396px;
    font-family: "Noto Sans KR", sans-serif;
    line-height: 20.27px;
    font-weight: 600;
  }
  @media screen and (max-width: 590px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 340px) {
    padding: 0 20px;
  }
`;

const ReadOnlyInputWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  div > div > input {
    background-color: #f1f3f6;
    color: #9d9ea2;
    font-size: 14px;
    border-width: 0px;
  }
`;

const InputWrapper = styled(Wrapper)``;

export default PaymentInfoForm;
