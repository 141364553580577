import { useRef, useState } from "react";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import { toastError, toastSuccess } from "../../utils/toast";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import { fetchWorkspaceDetailGET } from "../../redux/slice/workspaceDetailSlice";
import { AxiosError } from "axios";
import { error } from "console";
import { boolean } from "yup";
import { useTranslation } from "react-i18next";

interface Props {
    handleModal: (isOpen: boolean) => void;
    tenantId: string;
    id: string;
}

const ModifyPaymentEmail = ({ handleModal, tenantId, id }: Props) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const fetchPaymentEmailPUT = () => {
        if(inputRef.current && inputRef.current.value.trim()){
            const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
            if(!regex.test(inputRef.current.value)){
                setError(true);
                return;
            }else{
                setError(false);
            }

            axiosInstance.put('/workspace/payment/email',{
                tenantId: tenantId,
                paymentEmail: inputRef.current.value
            }, requestAuth({}))
            .then((data) => {
                toastSuccess(t('detail.changeSuccess'));
                dispatch(fetchWorkspaceDetailGET(id));
                handleModal(false);
            })
            .catch((error) => {
                if(error instanceof AxiosError && error.code === 'ERR_BAD_REQUEST'){
                    toastError(t('join.auth.fail'));
                }else{
                    toastError('SERVER ERROR');
                }
                handleModal(false);
            });
        }
    }

    return (
        <section ref={modalRef} className="w-[406px] h-[226px] px-[32px] py-[43px] rounded-[6px] shadow-workspace-name fixed top-[30%] left-[50%] ml-[-203px] z-50 bg-[#FFF] flex flex-col items-center justify-center gap-[25px]">
            <div className="font-noto-sans-kr leading-[20.27px] text-[14px]">
                {t('detail.emailChangeText')}
            </div>

            <div className="flex flex-col items-center">
                <input className="w-[320px] h-[42px] border border-solid border-[#C8CACE] p-[12px] rounded-[4px]" placeholder="email" ref={inputRef}/>
                {error && <span className="text-[12px] text-[red]">
                        {t('detail.emailTemplate')}
                    </span>}
            </div>
            
            <div className="flex flex-row gap-10">
                <button className="w-[100px] bg-[#FFFFFF] h-[36px] px-[20px] py-[8px] border-[1px] border-solid border-[#555555] rounded-[4px] flex items-center justify-center"
                    onClick={() => { handleModal(false) }}
                >
                    <span className="font-noto-sans-kr text-[11px] leading-[20.27px]">
                        {t('detail.cancel')}
                    </span>
                </button>
                
                <button className="w-[100px] bg-[#1F62B9] h-[36px] px-[20px] py-[8px] border-[1px] border-solid border-[#1F62B9] rounded-[4px] flex items-center justify-center"
                    onClick={() => {
                        fetchPaymentEmailPUT();
                    }}
                >
                    <span className="font-noto-sans-kr text-[14px] leading-[20.27px] text-[#FFFFFF]">
                        {t('detail.ok')}
                    </span>
                </button>

            </div>
        </section>
    )
}

export default ModifyPaymentEmail;
