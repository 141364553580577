import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import CardButton from "../Button/CardButton";
import { BottomArrow, EnterpriseCheckIcon } from "../../icons";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import SpredComponent from "./Spred";

interface Props {
  onClick: (license: PriceLicense) => void;
  price: number;
  educationFlag: boolean;
}

const EnterpriseCard = ({ onClick, price, educationFlag }: Props) => {
  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isScreenMD = useMediaQuery({ maxWidth: "802px" });
  const textStyle =
    "font-noto-sans-kr text-[14px] font-normal leading-[20px] tracking-normal";
  const handleSpredClick = () => {
    setIsExpanded((isExpanded) => !isExpanded);
  };

  const licenseDTO: PriceLicense = {
    license: "Enterprise",
    licensePrice: price,
    monetaryUnit: i18n.language === "ko" ? "WON" : "YEN",
    storage: 1024,
    educationFlag: educationFlag,
  };

  const textSize = i18n.language !== "ko" ? "text-[11px]" : "text-[15px]";
  const licensePrice =
    i18n.language === "jp"
      ? "¥" + price
      : price + " " + t("workspace.monetaryUnit");

  return (
    <Container $isExpended={isExpanded}>
      <div className="flex flex-col items-center margin-0 absolute top-0 gap-0 pt-5 mb-[40px] w-full">
        <div className="w-[113px] font-bold text-[#9C52E6] text-[22px]">
          Enterprise
        </div>
        <div className="text-[30px] font-bold font-noto-sans-kr mt-2">
          {licensePrice}
        </div>
        <div className="text-[#777777] text-[13px] font-normal leading-[18.82px] my-[4px]">
          {t("card.standard.summary")}
        </div>
        <div
          className={`text-center w-[235px] h-[44px] font-noto-sans-kr font-normal leading-[21.72px] my-[6px] ${textSize}`}
        >
          {t("card.enterprice.description")}
        </div>

        {/*<CardButton onClick={onClick} text={t('card.free.btnText')} color={"#4067F6"}/>*/}
        <CardButton
          onClick={onClick}
          text={t("card.enterprice.btnText")}
          color={"#9C52E6"}
          licenseData={licenseDTO}
        />
        <div className="w-full h-[1px] bg-[#DFDFDF] mt-4"></div>

        <div className=" h-[20px] w-full mt-1 flex items-center">
          <SpredComponent
            isExpanded={isExpanded}
            text={isExpanded ? t("common.folding") : t("common.expand")}
            handleSpredClick={handleSpredClick}
          />
        </div>

        <div
          className={`flex flex-col w-full gap-0 ${
            isExpanded && isScreenMD
              ? "block"
              : `${!isScreenMD ? "block" : "hidden"}`
          }`}
        >
          <div className="pl-4 pt-1 space-y-1.5">
            <p className="text-[#000000] text-[14px] font-semibold font-noto-sans-kr leading-[20.27px]">
              {t("card.enterprice.subCategory")}
            </p>
            <div className="flex items-center gap-1 pl-1">
              <EnterpriseCheckIcon />
              <span className={textStyle}>{t("card.enterprice.users")}</span>
            </div>
            <div className="flex items-center gap-1 pl-1">
              <EnterpriseCheckIcon />
              <span className={textStyle}>{t("card.enterprice.storage")}</span>
            </div>
            <div className="flex items-center gap-1 pl-1">
              <EnterpriseCheckIcon />
              <span className={textStyle}>
                {t("card.enterprice.storageAdd")}
              </span>
            </div>
            <div className="flex items-center gap-1 pl-1">
              <EnterpriseCheckIcon />
              <span className={textStyle}>
                {t("card.enterprice.fileUpload")}
              </span>
            </div>
          </div>

          {/* <div className="pl-4 pt-3 space-y-1.5">
                        <p className="text-[#000000] text-[14px] font-semibold font-noto-sans-kr leading-[20.27px]">
                            {t("card.enterprice.plusPrice")}
                        </p>
                        <div className="flex items-center gap-1 pl-1">
                            <EnterpriseCheckIcon/>
                            <span className={textStyle}>{t("card.enterprice.video")}</span>
                        </div>
                        <div className="flex items-center gap-1 pl-1">
                            <EnterpriseCheckIcon/>
                            <span className={textStyle}>{t("card.enterprice.linkedSystem")}</span>
                        </div>
                        <div className="flex items-center gap-1 pl-1">
                            <EnterpriseCheckIcon/>
                            <span className={textStyle}>{t("card.enterprice.linkedPush")}</span>
                        </div>
                    </div> */}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div<{ $isExpended: boolean }>`
  width: 300px;
  height: 760px;
  flex-shrink: 0;

  border-radius: 14px;
  border: 1px solid #dfdfdf;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 802px) {
    ${(props) =>
      props.$isExpended
        ? css`
            height: 760px;
          `
        : css`
            height: 294px;
          `}
    width: 90%;
  }
`;

export default EnterpriseCard;
