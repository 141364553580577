import React from 'react';

interface Props {
  imageUrls: string[],
  right: boolean
}

const SliderItem = ({ imageUrls, right }: Props ) => {

    const extendedImageUrls = [...imageUrls, ...imageUrls, ...imageUrls, ...imageUrls];
    return (
        <div className="overflow-hidden whitespace-nowrap">
          <div className={`${right ? 'animate-slideLeft' : 'animate-slideRight'} space-x-20 w-[1960px]`}>
            {extendedImageUrls.map((url, index) => (
              <img
                key={index}
                src={url}
                alt={`Image ${index}`}
                className="inline-block w-[200px] h-[44px]"
              />
            ))}
          </div>
        </div>
      );
}

export default SliderItem;

