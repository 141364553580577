import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import DetailSubTitle from "../Text/DetailSubTitle";
import Text from "../../ui/Text";
import {
  formatDateToYearMonth,
  formatDateYYYYMMDD,
} from "../../utils/common/dateFormat";
import { useEffect, useState } from "react";
import { calculationOfLicenseCosts } from "../../utils/common/licensePrice";
import { averageLicensePayment } from "../../utils/request";
import Skeleton from "react-loading-skeleton";

interface Props {
  workspaceId: number;
}

const PaymentInfo = ({ workspaceId }: Props) => {
  const workspaceDetail = useSelector(
    (state: RootState) => state.workspaceDetail.workspaceInfo
  );
  const [priceInfo, setPriceInfo] = useState<{
    totalPrice: string | null;
    personnelLimit: number | null;
    licenseUnitPrice: number | null;
    licenseStorageSize: number | null;
  }>();
  const [nextPaymentDate, setNextPaymentDate] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (workspaceDetail?.nextPaymentDate) {
      setNextPaymentDate(
        formatDateYYYYMMDD(new Date(workspaceDetail.nextPaymentDate))
      );
    }
    averageLicensePayment({
      workspaceId: workspaceId,
      setLoading,
    })
      .then((data: any) => {
        setPriceInfo({ ...data.data });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return SkeletonUI();
  }
  return (
    <section className="space-y-2">
      <DetailSubTitle text={"결제 예정"} />
      <div className="border-[#DFDFDF] border-[1px] rounded-[14px] h-[145px] flex flex-col gap-1 px-5 items-start justify-center">
        <Text className="text-[14px] text-[#555555]">
          {formatDateToYearMonth()} 사용량에 대해
        </Text>
        <Text className="text-[14px] text-[#555555]">
          멤버: {workspaceDetail?.personnelLimit} x{" "}
          {priceInfo?.licenseUnitPrice?.toLocaleString()}원
        </Text>
        <Text className="text-[14px] text-[#555555]">
          용량: {Math.floor(priceInfo?.licenseStorageSize! / 1024) * 100000}원
          (기본제공)
        </Text>
        <Text className="text-[18px] text-[#555555] font-medium-">
          <span className="font-bold">
            총 {Number(priceInfo?.totalPrice).toLocaleString()}원
          </span>
          이 {nextPaymentDate}에 결제 예정입니다.
        </Text>
      </div>
    </section>
  );
};

const SkeletonUI = () => {
  return (
    <section className="space-y-2">
      <DetailSubTitle text={"결제 예정"} />
      <div className="border-[#DFDFDF] border-[1px] rounded-[14px] h-[145px] flex flex-col gap-1 px-5 items-start justify-center">
        <Text className="text-[14px] text-[#555555]">
          <Skeleton width={100} height={20} />
        </Text>
        <Text className="text-[14px] text-[#555555]">
          <Skeleton width={140} height={20} />
        </Text>
        <Text className="text-[14px] text-[#555555]">
          <Skeleton width={140} height={20} />
        </Text>
        <Text className="text-[18px] text-[#555555] font-medium-">
          <Skeleton width={180} height={23} />
        </Text>
      </div>
    </section>
  );
};

export default PaymentInfo;
