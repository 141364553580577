import clsx from "clsx";
import { ComponentPropsWithRef, ElementType, forwardRef, Ref } from "react";

type RadioSizeVariants = "lg" | "sm" | "md" | "none";

type VariantStyles = {
  [key in RadioSizeVariants]: {
    default: string;
  };
};

type RadioProps = {
  variant?: VariantStyles;
};

const Radio = forwardRef(
  <T extends ElementType = "input">(
    {
      className,
      variant = "none",
      ...props
    }: ComponentPropsWithRef<T> & RadioProps,
    ref: Ref<any>
  ) => {
    const variantStyles: any = {
      lg: {
        default: "",
      },
      sm: {
        default: "",
      },
      md: {
        default: "",
      },
      none: {
        default: "w-[14px] h-[14px] rounded-[100%]",
      },
    };
    return (
      <div className="flex items-center">
        <input type="checkbox" id="check1" className="hidden peer" {...props} />
        <label
          htmlFor="check1"
          className={clsx(
            "inline-block w-4 h-4 border-2 border-solid border-[#CED4DA] cursor-pointer rounded-full relative",
            "after:content-[''] after:absolute after:top-1/2 after:left-1/2 after:w-2 after:h-2 after:bg-white after:rounded-full after:transform after:-translate-x-1/2 after:-translate-y-1/2",
            "peer-checked:after:bg-blue-500"
          )}
          style={{
            backgroundSize: "20%", // 배경 이미지 크기 조정
            backgroundPosition: "center center", // 배경 이미지 위치 조정
            backgroundRepeat: "no-repeat",
          }}
        ></label>
      </div>
    );
  }
);

export default Radio;
