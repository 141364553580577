import styled, { css } from "styled-components";

export interface Profile {
  id: number;
  country: string;
  name: string;
  email: string;
  phoneNumber: string;
  company: string;
  companyPhoneNumber: string;
  iat: number;
  exp: number;
}

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 18px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  max-height: 54px;
  z-index: 500;
  flex-shrink: 1;
  padding: 16px 36px;
  position: fixed;
  background-color: white;
  left: 0;
  right: 0;

  & section:nth-of-type(1) > div {
    background-image: url("/images/logo.png");
    background-size: 100%;
    background-repeat: no-repeat;
    height: 40px;
    width: 181px;
    margin-top: 9px;
  }

  & section:nth-of-type(2) {
    color: #333333;
  }

  @media (max-width: 1024px) {
    height: 48px;
    padding: 12px 25px;
  }

  @media (max-width: 768px) {
    height: 48px;
    padding: 12px 25px;
  }
`;

export const LogoImg = styled.img`
  width: 155px;
  height: 35px;
  flex-shrink: 0;
  cursor: pointer;
  @media screen and (max-width: 439px) {
    height: 30px;
    width: 134px;
    margin-right: 13px;
  }
`;

export const Logo = styled.div`
  background-size: 100%;
  width: 140px;
  height: 20px;
  cursor: pointer;
`;

export const Navbar = styled.ul`
  position: absolute;
  left: 37%;
  display: flex;
  flex-direction: row;
  text-decoration: none;

  li {
    margin-left: 24px;
    border: 1px solid black;
    width: 84px;
    height: 55px;
    display: flex;
    margin-top: 4px;
    justify-content: center;
    align-items: center;
  }

  li > a {
    text-decoration: none;
  }

  .active {
    background-color: black;
  }
`;

export const TransparentButton = styled.button`
  font-family: "Montserrat";
  font-weight: 500;
  letter-spacing: -1px;
  text-align: left;
  padding: 10px 21px 9px 22px;
  border-radius: 20px;
  color: inherit;
  border: 2px solid;
  border-color: inherit;
  cursor: pointer;
  position: relative;
  background-color: transparent;
`;

export const LangMenu = styled.ul<{ isLanguageDropdownOpen: boolean }>`
  display: ${(props) => (props.isLanguageDropdownOpen ? "block" : "none")};
  width: 240px;
  position: absolute;
  top: 65px;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 8%);
  padding: 20px;
  background-color: #fff;
  z-index: 11;

  &:after {
    content: "";
    position: absolute;
    top: -5px;
    left: 10px;
    background-color: #fff;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
  }
`;

export const LanguageItem = styled.li<{
  isHighlighted: boolean;
  isSelected: boolean;
}>`
  padding: 6px;
  background-color: #fff;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 25px;
  color: ${(props) =>
    props.isHighlighted || props.isSelected ? "#4067f6" : "#555555"};
  cursor: pointer;
`;

export const ProfileWrapper = styled.div`
  position: absoulte;
  width: 80px;
  display: flex;
  justify-content: center;
`;

export const ProfileButtom = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  color: inherit;
`;

export const ProfileInfo = styled.ul<{ isMenuDropdownOpen: boolean }>`
  ${(props) => {
    return css`
      display: ${props.isMenuDropdownOpen ? "block" : "none"};
      width: 240px;
      position: absolute;
      top: 65px;
      right: 40px;
      box-shadow: 0 10px 16px 0 rgb(0 0 0 / 8%);
      padding: 20px;
      background-color: #fff;
      z-index: 11;

      &:after {
        content: "";
        position: absolute;
        top: -5px;
        right: 10px;
        background-color: #fff;
        width: 30px;
        height: 30px;
        transform: rotate(45deg);
      }
    `;
  }}
`;

export const StyledMenuItem = styled.li<{
  isHighlighted: boolean;
  index: number;
}>`
  padding: 6px;
  background-color: #fff;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 25px;
  color: ${(props) =>
    props.isHighlighted && props.index !== 0 ? "#4067f6" : "#555555"};
  cursor: pointer;
`;

export const LoginButton = styled.button<{ $clicked: boolean; $show: string }>`
  border: 0px;
  display: ${(props) => props.$show};
  height: 36px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #1f62b9;
  color: #fff;
  text-align: center;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover {
    background: ${(props) => (props.$clicked ? "#32418E" : "#599AEE")};
  }
`;

// export const DropDownWrapper = styled.div<{hoverColor: string}>`
//     .drop-down-btn:hover{
//         colo: ${(props) => props.hoverColor}
//     }

// `;
export const DropDownWrapper = styled.div`
  padding-top: 2.4px;
`;
export const NavStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
