import styled from "styled-components";
import Button from "../../ui/Button";
import { Plus } from "../../icons";
import { lazy, useEffect, useState } from "react";
import Loader from "../../components/Loader";
import Text from "../../ui/Text";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import { error } from "console";
import clsx from "clsx";
import PaymentMethodBox from "../../components/PaymentMethodBox";
import { paymentAdminPageFetchData } from "../../utils/request";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { fetchMemberInfo } from "../../redux/slice/memberSlice";
import { useNavigate } from "react-router-dom";
import { toastError } from "../../utils/toast";
import BillingForm from "../../components/BillingForm";
import { PaymentInfoType } from "../../@types";
import ModalLayout from "../../components/Modal/Layout";

const PaymentAdminPage = () => {
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const [paymentInfos, setPaymentInfos] = useState<PaymentInfoType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchMemberInfo()).then((info) => {
      if (info.payload.data === -1) {
        navigate("/login");
      }
    });

    paymentAdminPageFetchData({ setLoading })
      .then((data) => {
        setPaymentInfos(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [isVisible]);

  return (
    <>
      <section className="pt-[140px] w-full relative">
        <div className="text-[#000] text-center font-noto-sans-kr text-[36px] font-bold">
          결제수단 관리
        </div>

        <div className="w-[100%] mb-[60px] pt-[50px] flex flex-col items-center justify-center">
          <div className="w-[100%] flex justify-center">
            <table className="w-[780px] mt-[45px]">
              <thead>
                <tr className="border border-[#ccc] h-[48px]">
                  <Th text="유형" />
                  <Th text="번호" />
                  <Th text="관리" />
                </tr>
              </thead>
              <tbody>
                {!paymentInfos.length && !loading && (
                  <tr>
                    <td colSpan={3} className="h-[110px] text-center">
                      <Text className="text-[#555555] text-[14px] font-medium">
                        결제 수단을 등록하세요.
                        <br />
                        워크스페이스의 요금을 결제할 수 있습니다.
                      </Text>
                    </td>
                  </tr>
                )}
                {loading ? (
                  <tr>
                    <td colSpan={4} rowSpan={3} className="h-[200px]">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  paymentInfos.map((data, index) => {
                    return (
                      <tr
                        className="border-[#ccc] border h-[48px] text-center"
                        key={index}
                      >
                        <td>
                          <Text className="text-[#555555] text-[14px]">
                            {data.kind}
                          </Text>
                        </td>
                        <td>
                          <Text className="text-[#555555] text-[14px]">
                            {data.cardNumber}
                          </Text>
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            className="w-[66px] h-[36px]"
                            onClick={() => {}}
                          >
                            삭제
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <Button
            variant="primary"
            className="flex items-center justify-center gap-2 w-[165px] h-[42px] my-[30px]"
            onClick={() => {
              document.body.style.overflow = "hidden";
              setIsVisible((visible) => !visible);
            }}
          >
            <Plus />
            <div className="font-semibold font-noto-sans-kr text-[16px] leading-1 text-center">
              결제 수단등록
            </div>
          </Button>
        </div>
        <PaymentMethodBox
          isVisible={isVisible}
          setIsVisible={(state: boolean) => {
            setIsVisible(state);
          }}
        />
      </section>
    </>
  );
};

const Th = ({ text }: { text: string }) => {
  return (
    <th className="font-noto-sans-kr leading-[20.27px] text-[14px] w-1/3">
      {text}
    </th>
  );
};
export default PaymentAdminPage;
