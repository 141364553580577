import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import InputCustom from "../../components/Form/Input";
import SelectBox from "../../components/Common/SelectBox";
import FormButton from "../../components/Button/FormButton";
import { useCustomForm } from "../../utils/yupCustom";
import axiosInstance, {
  requestUnAuth,
} from "../../utils/request/axios.instance";
import useLoading from "../../utils/useLoading";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { toastError, toastSuccess } from "../../utils/toast";
import JoinAgreeForm from "../../components/JoinAgreeForm";
import { useMediaQuery } from "react-responsive";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import Loadable from "../../components/Lodable";
import { useTranslation } from "react-i18next";

const JoinPage = () => {
  const { t } = useTranslation();

  const isScreenSM = useMediaQuery({ maxWidth: 768 });
  const commonWidth = isScreenSM ? "280px" : "340px";
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState<string>("KO");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useCustomForm<MemberDTO>();
  const [loading, setLoading] = useState<boolean>(false);
  const [joinAgree, setJoinAgree] = useState<boolean>(false);

  const postMemberRequest = async (dto: MemberDTO) => {
    setLoading(true);
    const { data } = await axiosInstance.post(
      "/member/join",
      dto,
      requestUnAuth({})
    );
    return data;
  };

  const selectedBoxtChange = (selectedText: string) => {
    setSelectedCountry(selectedText);
  };

  const onSubmit = (data: MemberDTO) => {
    data.country = selectedCountry;

    postMemberRequest(data)
      .then((data) => {
        if (data) {
          setLoading(false);
          navigate("/mail");
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response?.status === 400) {
            setLoading(false);
            toastError(t("join.mail.exist"));

            setError("email", {
              type: "custom",
              message: t("join.mail.exist"),
            });
          }
        }
      });
  };

  useEffect(() => {
    axiosInstance.get("/member/warm");
  }, []);

  return (
    <Container $commonWidth={commonWidth}>
      <>
        {joinAgree ? (
          <Loadable loading={loading}>
            <div className="mt-[80px] h-[830px] border-[1px] mb-8">
              <h3 className="text-[36px] font-black leading-[52.13px] mt-8 text-center mb-3">
                {t("join.page.signup")}
              </h3>
              <div
                className="text-[14px] text-center mb-3"
                dangerouslySetInnerHTML={{
                  __html: t("join.page.subTitle"),
                }}
              />
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={`flex flex-col items-center gap-6 ${
                  isScreenSM ? "w-[340px]" : "w-[420px]"
                }`}
              >
                <div className="flex flex-col items-center gap-8 ">
                  <InputCustom
                    text={t("join.page.email")}
                    name="email"
                    register={register}
                    errors={errors}
                  />
                  <InputCustom
                    text={t("join.page.pwd")}
                    name="password"
                    register={register}
                    errors={errors}
                  />
                  <InputCustom
                    text={t("join.page.pwdConfirm")}
                    name="passwordConfirm"
                    register={register}
                    errors={errors}
                  />
                  <InputCustom
                    text={t("join.page.name")}
                    name="name"
                    register={register}
                    errors={errors}
                  />
                  <InputCustom
                    text={t("join.page.phoneNumber")}
                    name="phoneNumber"
                    register={register}
                    errors={errors}
                  />
                  <InputCustom
                    text={t("join.page.companyName")}
                    name="company"
                    register={register}
                    errors={errors}
                  />
                  <InputCustom
                    text={t("join.page.companyTellNumber")}
                    name="companyCallNumber"
                    register={register}
                    errors={errors}
                  />
                  <div>
                    <SelectBox setStateProps={selectedBoxtChange} />
                    <span className="font-semibold font-noto-sans-kr text-[12px] text-gray-500">
                      {t("join.page.emailSelectText")}
                    </span>
                  </div>
                </div>

                <FormButton
                  text={t("join.page.joinBtn")}
                  className={`${isScreenSM ? "w-[280px]" : "w-[340px]"}`}
                ></FormButton>
              </form>
            </div>
          </Loadable>
        ) : (
          <JoinAgreeForm
            agree={() => {
              setJoinAgree(true);
            }}
          />
        )}
      </>
    </Container>
  );
};

const Consent = styled.div`
  margin-top: 10px;
  margin-right: 18px;
  display: flex;
  color: #555;

  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 600px) {
    gap: 3px;
    font-size: 12px;
  }
`;

const Container = styled.div<{ $commonWidth: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  #country-selected-box {
    width: ${(props) => props.$commonWidth};
  }

  .google-input input {
    width: ${(props) => props.$commonWidth};
  }
`;

export default JoinPage;
