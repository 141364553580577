import { useNavigate } from "react-router-dom";
import { BackIcon, Cancellation } from "../../icons";


interface Props{
    backIcon?: boolean; 
}

const AddHeader = ({ backIcon }: Props) => {

    const navigate = useNavigate();

    return (
        <div className="w-full h-[72px] relative flex items-center">
            {
                backIcon && <div className="absolute left-5" onClick={() => navigate(-1)}>
                                <BackIcon/>
                            </div>
            }

            <div className="absolute right-7 cursor-pointer" onClick={() => navigate(-1)}>
                <Cancellation/>
            </div>            
        </div>
    )
}

export default AddHeader;
