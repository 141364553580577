import clsx from "clsx";
import { ComponentPropsWithRef, ElementType, forwardRef, Ref } from "react";

type CheckBoxSizeVariants = "lg" | "sm" | "md" | "none";

type VariantStyles = {
  [key in CheckBoxSizeVariants]: {
    default: string;
  };
};

type CheckboxProps = {
  variant?: VariantStyles;
};

const Checkbox = forwardRef(
  <T extends ElementType = "input">(
    {
      className,
      variant = "none",
      ...props
    }: ComponentPropsWithRef<T> & CheckboxProps,
    ref: Ref<any>
  ) => {
    const variantStyles: any = {
      lg: {
        default: "",
      },
      sm: {
        default: "",
      },
      md: {
        default: "",
      },
      none: {
        default: "w-[14px] h-[14px] rounded-[4px]",
      },
    };
    return (
      <div className="flex items-center">
        <input type="checkbox" id="check1" className="hidden peer" {...props} />
        <label
          htmlFor="check1"
          className={clsx(
            "inline-block w-8 h-8 border-[1px] border-gray-400 cursor-pointer peer-checked:bg-check-box peer-checked:bg-cover peer-checked:bg-center peer-checked:bg-no-repeat peer-checked:border-0",
            variantStyles[variant].default
          )}
        ></label>
      </div>
    );
  }
);

export default Checkbox;
