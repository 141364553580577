import { Controller } from "react-hook-form";
import { CardNumberForm, ReactHookFormProps } from "../../@types";
import Input from "../../ui/Input";
import { ChangeEvent, useEffect, useState } from "react";
import Text from "../../ui/Text";

const NameInput = ({
  control,
  errors,
  setValue,
  getValues,
  trigger,
}: ReactHookFormProps<CardNumberForm>) => {
  useEffect(() => {}, [errors]);
  return (
    <div className="relative">
      <Controller
        name="buyerName"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            placeholder="구매자이름"
            className="w-full h-[42px]"
          />
        )}
      />
      {errors.buyerName && (
        <Text variant="danger" className="-top-5 right-0 absolute">
          {errors.buyerName.message}
        </Text>
      )}
    </div>
  );
};

export default NameInput;
