import { useTranslation } from "react-i18next";
import { useCalculateAndFormatDates } from "../../hooks/use.calculate.date";


interface Props {
    lastPaymentDate: string;
    nextPaymentDate: string;
    licenseType: string;
}

const RemainingDays = ({ lastPaymentDate, nextPaymentDate, licenseType }: Props) => {
    
    const { t } = useTranslation();
    const { getDifferenceInDays, getFormattedDate, getFreeDate, getCurrentDifferenceDays } = useCalculateAndFormatDates();

    return (
        <>
            { lastPaymentDate 
                ?
                    (
                        licenseType === 'Free' && lastPaymentDate
                        ?
                        <span>{`${getFreeDate(nextPaymentDate)}${t('detail.days')}`}</span>
                        :
                        <span>{`${getFormattedDate(nextPaymentDate!)}(${getCurrentDifferenceDays(nextPaymentDate)} ${t('detail.days')})`}</span>
                    )
                :
                    (
                        `(0 ${t('detail.days')})`
                    )
            }
          
        </>
    )
}

export default RemainingDays;