import { useTranslation } from "react-i18next";
import Loader from "../Loader";

interface Props {
  storageTotal: string;
  storageUsage: number;
  loading: boolean;
}

const RemainingCapacity = ({ storageTotal, storageUsage, loading }: Props) => {
  const { t } = useTranslation();

  if (loading) {
    return <Loader />;
  }

  return (
    <span className="text-[#555555] text-[18px] font-noto-sans-kr sm:text-[15px]">
      {t("detail.storageFront")}
      {storageUsage || storageUsage === 0 ? (
        <>
          <strong>
            {" "}
            {storageUsage}GB/
            {Number(storageTotal) / 1024 >= 1
              ? (Number(storageTotal) / 1024).toFixed(1) + "TB"
              : storageTotal + "GB"}
          </strong>{" "}
        </>
      ) : (
        <strong>{t("detail.unableToUse")}</strong>
      )}
      {t("detail.storageBack")}
    </span>
  );
};

export default RemainingCapacity;
