import React, { useEffect, useRef, useState } from "react";
import { ButtonArrowWhite } from "../../icons";
import { useNavigate } from "react-router-dom";
import { boolean, ref } from "yup";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const ImageFooter = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const observingRef = useRef<HTMLDivElement>(null);
    const [animate, setAnimate] = useState<boolean>(false);

    const handleArrowClick = () => {
        navigate('/login')
        window.scrollTo(0,0);
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setAnimate(entry.isIntersecting);
            },
            {
                threshold: 0.1
            }
        );

        if(observingRef.current){
            observer.observe(observingRef.current);
        }

        return () => {
            if(observingRef.current) {
                observer.unobserve(observingRef.current);
            }
        }
    }, []);
    
    return (
        <div className="relative flex flex-col items-center justify-center h-[400px]">
            <div className={`absolute z-50 flex flex-col items-center w-[40] gap-10 ${animate ? 'opacity-100 animate-slideFade' : 'opacity-0'}`} ref={observingRef}>
                <span className="w-[100%] h-[46px] leading-[46.34px] font-sans-kr text-[#FFF] font-extrabold text-[32px] sm:text-[18px] rxsm:text-[15px]">{t('main.startUc')}</span>
                <button onClick={handleArrowClick} className="w-[287px] h-[48px] bg-[#FFF] text-[#4067F6] rounded-[5px] text-left px-[20px] flex justify-between items-center">
                    <span className="font-bold leading-[20.27px] text-[14px] font-sans-kr animate-slideFade">{t('main.freeBtn')}</span>
                    <ButtonArrowWhite/>
                </button>
            </div>
            <div className=" w-[100%] h-[100%] bottom-0 relative">
                <img src={`${process.env.PUBLIC_URL}/images/card/main_bg_sm.png`} className="object-cover inset-0  z-20 w-[100%] h-[100%] bottom-0 absolute"/>
                <img src={`${process.env.PUBLIC_URL}/images/card/main_bg_overlay.png`} className="object-cover inset-0 z-10  w-[100%] h-[100%] bottom-0"/>
            </div>
        </div>
    )
}

export default ImageFooter;

