import React, {
  FormEvent,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import StepNavigator from "../../components/StepNavigator";
import FreeCard from "../../components/PriceCard/FreeCard";
import styled from "styled-components";
import StandardCard from "../../components/PriceCard/StandardCard";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { current } from "@reduxjs/toolkit";
import WorkspaceHeader from "../../components/Header/WorkspaceHeader";
import { BackIcon, Cancellation } from "../../icons";
import WorkspaceForm from "../../components/Form/WorkspaceForm";
import { debounce } from "../../utils/debounce";
import { useNavigate } from "react-router-dom";
import AdminAccountForm from "../../components/Form/AdminAccountForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import uuid from "react-uuid";
import { hashSHA256 } from "../../utils/sha256";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import { bool, boolean } from "yup";
import { toastError, toastSuccess } from "../../utils/toast";
import { error } from "console";
import axios, { AxiosError } from "axios";
import Loader from "../../components/Loader";
import { setTenantId } from "../../redux/slice/workspaceDataSlice";
import { fetchMemberInfo } from "../../redux/slice/memberSlice";
import { useTranslation } from "react-i18next";
import EnterpriseCard from "../../components/PriceCard/EnterpriseCard";
import Text from "../../ui/Text";
import PriceCardsForJP from "../../components/PriceCardForJP";
import PaymentInfoForm from "../../components/PaymentInfoForm";
import ModalLayout from "../../components/Modal/Layout";
import { AdditionalDataRequiredForProvisioning } from "../../@types";
import { cardFormClose } from "../../redux/slice/cardInfoFormSlice";
import {
  attemptToPayBeforeCreatingWorkspace,
  paymentAdminPageFetchData,
} from "../../utils/request";
import {
  setCardInfoData,
  setIsValid,
  setResetCardInfo,
} from "../../redux/slice/cardInfoListSlice";
import clsx from "clsx";
import PaymentLoadingAnimation from "./PaymentLoadingAnimation";
import BackButton from "./BackButton";
import CardInfoModal from "../../components/PaymentInfoForm/CardPaymentModal";

const BillingAdditionalModalLazy = lazy(
  () => import("../../components/Modal/BilllingAdditionalModal")
);

// const CardInfoModalLazy = lazy(
//   () => import("../../components/PaymentInfoForm/CardPaymentModal")
// );

const PaymentMethodBoxLazy = lazy(
  () => import("../../components/PaymentMethodBox")
);

const CreatePage = () => {
  const { t, i18n } = useTranslation();
  const [step, setStep] = useState<number>(1);
  const [loadingBar, setLoadingBar] = useState<boolean>(false);

  const sliderRef = useRef<Slider | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const billingAdditionalModalVisible = useSelector(
    (state: RootState) => state.billingAdditionalModal.isOpen
  );
  const cardInfoModalVisible = useSelector(
    (state: RootState) => state.cardInfoModal.isOpen
  );
  const cardInfoFormVisible = useSelector(
    (state: RootState) => state.cardInfoForm.isOpen
  );
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

  const memberRequestInfo = useSelector(
    (state: RootState) => state.memberLogin
  );
  const [formsData, setFormsData] = useState<FormsData>({
    priceLicense: {
      license: "NONE",
      licensePrice: 0,
      monetaryUnit: "WON",
      storage: 0,
      educationFlag: false,
    },
    workspaceForm: {
      workspaceName: null,
      tenantid: null,
      personnelLimit: 0,
      region: "KR",
    },
    adminForm: {
      restcode: null,
      name: null,
      tenantid: null,
      adminid: null,
      adminpwd: null,
    },
  });

  useEffect(() => {
    dispatch(fetchMemberInfo()).then((data) => {
      if (data.payload) {
        if (data.payload.data === -1) {
          toastError(t("common.loginRequired"));
          navigate("/login");
        } else if (data.payload === 403) {
          toastError(t("common.sessionExpired"));
          sessionStorage.removeItem("uc-portal-token");
          navigate("/login");
        }
      } else {
        toastError(t("common.connectError"));
        navigate("/");
      }
    });

    return () => {
      dispatch(setResetCardInfo());
    };
  }, []);

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    easing: "ease-in-out",
    afterChange: (index) => {
      setStep(index + 1);
    },
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 300); // 슬라이더 애니메이션 속도에 맞게 설정 (예: 300ms)
    }
  };

  const goToSlide = useCallback((index: number) => {
    sliderRef.current?.slickGoTo(index - 2);
  }, []);

  const goToPrevSlid = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  }, []);

  const handleLicenseClick = (licenseParam: PriceLicense) => {
    setFormsData((data) => ({
      ...data,
      priceLicense: licenseParam,
    }));

    goToNextSlide();
  };

  const handleWorkspaceClick = (dto: WorkspaceForm) => {
    setFormsData((data) => ({
      ...data,
      workspaceForm: dto,
    }));
    goToNextSlide();
  };

  const handleAdminClick = (dto: AdminDTO) => {
    if (formsData.priceLicense.license !== "Free") {
      //@note Free 라이센스가 아닐 경우 결제폼이 필요하기 떄문에 요청보내는 것이 아닌 상태업데이트
      hashSHA256(dto.adminPwd).then((pwd) => {
        setFormsData((data) => ({
          ...data,
          adminForm: {
            restcode: uuid(),
            name: formsData.workspaceForm.workspaceName,
            tenantid: formsData.workspaceForm.tenantid,
            adminid: dto.adminId,
            adminpwd: pwd,
          },
        }));
      });
      goToNextSlide();
    } else {
      //@note Free 라이센스 일 경우 결제 폼이 필요없어서 바로 프로비저닝 요청을 보냄
      hashSHA256(dto.adminPwd).then((pwd) => {
        fetchProvisioningPOST({
          adminFormData: {
            restcode: uuid(),
            name: formsData.workspaceForm.workspaceName,
            tenantid: formsData.workspaceForm.tenantid,
            adminid: dto.adminId,
            adminpwd: pwd,
          },
        });
      });
    }
  };

  const provisioningAfterPaymentRegistration = (
    paymentInfo: AdditionalDataRequiredForProvisioning
  ) => {
    fetchProvisioningPOST({
      adminFormData: formsData.adminForm,
      paymentInfo: paymentInfo,
    })
      .then((data) => {})
      .catch((error: any) => {
        setPaymentLoading(false);
        if (error.response.status === 402) {
          if (error.response.data.data === "Invalid Card Information") {
            toastError("유효하지 않은 카드입니다.");
          } else {
            toastError("ERRORCODE: 402");
          }
        } else {
          toastError("Server ERROR");
        }
      });
  };

  /**
   * @note 프로비저닝
   */
  const fetchProvisioningPOST = async ({
    adminFormData,
    paymentInfo,
  }: {
    adminFormData: AdminForm;
    paymentInfo?: AdditionalDataRequiredForProvisioning;
  }) => {
    setPaymentLoading(true);
    axiosInstance
      .post(
        "/workspace/create",
        {
          priceLicense: formsData.priceLicense,
          workspaceForm: formsData.workspaceForm,
          adminForm: adminFormData,
          ...(paymentInfo && { paymentInfo: paymentInfo }),
        },
        requestAuth({})
      )
      .then((data) => {
        toastSuccess(t("create.successMsg"));

        dispatch(setTenantId(adminFormData.tenantid!));
        setPaymentLoading(false);
        navigate("/workspace/success", {
          state: {
            adminId: adminFormData.adminid,
            tenantId: adminFormData.tenantid,
          },
        });
      })
      .catch((err) => {
        if (err.response.data.status === 400) {
          toastError(t("common.existTeamCode"));
        } else if (
          err.response.data.status === 401 &&
          err.response.data.data === "FREE"
        ) {
          toastError(t("workspace.freeFail"));
        } else if (err.response.status === 402) {
          toastError("허용되지 않은 카드입니다.");
        } else {
          toastError(t("create.errorMsg"));
        }
        setPaymentLoading(false);
        dispatch(setIsValid());
      });
  };

  return (
    <Container>
      <PaymentLoadingAnimation
        paymentLoading={paymentLoading}
        license={formsData.priceLicense.license}
      />
      {cardInfoModalVisible && ( //
        <Suspense>
          <CardInfoModal />
        </Suspense>
      )}

      <Suspense>
        <PaymentMethodBoxLazy
          isVisible={cardInfoFormVisible}
          setIsVisible={() => {
            dispatch(cardFormClose());
          }}
        />
      </Suspense>

      <div className="h-[50px] px-[36px] py-[16px]">
        <Box $loadingBar={loadingBar}>
          {step > 1 && (
            <BackButton
              goToSlide={() => {
                goToSlide(step);
              }}
            />
          )}
          <span
            onClick={() => {
              navigate("/");
            }}
            className={`${loadingBar ? "absolute right-4" : ""}`}
          >
            <Cancellation />
          </span>
        </Box>
      </div>

      {loadingBar ? (
        <div className="w-full h-[80vh] flex justify-center items-center">
          <Loader></Loader>
        </div>
      ) : (
        <>
          <StepNavigator
            step={step}
            license={formsData.priceLicense.license}
          ></StepNavigator>
          <Slider ref={sliderRef} {...settings}>
            <div>
              <ContentContainer>
                {i18n.language === "ja" ? (
                  <PriceCardsForJP handleLicenseClick={handleLicenseClick} />
                ) : (
                  <>
                    <FreeCard
                      onClick={handleLicenseClick}
                      price={0}
                      educationFlag={false}
                    />
                    <StandardCard
                      onClick={handleLicenseClick}
                      price={5000}
                      educationFlag={false}
                    />
                    <EnterpriseCard
                      onClick={handleLicenseClick}
                      price={9000}
                      educationFlag={false}
                    />
                  </>
                )}
              </ContentContainer>
              {/* <Text className="text-center pr-[400px] lg:pr-[0px]">
                위 금액은 1인 기준의 월간 사용요금으로, VAT(부가세)가 포함되지
                않은 금액입니다.
              </Text> */}
            </div>

            <div>
              <ContentContainer>
                <WorkspaceForm
                  buttonText={t("common.next")}
                  goToNext={handleWorkspaceClick}
                  license={formsData.priceLicense.license}
                ></WorkspaceForm>
              </ContentContainer>
            </div>

            <div>
              <ContentContainer>
                <AdminAccountForm
                  buttonText={t("common.teamBtn")}
                  onClick={handleAdminClick}
                ></AdminAccountForm>
              </ContentContainer>
            </div>

            <div>
              <ContentContainer>
                <PaymentInfoForm
                  workspaceName={formsData.workspaceForm.workspaceName!}
                  licenseType={formsData.priceLicense.license}
                  personnelLimit={formsData.workspaceForm.personnelLimit}
                  region={formsData.workspaceForm.region}
                  provisioning={provisioningAfterPaymentRegistration}
                />
              </ContentContainer>
            </div>
          </Slider>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  overflow-x: hidden;

  @media (max-width: 800px) {
    form {
      width: 340px;
    }
  }
`;

const FormWrapper = styled.span`
  form {
  }
`;

const ContentContainer = styled.div`
  margin-top: 1px;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 40px;

  @media screen and (max-width: 1400px) {
    padding-top: 0px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const Box = styled.div<{ $loadingBar: boolean }>`
  display: flex;
  width: ${(prop) => (prop.$loadingBar ? "100vw" : "auto")};
  float: right;
`;

export default CreatePage;
