import { useRef, useState } from "react";
import { boolean } from "yup";
import JoinAgreeModal from "../Modal/JoinAgreeModal";
import { Link } from "react-router-dom";
import TermofService from "../Modal/TermofService";
import TermofPersonalInfo from "../Modal/TermofPersonalInfo";
import useTermsCheckbox from "../../hooks/use.terms.check";
import CheckBox from "../CheckBox";
import useCheckbox from "../../hooks/use.terms.check.box";
import { useTranslation } from "react-i18next";

interface Props {
    agree: () => void;
}

const JoinAgreeForm = ({ agree }: Props) => {

    const { t } = useTranslation();
    const [isServiceTermOpen, setisServiceTermOpen] = useState<boolean>(false);
    const [checkbox, updateCkecboxState] = useTermsCheckbox();

    const {
        allChecked,
        termsChecked,
        privacyChecked,
        handleAllCheckedChange,
        handleTermsCheckedChange,
        handlePrivacyCheckedChange,
        updateAllCheckedState
      } = useCheckbox();

      return (
       <>
        <TermofService isOpen={isServiceTermOpen} onClose={() => { setisServiceTermOpen(false) }} height="660px" width="600px"/>
        
        <section className=" w-full h-[100vh] flex justify-center pt-[100px]">
            <div className="w-[410px] sm:w-[320px] h-[420px] bg-[#FFF] border-[1px] flex flex-col  gap-5">
                <h2 className="font-extrabold text-3xl leading-13 font-NotoSansKR mt-7 text-center ">{t('agree.signup')}</h2>
                <div className="text-center">{t('agree.title')}</div>

                <div className="px-10 flex flex-col gap-5">
                    <div className="flex gap-1 float-left">
                        {/* <input type="checkbox" checked={checkbox.allCheck} onChange={updateCkecboxState('allCheck')}/> */}
                        <CheckBox checked={allChecked} setChecked={handleAllCheckedChange}/>
                        <span className="leading-5 text-[14px] sm:text-[10px] font-bold font-sans-kr">{t('agree.allAgree')}</span>
                    </div>

                    <hr className="w-[100%] h-[50%] border-1"/>

                    <div>
                        <div className="flex gap-1 float-left">
                            <CheckBox checked={termsChecked} setChecked={handleTermsCheckedChange}/>
                            {/* <input type="checkbox" checked={checkbox.termsOfService} onChange={updateCkecboxState('termsOfService')}/> */}
                            <span className="leading-5 text-[14px] text-[#555555] sm:text-[10px] font-sans-kr">{t('agree.terms')}</span>
                        </div>
                        <div className="float-right">
                            <div className="text-[#9D9EA2] text-[12px] border-b-[1.2px] border-[#9D9EA2] sm:text-[10px] font-noto-sans-kr h-[16.7px] cursor-pointer" onClick={() => { setisServiceTermOpen(true) }}>{t('agree.view')}</div>
                        </div>
                    </div>

                    <div>
                        <div className="flex gap-1 float-left">
                            <CheckBox checked={privacyChecked} setChecked={handlePrivacyCheckedChange}/>
                            {/* <input type="checkbox" checked={checkbox.privacyPolicy} onChange={updateCkecboxState('privacyPolicy')}/> */}
                            <span className="leading-5 text-[14px] text-[#555555] sm:text-[10px] font-sans-kr">{t('agree.personalInfo')}</span>
                        </div>
                        <div className="float-right">
                            <div className="text-[#9D9EA2] text-[13px] border-b-[1.2px] border-[#9D9EA2] sm:text-[10px] font-noto-sans-kr h-[16.7px] cursor-pointer" onClick={() => { setisServiceTermOpen(true) }}>{t('agree.view')}</div>
                        </div>
                    </div>
                    <div className="pt-3 flex justify-center">
                        <button className={`${allChecked ? 'bg-[#1F62B9]' : 'bg-[#A6BAEB]'} text-[#FFF] w-[320px] sm:w-[240px] h-[42px] rounded-[4px] font-bold font-noto-sans-kr`} 
                            disabled={!allChecked}
                            onClick={agree}
                        >
                            {t('agree.next')}
                        </button>
                    </div>

                    <div className="text-[14px] text-[#1F62B9] leading-[20.27px] font-noto-sans-kr">
                        {t('agree.loginLink')} <Link to={'/login'} className="font-black">{t('agree.login')}</Link>
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default JoinAgreeForm;