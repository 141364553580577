import { Controller } from "react-hook-form";
import { CardNumberForm, ReactHookFormProps } from "../../@types";
import Input from "../../ui/Input";
import Text from "../../ui/Text";

const CardPw = ({
  control,
  errors,
  setValue,
  getValues,
}: ReactHookFormProps<CardNumberForm>) => {
  return (
    <div className="relative">
      <Controller
        name="cardPw"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            maxLength={2}
            placeholder="카드 비밀번호"
            className="w-full h-[42px]"
          />
        )}
      />
      {errors["cardPw"] && (
        <Text variant="danger" className="-top-5 right-0 absolute">
          {errors["cardPw"].message}
        </Text>
      )}
    </div>
  );
};

export default CardPw;
