import { Controller } from "react-hook-form";
import Input from "../../ui/Input";
import { CardNumberForm, ReactHookFormProps } from "../../@types";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Text from "../../ui/Text";
const placeholderArr = ["YY", "MM"];
const valueKey = ["yeaer", "month"];

const ExpireDate = ({
  control,
  errors,
  setValue,
  getValues,
  trigger,
}: ReactHookFormProps<CardNumberForm>) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleChange = ({ index, value }: { index: number; value: string }) => {
    if (/^\d{0,4}$/.test(value)) {
      const newExpireDate = [...getValues("expireDate")];
      newExpireDate[index] = value;
      setValue("expireDate", newExpireDate);

      if (value.length === 2 && index === 0) {
        inputRefs.current[index + 1]?.focus();
      }

      trigger("expireDate");
    }
  };

  const handleKeyDown = ({
    e,
    index,
  }: {
    e: React.KeyboardEvent<HTMLInputElement>;
    index: number;
  }) => {
    if (
      e.key === "Backspace" &&
      getValues(`expireDate.${index}`) === "" &&
      index > 0
    ) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    const errorObj = Object.values(errors.expireDate || {}).find(
      (error: any) => error
    );
    if (errorObj) {
      setErrorMessage((errorObj as { message: string }).message);
    } else {
      setErrorMessage(null);
    }
  }, [errors.expireDate]);

  return (
    <div className="flex flex-col gap-1 relative">
      <div className="flex flex-row gap-1 flex-1">
        {["expireDate.year", "expireDate.month"].map((name, index) => (
          <Controller
            key={name}
            name={name}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                ref={(el) => {
                  field.ref(el);
                  inputRefs.current[index] = el;
                }}
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChange({
                    index: index,
                    value: e.target.value,
                  });
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyDown({
                    e,
                    index,
                  })
                }
                maxLength={2}
                placeholder={placeholderArr[index]}
                className="w-[90px] h-[42px] flex-1"
              />
            )}
          />
        ))}
      </div>
      {errorMessage && (
        <Text variant="danger" className="-top-5 right-8 absolute">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default ExpireDate;
