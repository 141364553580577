import { useEffect, useRef, useState } from "react";
import { BackIcon } from "../../icons";
import clsx from "clsx";

interface Props {
  goToSlide: () => void;
}

const BackButton = ({ goToSlide }: Props) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const handleClick = () => {
    setClicked(true);
    goToSlide();

    setTimeout(() => {
      setClicked(false);
    }, 1000);
  };

  useEffect(() => {}, []);
  return (
    <span
      className={clsx(
        "absolute left-4 cursor-pointer transition-opacity duration-300",
        { "opacity-20": clicked }
      )}
      onClick={handleClick}
    >
      <BackIcon />
    </span>
  );
};

export default BackButton;
