import clsx from "clsx";
import { useEffect } from "react";
import Loader from "../Loader";

interface Props {
  visible: boolean;
}

const FullScreenLodable = ({ visible }: Props) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);
  return (
    <div
      className={clsx(
        "fixed w-full flex items-center justify-center flex-col gap-8 bg-[rgba(0,0,0,.8)] inset-0 z-[600] overflow-hidden transform transition-transform duration-500",
        {
          hidden: !visible,
        }
      )}
    >
      <Loader />
    </div>
  );
};

export default FullScreenLodable;
