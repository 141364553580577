import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProductButton from "../../components/Button/ProductCard";
import ProductCard from "../../components/Button/ProductCard";
import { Hourglass } from "../../icons";
import WatingButton from "../../components/Button/WatingBtn";
import ManageButton from "../../components/Button/ManageBtn";
import CreateWorkspaceButton from "../../components/Button/WorkspaceBtn";
import { useNavigate } from "react-router-dom";
import WorkspaceDeleteModal from "../../components/Modal/WorkspaceDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkspaceGET } from "../../redux/slice/workspaceSlice";
import { AppDispatch, RootState } from "../../redux";
import ConversionBtn from "../../components/Button/ConversionBtn";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { toastError, toastSuccess } from "../../utils/toast";
import {
  NumberOfUsersDTO,
  fetchNumberOfUsersPOST,
} from "../../redux/slice/numberOfUsers";
import Skeleton from "react-loading-skeleton";
import axiosInstance from "../../utils/request/axios.instance";

function findValueByKey(usersList: any, key: string): number | undefined {
  if (!usersList) return 0;

  return usersList[key];
}

const WorkspaceTable = () => {
  const [manageClick, setManageClick] = useState<boolean>(false);
  const [createClick, setCreateClick] = useState<boolean>(false);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const workspaces: Array<WorkspaceDTO> = useSelector(
    (state: RootState) => state.workspace.workspaceList
  );
  const numberOfMembers = useSelector(
    (state: RootState) => state.numberOfUsers
  );
  const loading = useSelector((state: RootState) => state.workspace.status);
  const dispatch = useDispatch<AppDispatch>();

  const handleManageClick = () => {
    setManageClick(!manageClick);
  };
  const handleCreateClick = () => {
    setCreateClick((createClick) => !createClick);
    navigate("/workspace/set-up");
  };

  const handleDetailRoute = (id: number, delFlag: string) => {
    if (!delFlag) {
      navigate(`/detail/${id}`);
    } else {
      toastError(t("join.auth.fail"));
    }
  };

  useEffect(() => {
    const fetchWorkspaceInfoList = dispatch(fetchWorkspaceGET()).then(
      (data) => {
        if (typeof data.payload === "number") {
          navigate("/login");
          sessionStorage.removeItem("uc-portal-token");
        }
      }
    );

    dispatch(fetchNumberOfUsersPOST());
  }, []);

  return (
    <section className="w-full pt-[140px]">
      <Text>{t("workspace.myTeam")}</Text>
      <WorkspaceWrapper className="scroll_layout">
        <WorkspaceList>
          <thead>
            <tr>
              <Th $expand={false}>{t("workspace.productPlan")}</Th>
              <Th $expand={true}>{t("workspace.team")}</Th>
              <Th $expand={true}>{t("workspace.teamCode")}</Th>
              <Th $expand={false}>{t("workspace.numberOfMembers")}</Th>
              <Th $expand={false}>{t("workspace.adminBtn")}</Th>
            </tr>
          </thead>

          <tbody>
            {loading === "loading" ? (
              <>
                <tr style={{ border: "0px" }}>
                  <td colSpan={5} rowSpan={3} className="px-2.5">
                    <Loader />
                  </td>
                </tr>
                <tr style={{ border: "0px" }}></tr>
                <tr style={{ border: "0px" }}></tr>
              </>
            ) : (
              <>
                {!workspaces ||
                  (workspaces.length === 0 && (
                    <>
                      <tr style={{ border: "0px" }}>
                        <td colSpan={5} rowSpan={3} className="px-2.5">
                          <EmptyText
                            dangerouslySetInnerHTML={{
                              __html: t("workspace.emptyMsg"),
                            }}
                          ></EmptyText>
                        </td>
                      </tr>
                      <tr style={{ border: "0px" }}></tr>
                      <tr style={{ border: "0px" }}></tr>
                    </>
                  ))}

                {!!workspaces &&
                  workspaces.length > 0 &&
                  workspaces.map((obj, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          handleDetailRoute(obj.id, obj.delFlag);
                        }}
                      >
                        <td width="10%">
                          <CardWrapper>
                            <ProductCard
                              text={obj.license}
                              educational={obj.educationFlag}
                            />
                          </CardWrapper>
                        </td>
                        <td>{obj.name}</td>
                        <td>{obj.tenantId}</td>

                        <td>
                          {numberOfMembers.status === "loading" ? (
                            <Skeleton />
                          ) : (
                            findValueByKey(
                              numberOfMembers.usersList,
                              obj.tenantId
                            ) +
                            "/" +
                            obj.personnelLimit
                          )}
                        </td>

                        <td>
                          <span onClick={handleManageClick}>
                            <ConversionBtn
                              $clicked={manageClick}
                              delFlag={Boolean(obj.delFlag)}
                              tenantId={obj.tenantId}
                              text={t("workspace.manageBtn")}
                              educationFlag={obj.educationFlag}
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </>
            )}
          </tbody>
        </WorkspaceList>
      </WorkspaceWrapper>
      <span className="flex justify-center pb-[30px]">
        <CreateWorkspaceButton
          $clicked={createClick}
          onClick={handleCreateClick}
          text={t("workspace.createBtn")}
        />
      </span>
    </section>
  );
};

const Th = styled.th<{ $expand: boolean }>`
  width: ${(props) => (props.$expand ? "24%" : "20%")};
`;

const Text = styled.div`
  color: #000;
  text-align: center;
  font-family: Noto Sans KR;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const EmptyText = styled.div`
  color: #555;
  text-align: center;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const CardWrapper = styled.span`
  padding: 0.4px 2px;
  display: flex;
  justify-content: center;
`;

const WorkspaceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 746px) {
    .workspace-create-btn {
      margin-left: 370px;
    }
  }

  @media screen and (max-width: 856px) {
    overflow-x: scroll;
  }
`;

const WorkspaceList = styled.table`
  font-family: Arial, sans-serif;
  border-collapse: collapse;
  flex-grow: 0;
  width: 70%;
  min-width: 800px;
  text-align: center;

  th,
  td {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: center;
  }
  th,
  td {
    border: none;
  }
  td {
    font-weight: 400;
    width: 143px;
    color: #555;

    text-align: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  tr {
    border: 1px solid #dfe2e8;
    height: 48px;
  }
  th {
    color: #555;

    text-align: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  th {
    color: #555;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  table {
    border: 1px solid #ccc; /* 바깥쪽 테두리 색상 */
  }

  /* 테이블 내부 선 스타일 */
  table th,
  table td {
    border-left: none; /* 왼쪽 테두리 제거 */
    border-right: none; /* 오른쪽 테두리 제거 */
  }
  table tr:last-child td {
    border-bottom: none; /* 마지막 행의 하단 테두리 제거 */
  }
  @media screen and (max-width: 746px) {
    margin-left: 524px;
  }
`;

export default WorkspaceTable;
