import { useNavigate } from "react-router-dom";
import { handleAdminPageLocate } from "../../utils/common/admin.page";
import DetailButton from "../Button/DetailButton";
import DetailSubTitle from "../Text/DetailSubTitle";
import NumberOfMember from "./NumberOfMember";
import RemainingCapacity from "./RemainingCapacity";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useEffect, useState, useTransition } from "react";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import { AxiosError } from "axios";
import { error } from "console";
import { toastError } from "../../utils/toast";
import { useTranslation } from "react-i18next";
import ConversionBtn from "../Button/ConversionBtn";
import { styled } from "styled-components";
import ManageButton from "../Button/ManageBtn";

interface Props {
  tenantId: string;
  isScreenSM: boolean;
}

interface DTO {
  storage: number;
  users: number;
}

const StorageAndPersonnel = ({ tenantId, isScreenSM }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const workspaceDetail = useSelector(
    (state: RootState) => state.workspaceDetail.workspaceInfo
  );
  const [data, setData] = useState<DTO>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(
        "workspace/use/info",
        requestAuth({
          params: {
            tenantId,
          },
        })
      )
      .then((response) => {
        setLoading(false);
        if (response.data) {
          setData(response.data.data.result);
        }
      })
      .catch((error) => {
        setLoading(false);
        toastError("SERVER ERROR");
        navigate("/");
      });
  }, []);

  return (
    <>
      <section className="w-full space-y-2">
        <DetailSubTitle text={t("feature.member")} />

        <div className="border-[#DFDFDF] border-[1px] rounded-[14px] h-[74px] flex items-center pl-5 justify-between px-1">
          <NumberOfMember
            personnelLimit={workspaceDetail?.personnelLimit!}
            users={data?.users!}
            loading={loading}
          />

          <div className="space-x-3 flex flex-row items-center">
            {!isScreenSM && (
              <>
                {workspaceDetail!.licenseType !== "Free" && (
                  <DetailButton
                    btnClassName="border-[#1F62B9] hover:bg-[#D9E5F9]"
                    text={t("detail.memberAdd")}
                    textClassName="text-[#1F62B9]"
                    onClick={() => {
                      if (!workspaceDetail?.nextPaymentDate) {
                        toastError(t("detail.paymentRequired"));
                      } else {
                        navigate("/workspace/member", {
                          state: {
                            tenantId: workspaceDetail?.tenantId,
                            nextPaymentDate: workspaceDetail?.nextPaymentDate,
                          },
                        });
                      }
                    }}
                  />
                )}
                <ManageButton
                  $clicked={true}
                  onClick={() => {
                    handleAdminPageLocate(workspaceDetail?.tenantId!, () => {
                      navigate("/");
                    });
                  }}
                  text={t("workspace.manageBtn")}
                  tenantId={"jw01"}
                  detail={true}
                />
                {/* <DetailButton btnClassName="border-[#1F62B9] hover:bg-[#D9E5F9]" text={t("workspace.manageBtn")} textClassName="text-[#1F62B9]" onClick={() => {  
                                    handleAdminPageLocate(workspaceDetail?.tenantId!, () => { navigate('/') });
                                }}/> */}
              </>
            )}
          </div>
        </div>
      </section>

      <section className="w-full space-y-2">
        <DetailSubTitle text={t("detail.storage")} />
        <div className="border-[#DFDFDF] border-[1px] rounded-[14px] h-[74px] flex items-center pl-5 justify-between px-5">
          <RemainingCapacity
            storageTotal={workspaceDetail?.storage!}
            storageUsage={data?.storage!}
            loading={loading}
          />

          {!isScreenSM && workspaceDetail!.licenseType !== "Free" && (
            <DetailButton
              btnClassName="border-[#1F62B9] hover:bg-[#D9E5F9]"
              text={t("detail.storageAdd")}
              textClassName="text-[#1F62B9]"
              onClick={() => {
                // if (!workspaceDetail?.nextPaymentDate) {
                //   toastError(t("detail.paymentRequired"));
                // } else {
                navigate("/workspace/storage", {
                  state: {
                    tenantId: workspaceDetail?.tenantId,
                    nextPaymentDate: workspaceDetail?.nextPaymentDate,
                  },
                });
                // }
              }}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default StorageAndPersonnel;
