import { useCallback } from "react"


export const useCalculateAndFormatDates = () => {
    const getDifferenceInDays = useCallback(calculateDifferenceInDays, []);
    const getFormattedDate = useCallback(formatDate, []);
    const getFreeDate = useCallback(calculateDaysDifferenceFromNow, []);
    const getCurrentDifferenceDays = useCallback(calculateDaysDifferenceFromNow, []);
    const getFormattedDateLine = useCallback(formatDateLine, []);

    const getCalculateUsersAdd = useCallback(calculatePayment, []);

    return { getDifferenceInDays, getFormattedDate, getFreeDate, getCurrentDifferenceDays , getFormattedDateLine, getCalculateUsersAdd};
}

function calculateDifferenceInDays(dateStr1: string, dateStr2: string): number {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);
    const differenceInDays = Math.abs(date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24);
    return differenceInDays;
}
  
function formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    return formattedDate;
}

function calculateDaysDifferenceFromNow(dateStr: string): number {
    const currentDate = new Date();
    const targetDate = new Date(dateStr);
    const differenceInMilliseconds = targetDate.getTime() - currentDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.round(differenceInDays);
}

function formatDateLine(inputDate: string, lang: string): string {

    const date = new Date(inputDate);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric', // 연도는 숫자로 표시 (예: 2024)
        month: 'long',   // 월은 긴 형태로 표시 (예: 4월)
        day: 'numeric'   // 일은 숫자로 표시 (예: 17)
    };

    const formatter = new Intl.DateTimeFormat(lang, options);
    return formatter.format(date);

}

function calculatePayment(lastPaymentDate: string, usersCount: number, licensePrice: number): number {

    const daysDifference = calculateDaysDifferenceFromNow(lastPaymentDate);
    if (daysDifference > 27) {
      // 마지막 결제일이 27일 이상일 경우
      return licensePrice * usersCount;
    } else {
      // 마지막 결제일이 27일 미만일 경우
      const dailyRate = (licensePrice * 12) / 365;
      let totalCost = dailyRate * daysDifference * usersCount; // 일일 요금 기반 총액
      // 10의 자리에서 올림 처리
      totalCost = Math.ceil(totalCost / 10) * 10;
      return totalCost;
    }
  }

  

  


