import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface TenantState {
    tenantId: string;
}

const initialState: TenantState = {
    tenantId: ''
}

const workspaceDataSlice = createSlice({
    name: 'workspaceData',
    initialState,
    reducers: {
        setTenantId(state, action: PayloadAction<string>){
            state.tenantId = action.payload;
        },
    }
});

export const { setTenantId } = workspaceDataSlice.actions;
export default workspaceDataSlice.reducer;
