import React from "react";
import MainLayout from "../../components/Layout/MainLayout";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MailSuccessPage = () => {
    const { t } = useTranslation();
    
    return (
        <MainLayout imgSrc={"/images/mail_bg.png"}>
            <Wrapper>
                <H3 dangerouslySetInnerHTML={{
                    __html : t('join.mail.title')
                }}>
                    
                </H3>
                <EmailText>{t('join.mail.subTitle')}</EmailText>
                <Link to={"/login"}>
                    <LinkSpan dangerouslySetInnerHTML={{
                        __html: t('join.mail.goToLogin')
                    }}>
                        
                    </LinkSpan>
                </Link>
           </Wrapper>
        </MainLayout>
    )
}

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;

    a{
        text-decoration-line: none;
    }
`;

const H3 = styled.h3`
    color: #000;
    font-family: Noto Sans KR;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 75px;

    @media (max-width: 590px) {
        font-size: 22px;
    }

`;

const EmailText = styled.div`
    color: #555;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
    margin-top: 30px;

    @media (max-width: 590px) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
`;

const LinkSpan = styled.span`
    color: #1F62B9;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

export default MailSuccessPage;