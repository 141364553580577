import styled, { Interpolation, css, keyframes } from "styled-components";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Loader from "../Loader";

interface Props{
    loading?: boolean;
    children: JSX.Element | JSX.Element[];
    override?: string;
}

export default function Loadable({ loading, children, override }: Props){
    return (
      <>
        {loading ? (
          <div className="fixed top-[50%] left-[50%]">
            <Loader/>
          </div>
        ) : children}
      </>
    )
}

