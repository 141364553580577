export const LANGUAGES = ["ko", "en", "ja"];




export const termsKO = `
<span className=\"font-bold text-[16px]\">[필수] 이용 약관</span> 
<br/>
<br/>
<span className='text-[#1F62B9] text-[16px] font-bold'>개인정보 처리방침</span>
<br/>
<br/>
<span>
(주)유씨웨어 주식회사는 이용자들의 개인정보를 소중히 다루고 있습니다.<br/>
<br/>
(주)유씨웨어 주식회사(이하, ‘회사’라 함)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보방침을 정하여 이용자권익 보호에 최선을 다하고 있습니다.
<br/>
<br/>
본 개인정보처리방침은 회사가 제공하는 UCWORKS 서비스에 한해 적용되며 다음과 같은 내용을 담고 있습니다.<br/>
<br/>
1. 수집하는 개인정보의 항목 및 수집방법<br/>
2. 개인정보의 수집 및 이용목적<br/>
3. 개인정보 공유 및 제공<br/>
4. 개인정보의 보유 및 이용기간<br/>
5. 개인정보 반환절차 및 방법<br/>
6. 개인정보 파기절차 및 방법<br/>
7. 이용자 및 법정대리인의 권리와 그 행사방법<br/>
8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항<br/>
9. 개인정보의 기술적/관리적 보호 대책<br/>
10. 적용범위<br/>
11. 고지의 의무<br/>

<br/>

<div>1.수집하는 개인정보의 항목 및 수집방법</div>

<br/>

가. 수집하는 개인정보의 항목
회사는 회원가입, 원활한 고객상담, 서비스 제공을 위해 아래와 같은 최소한의 개인정보를 필수항목으로 수집하고 있습니다.<br/>
-필수항목 :관리자 이름, 회사이름, 회사 전화번호, 이메일 주소(가입 인증 정보 또는 기본 입력 정보용), 휴대전화번호(가입 인증 정보), 관리자 계정정보 (아이디, 비밀번호)
회사와 계약관계에 있는 제휴회사를 통한 서비스 이용 시 계약 과정에서 아래와 같은 개인정보를 수집합니다.<br/>
-필수항목: 관리자 정보(성명, 계정정보, 이메일 주소, 전화번호)
또한 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 추가로 수집될 수 있습니다.<br/>
-거래정보: 개인의 경우 생년월일(정기결제에 한함), 신용카드정보(신용카드번호, 유효기간, 비밀번호 앞 두 자리), 세금계산서 발행을 위한 회계 담당자 정보(이름, 연락처, 이메일 주소) 등
- IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록 등<br/>
-필수항목 : 도메인 정보, 회사(단체)명, 대표자명, 대표주소, 대표번호(유선), 사업자 등록번호, 법인 등록번호, 고유번호<br/>
- 선택항목 : 팩스번호<br/>

<br/>

나. 개인정보 수집방법<br/>
회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br/>
- 홈페이지, 서면양식, 팩스, 전화, 상담 게시판, 이메일, 이벤트 응모, 배송요청<br/>
- 협력회사로부터의 제공<br/>
- 생성정보 수집 툴을 통한 수집<br/>

<br/>

<div>2.개인정보의 수집 및 이용목적</div>

<br/>

가. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 <br/>
콘텐트 제공, 특정 맞춤 서비스 제공, 물품배송 또는 청구서 등 발송, 본인확인, 구매 및 요금 결제, 요금추심

<br/>
<br/>

나. 회원관리
회원제 서비스 제공, 개인식별, 이용약관 위반 회원에 대한 이용제한 조치,
서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용 행위 제재, 가입의사 확인, 가입 및 가입횟수 제한, 만14세 미만 아동의 개인정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달, 회원탈퇴 의사의 확인

다. 신규 서비스 개발 및 마케팅•광고에의 활용
신규 서비스 개발 및 맞춤 서비스 제공, 
통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계

<br/>
<br/>
<div>3.개인정보의 공유 및 제공</div>
<br/>
회사는 이용자들의 개인정보를 ‘2. 개인정보의 수집목적 및 이용목적’서 고지한 범위 내에서 사용하며, 
이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.

<br/>
<br/>
가. 이용자가 사전에 동의한 경우
<br/>
<br/>
나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

<div>4.개인정보의 보유 및 이용기간</div>
<br/>
이용자의 개인정보는 원칙적으로 해지 전까지 원활한 서비스의 제공을 위해 보관됩니다.
 해지를 하는 경우 데이터 복구요청 등 이용자의 보호를 위해 해지 후 15일간 보관되며,
  15일이 지난 후 즉시 삭제됩니다. 단, 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 
  관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원 정보를 보관합니다.
   이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br/>
<br/>
- 계약 또는 청약철회 등에 관한 기록 <br/>
보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br/>
보존 기간 : 5년<br/>
- 대금결제 및 재화 등의 공급에 관한 기록<br/>
보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br/>
보존 기간 : 5년<br/>
보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률<br/>
보존 기간 : 3년<br/>
- 웹사이트 방문기록<br/>
보존 이유 : 통신비밀보호법<br/>
보존 기간 : 3개월<br/>
<br/>
<div>5. 개인정보 반환 절차 및 방법</div>
<br/>
이용자의 개인정보는 원칙적으로 해지 전까지 원활한 서비스의 제공을 위해 보관됩니다. 
해지를 하는 경우 데이터 복구요청 등 이용자의 보호를 위해 해지 후 15일간 보관되며, 
15일이 지난 후 즉시 삭제되지만 사용자가 반환을 요청하는 경우 아래와 같이 반환 받을 수 있습니다.

<br/>
<br/>
가. 반환 절차
- 사용자가 서비스 해지 요청 후 7일 이내 반환 요청 시 반환
<br/>
<br/>
나. 반환 방법
- 반환 데이터: 사용자 정보
-제공 형태: CSV 파일

<br/>
<br/>
<div>6. 개인정보 파기 절차 및 방법</div>
<br/>                    

이용자의 개인정보는 원칙적으로 해지 전까지 원활한 서비스의 제공을 위해 보관됩니다.
 해지를 하는 경우 데이터 복구요청 등 이용자의 보호를 위해 해지 후 15일간 보관되며, 15일이 지난 후 즉시 삭제됩니다.
<br/>
<br/>
 가. 파기절차<br/>
- 사용자가 회원가입 등을 위해 입력한 정보 및 채팅, 쪽지, 파일 내역은 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보 보호 사유에 따라 (보유 및 이용기간 참조) 저장된 후 파기됩니다.<br/>
- 동 개인정보는 법률에 의한 경우가 아니 고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다<br/>
<br/>
나. 파기 방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
(1)사용자 정보-해지 요청 15일 이후 관리자 수동 삭제
(2)쪽지, 채팅, 파일- 15일 주기로 자동 삭제

<br/>
<br/>

<div>7.이용자 및 법정대리인의 권리와 그 행사방법</div>

<br/>
- 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.<br/>
- 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 ‘개인정보변경'(또는 ‘회원정보수정’ 등)을, 가입해지(동의철회)를 위해서는 ‘회원탈퇴’를 클릭하여 직접 열람, 정정 또는 탈퇴가 가능합니다.<br/>
- 혹은 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.<br/>
- 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br/>
- 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 ‘5. 개인정보의 보유 및 이용기간’에 명시된 바에 따라 처리하고 그 외의 도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br/>

<br/>

<div>8.개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</div>

<br/>
가. 쿠키란? <br/>
- 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.<br/>
- 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.<br/>
<br/>
나. 회사의 쿠키 사용 목적 
<br/>
- 회사는 쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다.<br/>
- 회사는 쿠키를 통해 서비스의 접속 빈도, 방문 시간, 방문 회수 등을 분석하여 이용자의 취향과 관심분야를 파악하여 서비스 제공에 활용합니다.<br/>
<br/>
다. 쿠키의 설치/운영 및 거부
<br/>
- 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br/>
- 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 서비스의 경우, 이용에 어려움이 있을 수 있습니다.<br/>
- 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.<br/>
① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다. <br/>
② [개인정보 탭]을 클릭합니다. <br/>
③ [개인정보처리 수준]을 설정하시면 됩니다. <br/>

<br/>

<div>9.개인정보의 기술적/관리적 보호 대책</div>
<br/>
가. 비밀번호 암호화 UCWORKS 이용자의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며,
 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
<br/>
<br/>

나. 해킹 등에 대비한 대책 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고,
최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며,
 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고
  침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.

<br/>
<br/>
다. 처리 직원의 최소화 및 교육 회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 
이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 UCWORKS 개인정보처리방침의 준수를 항상 강조하고 있습니다.
<br/>
<br/>
라. 개인정보보호전담기구의 운영
그리고 사내 개인정보보호전담기구 등을 통하여 UCWORKS 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 
수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

<br/>
<br/>
기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다. <br/>
- 개인정보침해신고센터 (privacy.kisa.or.kr/ 국번없이 118) <br/>
- 대검찰청 사이버범죄수사단 (www.spo.go.kr/ 국번없이 1301) <br/>
- 경찰청 사이버안전국 (cyberbureau.police.go.kr/ 국번없이 182) <br/>

<br/>

<div>10.적용범위</div>
<br/>
UCWORKS에 링크되어 있는 다른 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본
 ‘UCWORKS 개인정보처리방침’이 적용되지 않음을 알려 드립니다. 또한 본 ‘UCWORKS 개인정보처리방침’은 회사와 이용계약을 체결한 회원에 한해 적용되며,
  회원의 관리하에 UCWORKS의 권한을 부여 받은 구성원에게는 적용되지 않습니다. <br/>
   구성원에게 적용되는 개인정보처리방침은 각 회사(단체)의 개인정보 보호책임자(혹은 개인정보 보호책임자)를 통해 확인하셔야 합니다.
   <br/><br/>
<div>11. 고지의 의무</div>
<br/>
현 개인정보처리방침 내용 추가,
 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 ‘공지사항’을 통해 고지할 것입니다.
  다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다. <br/>
– 공고일자 : 2020년 4월 9일<br/>
– 시행일자 : 2020년 4월 9일<br/>
</span>
`


export const termsEN = `
<span className=\"font-bold text-[16px]\">[Required] Terms and Conditions</span> 
<br/>
<br/>
<span className='text-[#1F62B9] text-[16px] font-bold'>Privacy Policy</span>
<br/>
<br/>
<span>
UC Ware Co., Ltd. cherishes the personal information of its users.<br/>
<br/>
UC Ware Co., Ltd. (hereinafter referred to as the "Company") complies with the personal information protection regulations of related laws and regulations that information and communication service providers must comply with, such as the Act on Promotion of Information and Communication Network Utilization and Information Protection, the Personal Information Protection Act, the Communication Secret Protection Act, and the Telecommunications Business Act.
<br/>
<br/>
This privacy policy applies only to UCWORKS services provided by the company and includes the following information.<br/>
<br/>
1. Items and methods of collecting personal information to be collected<br/>
2. Purpose of collecting and using personal information<br/>
3. Sharing and providing personal information<br/>
4. Hold and use period of personal information<br/>
5. Procedures and methods for returning personal information<br/>
6. Procedures and methods for destroying personal information<br/>
7. Rights of users and legal representatives and methods of exercising them<br/>
8. Matters concerning the installation/operation and rejection of the automatic personal information collection device<br/>
9. Technical/administrative protection measures for personal information<br/>
10. Coverage<br/>
11. Obligations of Notification<br/>

<br/>

<div>1. Items and methods of collecting personal information to be collected</div>

<br/>

A. Items of personal information to be collected
The company collects the following minimum personal information as essential items for membership registration, smooth customer consultation, and service provision.<br/>
- Required items: Administrator name, company name, company phone number, email address (for subscription authentication or basic input information), mobile phone number (subscription authentication information), administrator account information (ID, password)
When using the service through an affiliated company that has a contractual relationship with the company, the following personal information is collected during the contract process.<br/>
- Required: Administrator information (name, account information, email address, phone number)
In addition, the following information may be collected in the process of using the service or processing the business.<br/>
-Transaction information: For individuals, date of birth (limited to regular payments), credit card information (credit card number, validity period, two digits in front of password), accounting officer information (name, contact information, e-mail address) for issuing tax invoices, etc
- IP Address, Cookies, Date and Time of Visit, Service Usage History, Bad Usage History, etc.<br/>
- Required items: Domain information, company (organization) name, representative name, representative address, representative number (wired), business registration number, corporate registration number, unique number<br/>
- Selection: Fax Number<br/>

<br/>

B. How to collect personal information<br/>
The company collects personal information in the following ways:<br/>
- Home page, written form, fax, phone, consultation board, email, event entry, delivery request<br/>
- Offer from partner companies<br/>
- Collecting through generation information collection tools<br/>

<br/>

<div>2. Purpose of collecting and using personal information</div>

<br/>

A. Implementation of the contract on service provision and settlement of fees according to service provision <br/>
Content provision, specific customized services, delivery of goods or invoices, identification, purchase and payment of charges, collection of charges

<br/>
<br/>

B. Member management
Provision of membership services, personal identification, restrictions on use of members who violate the terms and conditions of use,
Sanctions on acts that interfere with the smooth operation of services and illegal use of services, confirmation of intention to subscribe, restrictions on the number of subscriptions, confirmation of consent from legal representatives when collecting personal information of children under the age of 14, confirmation of legal representatives, preservation of records for dispute settlement, handling complaints, delivery of notices, confirmation of intention to withdraw from membership

C. Development of new services, marketing, and use in advertising
Developing new services and providing customized services,
Statistics on service provision and advertising based on statistical characteristics, validation of services, provision of event information and opportunities to participate, provision of advertising information, identification of access frequency, and use of services by members

<br/>
<br/>
<div>3. Sharing and providing personal information</div>
<br/>
The company uses users' personal information within the scope of the notice "2. Purpose of Collection and Use of Personal Information",
Without the prior consent of the user, the user shall not use it beyond the scope or, in principle, disclose the user's personal information to the outside. However, the following cases are excluded.

<br/>
<br/>
A. When the user has agreed in advance
<br/>
<br/>
B. Where there is a request from an investigative agency in accordance with the provisions of the Act or in accordance with the procedures and methods prescribed by the Act for investigation purposes

<div>4. Retention and use period of personal information</div>
<br/>
In principle, the user's personal information is kept for smooth service until termination.
In the case of termination, it will be stored for 15 days after termination for the protection of users, such as requesting data recovery,
It will be deleted immediately after 15 days. However, the Act on Consumer Protection in Commercial Law, Electronic Commerce, etc
If it is necessary to preserve it according to the provisions of the relevant laws and regulations, the company will keep the membership information for a certain period as set forth in the relevant laws and regulations.
In this case, the company uses the information it keeps only for the purpose of keeping it, and the retention period is as follows.<br/>
<br/>
- Record of contract or withdrawal of subscription, etc. <br/>
Reason for preservation: Act on Consumer Protection in Electronic Commerce, etc.<br/>
Retention period: 5 years<br/>
- Records on payment and supply of goods, etc.<br/>
Reason for preservation: Act on Consumer Protection in Electronic Commerce, etc.<br/>
Retention period: 5 years<br/>
Reason for preservation: Act on Consumer Protection in Electronic Commerce, etc.<br/>
Retention period: 3 years<br/>
- Website Visit History<br/>
Reason for preservation: Communications Secret Protection Act<br/>
Retention period: 3 months<br/>
<br/>
<div>5. Procedures and methods for returning personal information</div>
<br/>
In principle, the user's personal information is kept for smooth service until termination.
In the case of termination, it will be stored for 15 days after termination for the protection of users, such as requesting data recovery,
It will be deleted immediately after 15 days, but if you request a return, you can get it back as follows.

<br/>
<br/>
A. Return procedure
- Returns when a user requests a return within 7 days of a service termination request
<br/>
<br/>
B. Method of return
- Return Data: User Information
-Provided type: CSV file

<br/>
<br/>
<div>6. Procedures and methods for destroying personal information</div>
<br/>

In principle, the user's personal information is kept for smooth service until termination.
In the case of termination, it will be stored for 15 days after termination for the protection of the user, such as requesting data recovery, and will be deleted immediately after 15 days.
<br/>
<br/>
A. Destruction procedure<br/>
- The information, chat, note, and file details entered by the user for membership registration, etc. are transferred to a separate DB, stored (see retention and usage period) according to internal policy and other reasons for information protection under relevant laws and regulations, and destroyed.<br/>
- This personal information is not used for any purpose other than being held unless it is by law.<br/>
<br/>
B. How to destroy it
- The personal information printed on the paper is crushed with a grinder or destroyed by incineration.
- Personal information stored in electronic file format is deleted using a technical method that does not allow records to be played back.
(1)User Information - Delete Administrator manually after 15 days
(2)Notes, Chat, File - Automatically delete every 15 days

<br/>
<br/>

<div>7. Rights of users and legal representatives and methods of exercising them</div>

<br/>
- Users and legal representatives may inquire or modify the personal information of themselves or children under the age of 14 at any time, and may refuse consent or request cancellation of membership (withdrawal of membership) if they do not agree to the processing of the company's personal information. However, in such cases, it may be difficult to use some or all of the services.<br/>
- Users or children under the age of 14 can be viewed, corrected, or withdrawn by clicking "change personal information" (or "correct member information") to inquire or modify their personal information, and "withdrawal of membership" to cancel the subscription (withdrawal of consent).<br/>
- Alternatively, contact the Personal Information Protection Officer in writing, by phone, or by e-mail, and we will take action without delay.<br/>
- If a user requests correction of an error in personal information, the personal information shall not be used or provided until the correction is completed. In addition, if incorrect personal information has already been provided to a third party, we will notify the third party of the correction processing result without delay so that the correction can be made.<br/>
- The company processes personal information terminated or deleted at the request of the user or legal representative in accordance with the "5. Retention and Use Period of Personal Information" and makes it impossible to view or use other roads.<br/>
<br/>

<div>8. Matters concerning the installation/operation and rejection of the automatic personal information collection device</div>

<br/>
A. What is a cookie? <br/>
- In order to provide personalized and customized services, the company uses a "cookie" that stores and calls users' information from time to time.<br/>
- Cookies are very small text files sent to the user's browser by the server that runs the website and are stored on the hard disk of the user's computer. When the user visits the website, the website server reads the contents of the cookies on the user's hard disk to maintain the user's preferences and provide customized services.<br/>
<br/>
B. Purpose of using cookies by the company
<br/>
- The company stores users' preferred settings through cookies to help users experience a faster web environment, and utilizes them to improve services for convenient use.<br/>
- The company analyzes the frequency of access, visit time, and number of visits of the service through cookies to identify the user's preferences and interests and use them to provide the service.<br/>
<br/>
C. Installation/Operation and Denial of Cookies
<br/>
- Users have the option of installing cookies. Therefore, users can allow all cookies by setting options in the web browser, go through confirmation every time they are saved, or refuse to save all cookies.<br/>
- However, if you refuse to save cookies, you may have difficulty using services that require login.<br/>
- How to specify whether to allow cookie installation (for Internet Explorer) is as follows.<br/>
① From the Tools menu, select Internet Options.<br/>
② Click the Privacy tab.<br/>
③ You can set the [Personal Information Processing Level] <br/>

<br/>

<div>9. Technical/management protection measures for personal information</div>
<br/>
A. The password encryption UCWORKS user's password is encrypted, stored, and managed, so only he or she knows,
The verification and change of personal information is only possible by the person who knows the password.
<br/>
<br/>

B. A countermeasure company for hacking, etc., is doing its best to prevent leakage or damage of members' personal information due to hacking, computer viruses, etc.
Data is backed up from time to time in case of damage to personal information,
The latest vaccine program is used to prevent users' personal information or data from being leaked or damaged,
Through encrypted communication, personal information can be transmitted safely on the network. And
We use intrusion prevention systems to control unauthorized access from outside, and we are trying to equip all possible technical devices to ensure systematic security.

<br/>
<br/>
C. Minimization of processing staff and personal information-related processing staff of education companies are limited to those in charge
For this purpose, a separate password is assigned and updated regularly, and the compliance with the UCWORKS personal information processing policy is always emphasized through occasional training for the person in charge.
<br/>
<br/>
D. Operation of an organization dedicated to personal information protection
In addition, if a problem is discovered by checking the implementation of the UCWORKS personal information processing policy and compliance with the person in charge through an in-house personal information protection organization, etc., immediately
We are working hard to correct and correct it. However, the company is not responsible for any problems caused by the leakage of personal information such as ID and password due to the user's carelessness or Internet problems.
<br/>
<br/>
If you need to report or consult other personal information infringement, please contact the institution below. <br/>
- Personal Information Infringement Reporting Center (privacy.kisa.or.kr/ 118) <br/>
- Cybercrime Investigation Team of the Supreme Prosecutors' Office (www.spo.go.kr/ 1301) <br/>
- National Police Agency Cyber Safety Bureau (cyberbureau.police.go.kr/ 182) <br/>

<br/>

<div>10. Scope of application</div>
<br/>
Regarding the act of collecting personal information by other websites linked to UCWORKS
This is to inform you that the 'UCWORKS Privacy Policy' does not apply. In addition, this 'UCWORKS Privacy Policy' applies only to members who have entered into a use contract with the company,
Does not apply to members who are authorized to UCWORKS under the management of members. <br/>
The personal information processing policy that applies to members should be checked with the personal information protection manager (or personal information protection manager) of each company (organization).
<br/><br/>
<div>11. Duty of notification</div>
<br/>
Addition of the current personal information processing policy,
If there is any deletion or modification, it will be notified through the 'Notice' on the website at least 7 days before the revision.
However, if there is any significant change in user rights, such as the collection and utilization of personal information, provision to a third party, etc., it shall be notified at least 30 days in advance. <br/>
– Announcement date: April 9, 2020 <br/>
– Effective date: April 9, 2020 <br/>
</span>
`



export const termsJP = `
<span className=\"font-bold text-[16px]\">[必須]利用約款</span>
<br/>
<br/>
<span className='text-[#1F62B9]text-[16px]font-bold'>個人情報処理方針</span>
<br/>
<br/>
<span>
(株)ユーシーウェア株式会社は利用者の個人情報を大切に扱っています。<br/>
<br/>
(株)ユーシーウェア株式会社(以下、「会社」という)は、情報通信網利用促進及び情報保護などに関する法律、個人情報保護法、通信秘密保護法、電気通信事業法など情報通信サービス提供者が遵守しなければならない関連法令上の個人情報保護規定を遵守し、関連法令に基づいた個人情報方針を定め、利用者権益保護に最善を尽くしています。
<br/>
<br/>
本個人情報処理方針は、会社が提供するUCWORKSサービスに限り適用され、次のような内容を含んでいます。<br/>
<br/>
1. 収集する個人情報の項目及び収集方法<br/>
2. 個人情報の収集及び利用目的<br/>
3. 個人情報の共有·提供<br />
4. 個人情報の保有及び利用期間<br/>
5. 個人情報の返還手続き及び方法<br />
6. 個人情報破棄手続き及び方法<br/>
7. 利用者及び法定代理人の権利とその行使方法<br/>
8. 個人情報自動収集装置の設置/運営及び拒否に関する事項<br/>
9. 個人情報の技術的/管理的保護対策<br/>
10. 適用範囲<br />
11. 告知の義務<br />

<br/>

<div>1.収集する個人情報の項目及び収集方法</div>

<br/>

A. 収集する個人情報の項目
会社は会員登録、円滑な顧客相談、サービス提供のため、以下のような最小限の個人情報を必須項目として収集しています。<br/>
- 必須項目:管理者の名前、会社名、会社の電話番号、メールアドレス(加入認証情報または基本入力情報用)、携帯電話番号(加入認証情報)、管理者アカウント情報(ID、パスワード)
会社と契約関係にある提携会社を通じたサービス利用時、契約過程で以下のような個人情報を収集します。<br/>
- 必須項目: 管理人情報(氏名、アカウント情報、メールアドレス、電話番号)
また、サービス利用過程や事業処理過程で以下のような情報が追加で収集されることがあります。<br/>
-取引情報:個人の場合、生年月日(定期決済に限る)、クレジットカード情報(クレジットカード番号、有効期間、パスワードの前の二桁)、税金計算書発行のための会計担当者情報(氏名、連絡先、メールアドレス)など
- IPアドレス、クッキー、訪問日時、サービス利用記録、不良利用記録など<br/>
-必須項目:ドメイン情報、会社(団体)名、代表者名、代表住所、代表番号(有線)、事業者登録番号、法人登録番号、固有番号<br/>
- 選択項目:ファクス番号<br />

<br/>

B. 個人情報の収集方法<br/>
会社は、次のような方法で個人情報を収集します。<br/>
- ホームページ·書面フォーム·FAX·電話·相談掲示板·メール·イベント応募·配送リクエスト<br />
- 協力会社からの提供<br />
- 生成情報収集ツールによる収集<br/>
<br/>

<div>2.個人情報の収集及び利用目的</div>

<br/>

A. サービス提供に関する契約履行及びサービス提供に伴う料金精算<br/>
コンテンツ提供、特定オーダーメードサービス提供、物品配送または請求書などの発送、本人確認、購買および料金決済、料金取り立て

<br/>
<br/>

B. 会員管理
会員制サービスの提供、個人識別、利用約款違反会員に対する利用制限措置、
サービスの円滑な運営に支障を及ぼす行為及びサービス不正利用行為制裁、加入意思確認、加入及び加入回数制限、満14歳未満児童の個人情報収集時の法定代理人の同意有無確認、今後の法定代理人本人確認、紛争調整のための記録保存、苦情処理などの苦情処理、告知事項伝達、会員脱退意思の確認

C. 新規サービス開発及びマーケティング·広告への活用
新規サービスの開発及びカスタマイズサービスの提供、
統計学的特性によるサービス提供及び広告掲載、サービスの有効性確認、イベント情報及び参加機会の提供、広告性情報の提供、アクセス頻度の把握、会員のサービス利用に関する統計

<br/>
<br/>
<div>3.個人情報の共有及び提供</div>
<br/>
会社は利用者の個人情報を「2.個人情報の収集目的及び利用目的」で告知した範囲内で使用し、
利用者の事前同意なしには、同範囲を超えて利用したり、原則的に利用者の個人情報を外部に公開したりしません。 ただし、下記の場合は例外とします。

<br/>
<br/>
A. 利用者が事前に同意した場合
<br/>
<br/>
B. 法令の規定に基づき、または捜査目的で法令に定められた手続きと方法によって捜査機関の要求がある場合

<div>4.個人情報の保有及び利用期間</div>
<br/>
利用者の個人情報は、原則として解約するまで円滑なサービス提供のために保管されます。
解約をする場合、データ復旧要請など利用者の保護のために解約後15日間保管され、
15日経過した後、即時削除されます。 ただし、商法、電子商取引等における消費者保護に関する法律等
関係法令の規定により保存する必要がある場合、会社は関係法令に定められた一定期間、会員情報を保管します。
この場合、会社は保管する情報をその保管の目的にのみ利用し、保存期間は以下のとおりです。<br/>
<br/>
- 契約又は申込みの撤回等に関する記録<br/>
保存理由:電子商取引等における消費者保護に関する法律<br/>
保存期間:5年<br />
- 代金決済·財貨等の供給に関する記録<br />
保存理由:電子商取引等における消費者保護に関する法律<br/>
保存期間:5年<br />
保存理由:電子商取引等における消費者保護に関する法律<br/>
保存期間:3年<br />
- ウェブサイトの訪問記録<br />
保存理由:通信秘密保護法<br />
保存期間:3ヶ月<br />
<br/>
<div>5. 個人情報の返還手続き及び方法 </div>
<br/>
利用者の個人情報は、原則として解約するまで円滑なサービス提供のために保管されます。
解約をする場合、データ復旧要請など利用者の保護のために解約後15日間保管され、
15日が過ぎた後、直ちに削除されますが、ユーザーが返還を要請する場合、以下のように返還を受けることができます。

<br/>
<br/>
A。 返還手続き
- ユーザーがサービス解約要請後7日以内に返還要請をした場合、返還
<br/>
<br/>
私. 返却方法
- 返却データ:ユーザー情報
- 提供形態: CSV ファイル

<br/>
<br/>
<div>6.個人情報破棄手続き及び方法</div>
<br/>                    

利用者の個人情報は、原則として解約するまで円滑なサービス提供のために保管されます。
解約をする場合、データ復旧要請など利用者の保護のために解約後15日間保管され、15日が過ぎた後に直ちに削除されます。
<br/>
<br/>
 A. 破棄手続き<br/>
 - ユーザーが会員登録などのために入力した情報やチャット、メッセージ、ファイルの内訳は、別のDBに移され、内部方針やその他の関連法令による情報保護の理由によって(保有期間および利用期間参照)保存された後、破棄されます。<br/>
 - 同個人情報は法律による場合でなければ保有される以外の目的で利用されません<br/>
<br/>
B. 破棄方法
- 紙に出力された個人情報は、シュレッダーで粉砕するか、焼却を通じて破棄します。
- 電子ファイル形式で保存された個人情報は、記録を再生できない技術的方法を使用して削除します。
(1)ユーザー情報-解約リクエスト 15日以降の管理者手動削除
(2)メッセージ、チャット、ファイル - 15日周期で自動削除

<br/>
<br/>

<div>7.利用者及び法定代理人の権利とその行使方法</div>

<br/>
- 利用者及び法定代理人は、いつでも登録されている自分または当該満14歳未満の児童の個人情報を照会または修正することができ、会社の個人情報の処理に同意しない場合、同意を拒否したり、登録解除(会員退会)を要請することができます。 ただし、その場合、サービスの一部または全部の利用が難しい場合があります。<br/>
- 利用者または満14歳未満の児童の個人情報照会、修正のためには「個人情報変更」(または「会員情報修正」など)を、加入解約(同意撤回)のためには「会員脱退」をクリックして直接閲覧、訂正、または脱退が可能です。<br/>
- もしくは、個人情報保護責任者に書面、電話、または電子メールでご連絡いただければ、遅滞なく措置いたします。<br/>
- 利用者が個人情報の誤りに対する訂正を要請された場合は、訂正を完了するまで当該個人情報を利用または提供しません。 また、間違った個人情報を第三者にすでに提供している場合は、訂正処理の結果を第三者に遅滞なく通知し、訂正が行われるようにします。<br/>
- 会社は利用者または法定代理人の要請により解約または削除された個人情報は「5.個人情報の保有および利用期間」に明示されたとおり処理し、その他の道路閲覧または利用できないように処理しています。<br/>
<br/>

<div>8.個人情報自動収集装置の設置/運営及び拒否に関する事項</div>

<br/>
A. クッキーとは?<br />
- 会社はパーソナライズされたサービスを提供するために、利用者の情報を保存し、随時読み込む「クッキー(cookie)」を使用します。<br/>
- クッキーは、ウェブサイトの運営に利用されるサーバーが利用者のブラウザに送る非常に小さなテキストファイルで、利用者のコンピュータのハードディスクに保存されます。 以後、利用者がウェブサイトに訪問する場合、ウェブサイトサーバーは利用者のハードディスクに保存されているクッキーの内容を読み取って利用者の環境設定を維持し、オーダーメード化されたサービスを提供するために利用されます。<br/>
<br/>
B. 会社のクッキー使用目的
<br/>
- 会社はクッキーを通じて利用者が好む設定などを保存し、利用者により速いウェブ環境を支援し、便利な利用のためにサービス改善に活用します。<br/>
- 会社はクッキーを通じてサービスの接続頻度、訪問時間、訪問回数などを分析し、利用者の好みと関心分野を把握してサービス提供に活用します。<br/>
<br/>
C. クッキーのインストール/運営及び拒否
<br/>
- ユーザーは、クッキーのインストールの選択権を持っています。 したがって、ユーザーはウェブブラウザでオプションを設定することで、すべてのクッキーを許可したり、クッキーが保存されるたびに確認を経たり、あるいはすべてのクッキーの保存を拒否することもできます。<br/>
- ただし、クッキーの保存を拒否する場合は、ログインが必要なサービスの場合、利用が困難になることがあります。<br/>
- クッキーのインストールを許可するかどうかを指定する方法（Internet Explorerの場合）は、次のとおりです。<br/>
① [ツール] メニューから、[インターネット オプション] を選択します。 <br/>
② [個人情報タブ] をクリックします。 <br/>
③ [個人情報処理レベル]を設定してください。 <br/>

<br/>

<div>9.個人情報の技術的/管理的保護対策</div>
<br/>
A. パスワード暗号化UCWORKS利用者のパスワードは暗号化され保存·管理されており、本人だけが知っており、
個人情報の確認および変更も、パスワードを知っている本人によってのみ可能です。
<br/>
<br/>

B. ハッキングなどに備えた対策会社はハッキングやコンピューターウイルスなどによって会員の個人情報が流出したり毀損されるのを防ぐために最善を尽くしています。
個人情報の毀損に備えて資料を随時バックアップしており、
最新のワクチンプログラムを利用して利用者の個人情報や資料が流出したり損傷したりしないように防止しており、
暗号化通信などを通じてネットワーク上で個人情報を安全に伝送できるようにしています。 そして。
侵入遮断システムを利用して外部からの無断アクセスを統制しており、その他システム的に保安性を確保するための可能なすべての技術的装置を備えようと努力しています。

<br/>
<br/>
C. 処理職員の最小化及び教育会社の個人情報関連処理職員は担当者に限定させており
そのための別途のパスワードを付与して定期的に更新しており、担当者に対する随時教育を通じてUCWORKS個人情報処理方針の遵守を常に強調しています。
<br/>
<br/>
D. 個人情報保護専門担当機構の運営
また、社内の個人情報保護専担機構などを通じてUCWORKS個人情報処理方針の履行事項及び担当者の遵守状況を確認し、問題が発見された場合、直ちに
修正して直せるように努力しています。 但し、利用者本人の不注意やインターネット上の問題でID、パスワードなど個人情報が流出して発生した問題に対して会社は一切の責任を負いません。

<br/>
<br/>
その他、個人情報侵害に対する申告や相談が必要な場合は、下記の機関にお問い合わせください。 <br/>
- 個人情報侵害申告センター(privacy.kisa.or.kr/ 局番なしで118) <br/>
- 最高検察庁サイバー犯罪捜査団（www.spo.go.kr/ 局番なしで1301）<br/>
- 警察庁サイバー安全局(cyberbureau.police.go.kr/ 局番なし182)<br/>

<br/>

<div>10. 適用範囲</div>
<br/>
UCWORKSにリンクされている他のウェブサイトが個人情報を収集する行為については、本
「UCWORKS個人情報処理方針」が適用されないことをお知らせします。 なお、本「UCWORKS個人情報処理方針」は、会社と利用契約を締結した会員に限り適用され、
会員の管理下でUCWORKSの権限を付与された構成員には適用されません。 <br/>
構成員に適用される個人情報処理方針は、各会社(団体)の個人情報保護責任者(または個人情報保護責任者)を通じて確認する必要があります。
<br/><br/>
<div>11.告知の義務</div>
<br/>
現個人情報処理方針の内容の追加、
削除および修正がある場合は、改正の最低7日前からホームページの「お知らせ」を通じて告知します。
ただし、個人情報の収集および活用、第三者提供などのように利用者権利の重要な変更がある場合には、少なくとも30日前に告知します。 <br/>
– 公告日 : 2020年4月9日<br />
– 施行日:2020年4月9日<br/>
</span>
`