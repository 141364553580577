import { lazy, Suspense, useEffect, useState } from "react";
import Text from "../../ui/Text";
import { paymentAdminPageFetchData } from "../../utils/request";
import { PaymentInfoType } from "../../@types";
import Skeleton from "react-loading-skeleton";
import ModalLayout from "../Modal/Layout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import {
  setCardInfoData,
  setResetCardInfo,
} from "../../redux/slice/cardInfoListSlice";
import { modalOpen } from "../../redux/slice/cardInfoModalSlice";
import {
  cardFormClose,
  cardFormOpen,
} from "../../redux/slice/cardInfoFormSlice";
import { CgDanger } from "react-icons/cg";
import { setChecked } from "../../redux/slice/paymentChecboxSlice";

const PaymentInfoMethod = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();
  const cardInfo = useSelector((state: RootState) => state.cardInfoList);
  const [paymentInfos, setPaymentInfos] = useState<PaymentInfoType[]>([]);
  const cardInfoFormVisible = useSelector(
    (state: RootState) => state.cardInfoForm.isOpen
  );

  const handleCardInfoModal = (registrationOrChage: boolean) => {
    if (registrationOrChage) {
      // 등록된 카드가 존재한다면
      dispatch(modalOpen());
    } else {
      dispatch(cardFormOpen());
    }
  };

  const fetchCardInfo = () => {
    paymentAdminPageFetchData({ setLoading })
      .then((data) => {
        dispatch(setCardInfoData(data.data));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCardInfo();

    return () => {};
  }, [cardInfoFormVisible]);

  if (loading) {
    return <SkeletonUI />;
  }

  return (
    <>
      <div className="space-y-2">
        <Text className="font-normal text-[14px]">결제수단</Text>
        <div className="w-[396px] h-[76px] p-[16px] flex flex-col border-[1px] border-solid border-[#DFDFDF] rounded-[4px]">
          {!cardInfo.selectedCardInfo && (
            <Text className="font-bold text-[14px]">
              등록된 카드가 없습니다.
            </Text>
          )}
          {cardInfo.selectedCardInfo && (
            <div className="flex flex-row justify-between items-center">
              <div>
                <Text className="font-bold text-[14px]">신용카드</Text>
                <Text className="font-normal text-[14px]">
                  {cardInfo.selectedCardInfo.cardNumber}
                </Text>
              </div>
              {!cardInfo.selectedCardInfo.isValid && (
                <CgDanger className="text-red-600 w-[30px] h-[30px]" />
              )}
            </div>
          )}
        </div>

        <Text
          className="font-bold text-[14px] text-[#1F62B9] cursor-pointer"
          onClick={() => {
            handleCardInfoModal(!!cardInfo.selectedCardInfo);
            dispatch(setChecked({ value: false }));
          }}
        >
          {cardInfo.selectedCardInfo ? "결제 수단 변경" : "결제 수단 등록"}
        </Text>
      </div>
    </>
  );
};

const SkeletonUI = () => {
  return (
    <div className="space-y-2">
      <Text className="font-normal text-[14px]">결제수단</Text>
      <div className="w-[396px] h-[76px] p-[10px] flex flex-col border-[1px] border-solid border-[#DFDFDF] rounded-[4px] ">
        <Skeleton width={120} height={20} />
        <Skeleton width={200} height={20} />
      </div>

      <div className="font-bold text-[14px] text-[#1F62B9] cursor-pointer">
        <Skeleton width={100} height={15} />
      </div>
    </div>
  );
};

export default PaymentInfoMethod;
