import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { styled } from "styled-components";
import ToolTipManage from "../Common/ToolTip";

interface Props{
    $clicked: boolean;
    onClick: () => void;
    text: string;
    tenantId: string;
    detail?:boolean;
    educationFlag?: boolean;
}

const ManageButton = ({$clicked, onClick, text, tenantId, detail = false, educationFlag}: Props) => {
    const isScreenSM = useMediaQuery({maxWidth: 1068});
    const [show, setShow] = useState<boolean>(false);

    let timeoutId: NodeJS.Timeout | null = null;
    const handleMouseEnter = () => {
        setShow(true); 
    };

    const handleMouseLeave = () => {
        setShow(false);
    };
    
    return (
        <div className="relative px-[15px] py-[4px]">
            <MemberButton $clicked={$clicked} $adminUrl={''} $detail={detail} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={(e) => { 
                e.stopPropagation();
                onClick(); 
            }} data-tip data-for="registerTip">
                <MemberButtonText>
                    {text}
                </MemberButtonText>
            </MemberButton>
            <ToolTipManage tenantId={tenantId} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} show={show} educationFlag={educationFlag}/>
        </div>
    )
}

const MemberButton = styled.button<{ $clicked : boolean, $adminUrl: string, $detail:boolean}>`
    position: relative;
    display: line-flex;
    height: 36px;
    justify-content:center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    width: ${(props) => (props.$detail ? '140px' : '95px')};
    border-radius: 4px;
    border: 1px solid #1F62B9;
    background-color: transparent;
    &:hover{
        background-color: ${(props) => (props.$clicked ? '#D9E5F9' : '#B8D3FE')};
    }

    @media (max-width: 1356px) {
        padding: 4px;
    }
`;

const MemberButtonText= styled.span`
    color: #1F62B9;
    display: flex;
    align-items:center;
    width: 100%;
    justify-content: center;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;

    @media (max-width: 1416px) {
        font-size: 12px; //사파리 글자깨짐 수정
    }
`;


export default ManageButton;
