import ModalLayout from "../Modal/Layout";
import Text from "../../ui/Text";
import Button from "../../ui/Button";
import Radio from "../../ui/Radio";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import clsx from "clsx";
import { modalClose, modalOpen } from "../../redux/slice/cardInfoModalSlice";
import { setSelectedKey } from "../../redux/slice/cardInfoListSlice";
import { CgDanger } from "react-icons/cg";
import { MdAddCard } from "react-icons/md";
import { cardFormOpen } from "../../redux/slice/cardInfoFormSlice";
const CardPaymentMethodModal = () => {
  const cardInfo = useSelector((state: RootState) => state.cardInfoList);
  const [tempSelectedCardKey, setTempSelectedCardKey] = useState<string | null>(
    cardInfo.selectedKey
  );
  const cardPaymentModalIsOpen = useSelector(
    (state: RootState) => state.cardInfoModal.isOpen
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleTempSelectCard = (key: string) => {
    setTempSelectedCardKey(key);
  };
  const handleSelectCard = () => {
    dispatch(setSelectedKey(tempSelectedCardKey!));
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <ModalLayout
      isOpen={cardPaymentModalIsOpen}
      onClose={() => {
        dispatch(modalClose());
      }}
      height={"504px"}
      width="374px"
      paddingX="24px"
    >
      <div className="w-full flex flex-col gap-3">
        <Text className="font-extrabold text-[18px] mb-5">결제 수단 변경</Text>

        <div className="w-full h-[360px]">
          <div className="space-y-2 w-full">
            {cardInfo.data.length === 0 && <div>카드가 없습니다.</div>}
            {cardInfo.data.map((data, index) => (
              <CardInfo
                key={index}
                isSelected={tempSelectedCardKey === data.cardId}
                onSelect={handleTempSelectCard}
                cardInfo={data.cardNumber}
                encryptedKey={data.cardId}
                isValid={data.isValid}
              />
            ))}
            {cardInfo.data.length < 4 && (
              <div className="w-full flex justify-center">
                <Button
                  variant="primary"
                  className="w-full flex flex-row items-center justify-center p-4 gap-1"
                  onClick={() => {
                    dispatch(modalClose());
                    dispatch(cardFormOpen());
                  }}
                >
                  <Text>카드추가</Text>
                  <MdAddCard className="w-[20px] h-[20px]" />
                </Button>
              </div>
            )}
          </div>
        </div>

        <Button
          variant="success"
          className="w-full h-[42px]"
          onClick={() => {
            handleSelectCard();
            dispatch(modalClose());
          }}
        >
          확인
        </Button>
      </div>
    </ModalLayout>
  );
};

const CardInfo = ({
  isSelected,
  onSelect,
  cardInfo,
  isValid,
  encryptedKey,
}: {
  isSelected: boolean;
  onSelect: (key: string) => void;
  cardInfo: string;
  isValid: boolean;
  encryptedKey: string;
}) => {
  return (
    <div
      className={clsx(
        "w-full h-[76px] p-[16px] flex flex-row gap-2 border-[1px] border-solid rounded-[4px] cursor-pointer transition-colors duration-500",
        {
          "border-[2px] border-[#1F62B9]": isSelected,
          "border-[#DFDFDF]": !isSelected,
        }
      )}
      onClick={() => onSelect(encryptedKey)}
    >
      <Radio checked={isSelected} readOnly />
      <div className="flex flex-row w-full justify-between items-center">
        <div>
          <Text className="font-bold text-[14px]">신용카드</Text>
          <Text className="font-normal text-[14px]">{cardInfo}</Text>
        </div>
        {!isValid && <CgDanger className="text-red-600 w-[30px] h-[30px]" />}
      </div>
    </div>
  );
};

export default CardPaymentMethodModal;
