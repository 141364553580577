import { useMediaQuery } from "react-responsive";
import ModalLayout from "./Layout";
import { ReactNode } from "react";

const TermLayout = ({ isOpen, onClose, height, width, children, isScreenSM }:
     ModalProps & { height: string, width: string, children: ReactNode, isScreenSM: boolean}) => {
    return (
        <ModalLayout isOpen={isOpen} onClose={onClose} height={height} width={isScreenSM ? '400px' : width}>
           {children}
        </ModalLayout>
    )
}

export default TermLayout;

