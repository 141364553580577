import { Controller } from "react-hook-form";
import Input from "../../ui/Input";
import { CardNumberForm, ReactHookFormProps } from "../../@types";
import { useEffect } from "react";
import Text from "../../ui/Text";

const Cvc = ({
  control,
  errors,
  setValue,
  getValues,
}: ReactHookFormProps<CardNumberForm>) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/^\d{0,4}$/.test(e.target.value)) {
      setValue("cvc", e.target.value);
    }
  };

  useEffect(() => {}, [errors]);
  return (
    <div className="relative">
      <Controller
        name="cvc"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <Input
            {...field}
            type="text"
            maxLength={3}
            placeholder="cvc"
            className="w-full h-[42px]"
          />
        )}
      />
      {errors.cvc && (
        <Text variant="danger" className="-top-5 right-0 absolute">
          {errors.cvc.message}
        </Text>
      )}
    </div>
  );
};

export default Cvc;
