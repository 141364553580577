import { useEffect, useRef, useState } from "react";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import { toastError, toastSuccess } from "../../utils/toast";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import { fetchWorkspaceDetailGET } from "../../redux/slice/workspaceDetailSlice";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";

interface Props {
    handleModal: (isOpen: boolean) => void;
    tenantId: string;
    id: string;
}

const WorkspaceNameModal = ({ handleModal, tenantId, id }: Props) => {

    const modalRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const { t, i18n } = useTranslation();

    const fetchNamePUT = () => {
        setLoading(true);
        if(inputRef.current && inputRef.current.value.trim()){
            axiosInstance.put('/workspace/name', {
                tenantId: tenantId,
                workspaceName: inputRef.current.value
            }, requestAuth({}))
            .then((data) =>{
                setLoading(false);
                toastSuccess(t('detail.changeSuccess'));
                dispatch(fetchWorkspaceDetailGET(id));
                handleModal(false);

            }).catch((error) => {
                setLoading(false);
                if(error instanceof AxiosError && error.code === 'ERR_BAD_REQUEST'){
                    toastError(t('join.auth.fail'));
                }else{
                    toastError('SERVER ERROR');
                }
                handleModal(false);

            });

        }else{
            toastError(t('detail.changeFail'));
        }
    }

    useEffect(() => {
        const handleActive = (e: MouseEvent) => {
            if(modalRef.current && !modalRef.current.contains(e.target as HTMLElement)){
                handleModal(false);
            }
        }

        document.addEventListener("mouseup", handleActive);

        return () => { document.removeEventListener("mouseup", handleActive); }
    })

    
    return (
        <section ref={modalRef} className="w-[406px] h-[226px] px-[32px] py-[43px] rounded-[6px] shadow-workspace-name fixed top-[30%] left-[50%] ml-[-203px] z-50 bg-[#FFF] flex flex-col items-center justify-center gap-[25px]">
            {
                loading 
                ?
                    <Loader/>
                :
                <>
                    <div className="font-noto-sans-kr leading-[20.27px] text-[14px]">
                        {t('detail.changeName')}
                    </div>

                    <input className="w-[320px] h-[42px] border border-solid border-[#C8CACE] p-[12px] rounded-[4px]" placeholder={t('detail.changeInput')} ref={inputRef}/>

                    <div className="flex flex-row gap-10">
                        <button className="w-[100px] bg-[#FFFFFF] h-[36px] px-[11px] py-[8px] border-[1px] border-solid border-[#555555] rounded-[4px] flex items-center justify-center"
                            onClick={() => { handleModal(false) }}
                        >
                            <span className="font-noto-sans-kr text-[14px] leading-[20.27px] w-[80px]">
                                {t('detail.cancel')}
                            </span>
                        </button>
                        
                        <button className="w-[100px] bg-[#1F62B9] h-[36px] px-[20px] py-[8px] border-[1px] border-solid border-[#1F62B9] rounded-[4px] flex items-center justify-center"
                            onClick={() => {
                                fetchNamePUT();
                            }}
                        >
                            <span className="font-noto-sans-kr text-[14px] leading-[20.27px] text-[#FFFFFF]">
                                {t('detail.ok')}
                            </span>
                        </button>

                    </div>
                </>

            }
        </section>
    )
}

export default WorkspaceNameModal;