import React, { useEffect } from "react";
import PlatformBule from "../../components/Main/PlatformBlue";
import PlatformPurple from "../../components/Main/PlatformPurple";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";

import SliderSection from "../../components/Main/SliderSection";

import Footer from "../../components/Footer";
import ImageFooter from "../../components/Main/ImageFooter";
import Features from "../../components/Main/Feature";
import { useMediaQuery } from "react-responsive";
import axiosInstance from "../../utils/request/axios.instance";
import { toastError, toastSuccess } from "../../utils/toast";

const MainPage = () => {
  const selector = useSelector((state: RootState) => state.memberLogin);
  const isScreenSM = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    // axiosInstance.get('/member/warm');
  }, []);

  return (
    <div className="overflow-x-hidden">
      <section>
        {selector.authentication ? <PlatformPurple /> : <PlatformBule />}
      </section>

      <section>
        <Features />
      </section>

      <section
        className={`${isScreenSM ? "mb-[610px]" : "mb-[640px]"} h-[640px]`}
      >
        <SliderSection />
      </section>

      <section className="absolute bottom-0 right-0 w-[100%]">
        <ImageFooter />
        <Footer className="h-[400px]" />
      </section>
    </div>
  );
};

export default MainPage;
