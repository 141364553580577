import { combineReducers, configureStore } from "@reduxjs/toolkit";
import workspaceReducer from "./slice/workspaceSlice";
import workspaceDetailReducer from "./slice/workspaceDetailSlice";
import workspaceDataReducer from "./slice/workspaceDataSlice";
import memberLoginReducer from "./slice/memberSlice";
import paymentHistoryReducer from "./slice/paymentHistorySlice";
import numberOfUsersReducer from "./slice/numberOfUsers";
import billingAdditionalModalReducer from "./slice/billingAdditionalModalSlice";
import cardInfoListReducer from "./slice/cardInfoListSlice";
import cardInfoModalReducer from "./slice/cardInfoModalSlice";
import cardInfoFormReducer from "./slice/cardInfoFormSlice";
import paymentAgreedCheckboxSlice from "./slice/paymentChecboxSlice";

const configReducer = combineReducers({
  workspace: workspaceReducer,
});

const configStore = configureStore({
  reducer: {
    workspace: workspaceReducer,
    workspaceDetail: workspaceDetailReducer,
    workspaceData: workspaceDataReducer,
    memberLogin: memberLoginReducer,
    paymentHistory: paymentHistoryReducer,
    numberOfUsers: numberOfUsersReducer,
    billingAdditionalModal: billingAdditionalModalReducer,
    cardInfoList: cardInfoListReducer,
    cardInfoModal: cardInfoModalReducer,
    cardInfoForm: cardInfoFormReducer,
    paymentAgreedCheckbox: paymentAgreedCheckboxSlice,
  },
});

export type RootState = ReturnType<typeof configStore.getState>;
export type AppDispatch = typeof configStore.dispatch;

export default configStore;
