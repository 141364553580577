import {CheckIcon, CompanyBlueIcon, EducationBlueIcon, EducationWhiteIcon} from "../../icons";
import SelectionNavigator from "./SelectionNavigator";
import {useState} from "react";
import EducationAd from "./EducationAd";
import FreeCard from "../PriceCard/FreeCard";
import StandardCard from "../PriceCard/StandardCard";
import EnterpriseCard from "../PriceCard/EnterpriseCard";

interface Props {
    handleLicenseClick: (licenseDTO: PriceLicense) => void;
}
const PriceCardsForJP = ({ handleLicenseClick }: Props) => {

    const [selectState, setSelectState] = useState<"EDUCATION" | "COMPANY">("COMPANY");

    const handleState = (state: "EDUCATION" | "COMPANY") => {
        setSelectState(state);
    }

    return (
        <section className="space-y-3.5 flex flex-col justify-center w-full">
            <div className="flex flex-col justify-center items-center gap-7">
                <SelectionNavigator selectState={selectState} onClick={handleState}/>
                {(selectState === "EDUCATION") && <EducationAd/> }
            </div>

            <div className="flex flex-row items-center justify-center gap-6 w-full md:flex-col">
                <FreeCard onClick={handleLicenseClick} price={0} educationFlag={(selectState === "EDUCATION")}/>
                <StandardCard onClick={handleLicenseClick} price={600} educationFlag={(selectState === "EDUCATION")}/>
                <EnterpriseCard onClick={handleLicenseClick} price={1000} educationFlag={(selectState === "EDUCATION")}/>
            </div>
        </section>
    )
}

export default PriceCardsForJP;
