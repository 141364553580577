import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toastError, toastSuccess } from "../utils/toast";
import axios from "axios";
import axiosInstance, { requestAuth } from "../utils/request/axios.instance";
import useModifyUsers from "./use.modify.users.size";

const useModifyStorage  = (): [(params: ModifyStorageParams) => void, boolean] => {
    const navigate = useNavigate();
    const [putLoading, setLoading ] = useState<boolean>(false);

    const increasedStorageCapacity = async (params: ModifyStorageParams) => {
        setLoading(true);
        try {
            const response = await axiosInstance.put('/workspace/tenant/storage/modify', params, requestAuth({}));
            if (response.status === 200) {
                toastSuccess('스토리지 용량이 증가되었습니다.');
            }
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                toastError('일시적인 오류입니다.');
            } else {
                toastError('알 수 없는 오류가 발생했습니다.');
            }
        } finally {
            setLoading(false);
            navigate('/team');
        }
    };

    return [increasedStorageCapacity, putLoading];
}

export default useModifyStorage;
