import React from "react";
import MainLayout from "../../components/Layout/MainLayout";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PasswordSuccessPage  = () => {
    const { t } = useTranslation();

    return (
        <MainLayout imgSrc={"/images/pwd_bg_2.png"}>
            <Wrapper>
                <H3>{t('pwd.success.title')}</H3>
                <PwdText>{t('pwd.success.subTitle')}</PwdText>

                <Link to={"/login"} 
                    dangerouslySetInnerHTML={{
                        __html: t('pwd.success.goToLogin')
                    }}></Link>
            </Wrapper>
        </MainLayout>
    )
}

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;

    a{
        text-decoration-line: none;
        color: #1F62B9;
        font-family: Noto Sans KR;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

const H3 =styled.h3`
    height: 30px;
    color: #000;
    font-family: Noto Sans KR;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;

    @media (max-width: 590px) {
        margin-bttom: 0px;
        height: 11px;
        font-size: 22px;
    }
`;

const PwdText = styled.div`
    color: #555;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-bottom: 45px;
`;

export default PasswordSuccessPage;
