import { ThreeDots } from "svg-loaders-react";

interface Props {
  className?: string;
}

const Loader = ({ className, ...props }: Props) => (
  <div
    className={`flex justify-center items-center ${className ?? ""}`}
    {...props}
  >
    <ThreeDots fill="#599aee" width={60} height={15} />
  </div>
);

export default Loader;
