import React, { useCallback, useEffect, useRef, useState } from "react";
import { css, styled } from "styled-components";
import { BottomArrow, Cancellation } from "../../icons";
import { boolean } from "yup";
import { LANGUAGES } from "../../utils/content";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { fetchMemberInfo } from "../../redux/slice/memberSlice";
import { useTranslation } from "react-i18next";

interface Props {
    isOpen: boolean;
    toggleMenu: () => void;
}

const Menu = ({ isOpen, toggleMenu }: Props) => {
    const navigate = useNavigate();
    const menuRef = useRef<HTMLDivElement>(null);
    const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const [auth, setAuth] = useState<boolean>();
    const { i18n } = useTranslation();

    const handleLogout = useCallback(() => {
        sessionStorage.removeItem('uc-portal-token');
        dispatch(fetchMemberInfo());
        navigate('/login');
    },[]);

    const handleLangClick = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('i18nextLng', lang);
    }

    useEffect(() => {
        dispatch(fetchMemberInfo()).then(data => {
          if(data.payload){
            if(data.payload.data !== -1){
                setAuth(true);
            }else{
                setAuth(false);
            }
          }
        });

        const handleActive = (e: MouseEvent) => {
            if(menuRef.current && !menuRef.current.contains(e.target as HTMLElement)){
                toggleMenu();
            }
        }

        document.addEventListener("mouseup", handleActive);
        return () => { document.removeEventListener("mouseup", handleActive)}
    },[]);

    return (
          <>
            <Background $isOpen={isOpen}/>
                <Container $isOpen={isOpen} ref={menuRef}>
                    <span className="absolute right-[8px] top-[8px] z-10" onClick={toggleMenu}>
                       <Cancellation/>
                    </span>

                    <ListWrapper>
                       <div className="flex items-center gap-2" onClick={() => {setSubMenuOpen((subMenuOpen) => !subMenuOpen)}}>
                           <Text> LANGUAGES</Text>
                           <ArrowWrapper $subMenuOpen={subMenuOpen}>
                                <BottomArrow/>
                           </ArrowWrapper>
                       </div>

                       <SubMenuWrapper $subMenuOpen={subMenuOpen}>
                           {LANGUAGES.map((lang, index) => (
                               <SubText key={index} onClick={() => {
                                handleLangClick(lang);
                                toggleMenu();
                            }}>{lang}</SubText>
                           ))}
                       </SubMenuWrapper>

                       {
                           auth
                           ?
                           <Text onClick={handleLogout}> LOGOUT </Text>
                           :
                           <Text onClick={() => { navigate('/login') }}> LOGIN </Text>
                       }
                    </ListWrapper>
                </Container>
          </>
    )
}

const Background = styled.div<{ $isOpen: boolean }>`
    display:${props => props.$isOpen ? 'inline-block' : 'none'};
    position:fixed;
    width: 100%;
    height: 100%;
    background: #00000080;
    z-index: 10000;
    transition: 3s;
`;

const Container = styled.div<{ $isOpen: boolean }>`
    position:fixed;
    top: 0;
    right: ${(props) => (props.$isOpen ? '0' : '-100%')};
    max-width: 400px;
    width: 70%;
    height: 100%;
    background-color: #FFF;
    z-index: 10000;
    box-shadow: 0px 4px 18px 4px rgba(0, 0, 0, 0.10);
`

const ListWrapper = styled.div`
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // padding: 24px;
`;

const Text = styled.div`
    width: 87%;
    height: 40px;
    text-align: start;
    color: #555;
    font-family: "Noto Sans KR";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items:center;
    padding-left: 40px;
`;

const SubText = styled.div`
    color: #555;
    font-family: "Noto Sans KR";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 30px;
    padding-left: 58px;
    display: flex;
    align-items: center;

    &:hover{
        background-color: #F7F7F7;
    }
`;

const SubMenuWrapper = styled.div<{ $subMenuOpen: boolean }>`
    ${(props) => 
        props.$subMenuOpen ?
            css`
                max-height: 120px;
                transition: max-height 0.011s ease-in;
            `
        :
            css`
                max-height:0;
                overflow:hidden;
                transition: max-height: 0s ease-out;
            `
    }
`;

const ArrowWrapper = styled.span<{ $subMenuOpen: boolean }>`
    // padding-right: 10px;
    transition: 0.3s;
    // transform: ${(props) => props.$subMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)' };

    ${(props) => 
        props.$subMenuOpen ?
        css`
            transform: rotate(180deg);
        `
        :
        css`
            transform: rotate(0deg);
        `
    }
`;

export default Menu;