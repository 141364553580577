import React, { useState } from "react";
import { styled } from "styled-components";

interface Props {
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
}   

const FormButton = ({ text, onClick, disabled, className}: Props) => {
    const [click, setClick] = useState<boolean>(false);

    return (
        <SubmitInput value={text} type="submit" onClick={onClick} $clicked={click} disabled={disabled}  tabIndex={-1} className={`${className ?? ''}`}>
        </SubmitInput>
    )
}

const SubmitInput = styled.input<{$clicked: boolean}>`
    height: 42px;
    flex-shrink: 0;
    background-color: #1F62B9;
    color: #FFFFFF;
    border-radius: 4px;
    border: 0px;
    padding:0;
    margin-top: 10px;

    color: #FFF;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &:hover{
        background-color: ${props => props.$clicked ? '#32418E' : '#599AEE'};
    }
`;

export default FormButton;