import { useForm, UseFormProps } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18n from "../i18n";
import Cvc from "../components/Card/Cvc";
import Identification from "../components/Card/Identification";

const useCustomFormMethod = <T extends Record<string, any>>(
  schema: yup.AnyObjectSchema
) => {
  const formMethods = useForm<T>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  return formMethods;
};

export const useCustomForm = <T extends Record<string, any>>() => {
  const schema = yup.object({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        i18n.t("join.valid.email")
      ),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        i18n.t("join.valid.password")
      ),
    passwordConfirm: yup
      .string()
      .oneOf(
        [yup.ref("password"), undefined],
        i18n.t("join.valid.passwordConfirm")
      )
      .required(i18n.t("join.valid.passwordConfirmRequired")),
    name: yup
      .string()
      .required(i18n.t("common.valid.required"))
      .min(2, i18n.t("common.valid.requiredMin")),
    phoneNumber: yup
      .string()
      .matches(/^\d{10,11}$/, i18n.t("join.valid.phoneNumber")),
  });

  return useCustomFormMethod<T>(schema);
};

export const useWorkspaceForm = <T extends Record<string, any>>(
  freeLicense: boolean
) => {
  const schema = yup
    .object({
      workspaceName: yup
        .string()
        .min(2, i18n.t("common.valid.requiredMin"))
        .max(20, i18n.t("common.valid.nameMax")),
      tenantid: yup
        .string()
        .notOneOf(
          ["colla", "storage", "sp"],
          i18n.t("common.valid.specificPlatform")
        )
        .matches(/[a-z]/, i18n.t("common.valid.tenantValid"))
        .matches(/[0-9]/, i18n.t("common.valid.tenantValid"))
        .max(10, i18n.t("common.valid.maxLength")),
      personnelLimit: yup.lazy(() =>
        freeLicense
          ? yup.string().notRequired()
          : yup.number().typeError(i18n.t("common.valid.onlyNumber"))
      ),
    })
    .required();

  return useCustomFormMethod<T>(schema);
};

export const useAdminForm = <T extends Record<string, any>>() => {
  const schema = yup.object({
    adminId: yup
      .string()
      .matches(/^[a-z\d]{4,}$/, i18n.t("common.valid.admin.email")),
    adminPwd: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        i18n.t("join.valid.password")
      ),
    adminPwdConfirm: yup
      .string()
      .oneOf(
        [yup.ref("adminPwd"), undefined],
        i18n.t("common.valid.admin.passwordConfirm")
      ),
  });

  return useCustomFormMethod<T>(schema);
};

export const useLoginForm = <T extends Record<string, any>>() => {
  const schema = yup.object({
    username: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        i18n.t("login.valid.email")
      ),
    password: yup.string().required(i18n.t("login.valid.pwd")),
  });

  return useCustomFormMethod<T>(schema);
};

export const usePasswordChangeForm = <T extends Record<string, any>>() => {
  const schema = yup.object({
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        i18n.t("join.valid.password")
      ),
    passwordConfirm: yup
      .string()
      .oneOf(
        [yup.ref("password"), undefined],
        i18n.t("join.valid.passwordConfirm")
      )
      .required(i18n.t("join.valid.passwordConfirmRequired")),
  });

  return useCustomFormMethod<T>(schema);
};

export const useEmailSendForm = <T extends Record<string, any>>() => {
  const schema = yup.object({
    email: yup
      .string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        i18n.t("join.valid.email")
      )
      .required(),
  });

  return useCustomFormMethod<T>(schema);
};

/**
 * @deprecated 사용불가해서 useForm으로 재선언함
 */
export const useCardRegisterForm = <T extends Record<string, any>>() => {
  const schema = yup.object({
    cardNumber: yup.array().of(
      yup
        .string()
        .required("카드 번호 입력바랍니다.")
        .matches(/^\d{4}$/, "4자리 이상 입력 부탁드립니다.")
    ),
  });

  return useCustomFormMethod<T>(schema);
};

export const cardRegisterSchema = yup.object().shape({
  cardNumber: yup
    .array()
    .of(
      yup
        .string()
        .required("카드번호는 필수입니다.")
        .matches(/^\d{4}$/, "카드 번호 형식이 이상합니다.")
    )
    .min(4, "Must have 4 fields"),
  expireDate: yup.array().of(yup.string().required("유효기간은 필수입니다.")),
  cvc: yup.string().required("cvc 는 필수값입니다."),
  buyerName: yup.string().required("이름은 필수값입니다."),
  cardPw: yup.string().required("카드비밀번호는 필수값입니다."),
  identification: yup.string().required("해당 값은 필수값입니다."),
});
