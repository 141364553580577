import { useEffect, useState } from "react";
import { useCalculateAndFormatDates } from "./use.calculate.date";



const useCalucalateUsersCost = (usersToAdd: number, licensePrice: number, nextPaymentDate: string): [number, number, number] => {

    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [vatState, setVAT] = useState<number>(0);
    const [originalPrice, setOriginalPrice] = useState<number>(0);
  
    const { getCurrentDifferenceDays }  = useCalculateAndFormatDates();
   
    useEffect(() => {
        const calculateCost = (): void => {
            const daysUntilNextPayment = getCurrentDifferenceDays(nextPaymentDate);

            const daysInMonth = 30;

            let costWithoutVAT = 0;

            if(daysUntilNextPayment > 27) {
                costWithoutVAT = usersToAdd * licensePrice;
            } else {
                const dailyPricePerUser = licensePrice / daysInMonth;
                const totalDailyPrice = dailyPricePerUser * usersToAdd;
                costWithoutVAT = daysUntilNextPayment * totalDailyPrice;
            }
            let vat = costWithoutVAT * 0.1;
            vat = Math.ceil(vat / 10) * 10; // 10의 자리수 올림
            setVAT(vat);

            const tempPrice = costWithoutVAT + vat;

            setOriginalPrice(Math.ceil(costWithoutVAT/ 10) * 10);
            setTotalPrice(Math.ceil(tempPrice/ 10) * 10);
        }

        calculateCost();
    },  [usersToAdd, licensePrice, nextPaymentDate]);

    return [totalPrice, vatState, originalPrice];
}

export default useCalucalateUsersCost;
