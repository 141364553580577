import { Suspense } from "react";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";


interface Props {
    personnelLimit: string;
    users: number;
    loading: boolean;
}

const NumberOfMember = ({personnelLimit, users, loading}: Props) => {

    const { t } = useTranslation();

    if(loading){
        return <Loader/>
    }

    return (
        <span className="text-[#555555] text-[18px] font-noto-sans-kr sm:text-[15px]">
            {t('detail.memberFront')} <>&nbsp;</>
            {users ?
                <>
                    <strong>
                        {users}/{personnelLimit} {t('detail.people')}
                    </strong>
                </>
            :
                <>
                    <strong>
                        {t("detail.unableToUse")}
                    </strong>    
                </>
        }   
            <>&nbsp;</>
            {t('detail.memberBack')}
        </span>
    )
} 

export default NumberOfMember;
