import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { string } from "yup";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  height: string;
  width?: string;
  paddingX?: string;
}

const ModalLayout = ({
  isOpen,
  onClose,
  children,
  height,
  width,
  paddingX = "43px",
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleActive = (e: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(e.target as HTMLElement)
      ) {
        onClose();
      }
    };

    document.addEventListener("mouseup", handleActive);
    return () => {
      document.removeEventListener("mouseup", handleActive);
    };
  }, []);

  return (
    <Container $isOpen={isOpen}>
      <Content
        ref={modalRef}
        $height={height}
        $width={width ? width : "auto"}
        $paddingX={paddingX}
      >
        {children}
      </Content>
    </Container>
  );
};

const Container = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.$isOpen ? "flex" : "none")};
  align-items: center;
  justify-content: center;

  z-index: 10000;
`;

const Content = styled.div<{
  $height: string;
  $width: string;
  $paddingX: string;
}>`
  background: white;
  border-radius: 5px;
  z-index: 1000;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.25);

  display: inline-flex;
  padding: 32px ${(props) => props.$paddingX};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
`;

export default ModalLayout;
