import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentInfoType } from "../../@types";

type CardInfoListType = {
  selectedKey: string | null;
  selectedCardInfo: PaymentInfoType | null;
  data: PaymentInfoType[];
};

const initialState: CardInfoListType = {
  selectedKey: null,
  data: [],
  selectedCardInfo: null,
};

const cardInfoListSlice = createSlice({
  name: "cardInfoListSlice",
  initialState,
  reducers: {
    setCardInfoData(state, action: PayloadAction<PaymentInfoType[]>) {
      console.log(action.payload);
      state.selectedKey = action.payload[0].cardId;
      state.selectedCardInfo = action.payload[0];
      state.data = action.payload;
    },
    setSelectedKey(state, action: PayloadAction<string>) {
      state.selectedKey = action.payload;
      state.selectedCardInfo =
        state.data.find((cardInfo) => cardInfo.cardId === state.selectedKey) ||
        null;
    },
    setResetCardInfo(state) {
      return initialState;
    },
    setIsValid(state) {
      //선택된 카드정보 업데이트
      if (state.selectedCardInfo && state.selectedCardInfo.isValid) {
        state.selectedCardInfo.isValid = false;
      }
      //배열에서 선택된 값을 찾아 업데이트
      const selectedCard = state.data.find(
        (cardInfo) => cardInfo.cardId === state.selectedKey
      );

      if (selectedCard) {
        selectedCard.isValid = false;
      }
    },
  },
});

export const { setCardInfoData, setSelectedKey, setResetCardInfo, setIsValid } =
  cardInfoListSlice.actions;
export default cardInfoListSlice.reducer;
