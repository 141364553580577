
export const hashSHA256 = (input: string): Promise<string> => {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);

    const result = crypto.subtle.digest('SHA-256', data)
        .then((hashBuffer) => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

            return hashHex;
        });

    return result;

}


  