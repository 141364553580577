import i18n from "../../i18n";
import axiosInstance, {requestAuth} from "../request/axios.instance";
import { toastError } from "../toast";


export const handleAdminPageLocate = (tenantId: string, callback: () => void) => {
    
    axiosInstance.post('/workspace/admin-url',{
        tenantId
    },requestAuth({})).then((response) => {
        const authorization: string = response.headers['authorization'];
        const token: string[] = authorization.split(' ')[1].split('/');
        window.open(`https://admin.ucware.co.kr/?jwt=${token[0]}&refjwt=${token[1]}&lang=${i18n.language}&type=${response.data.data}`);
    }).catch((err) => {
        toastError('SERVER ERROR');
        callback();
    })

}

