import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";

export interface NumberOfUsersDTO {
    [key:string] : number;
}

interface NumberOfUSersState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    usersList: NumberOfUsersDTO[];
}

const initialState: NumberOfUSersState = {
    usersList: [],
    status: 'idle',
}

export const fetchNumberOfUsersPOST = createAsyncThunk(
    '/workspace/users-list',
    async () => {
        try{
            const response = await axiosInstance.post('/workspace/users-list',{}, requestAuth({}));
            return response.data;
        }catch(error) {
            return error;
        }
    }
)

const numberOfUSersSlice = createSlice({
    name: 'numberOfUsers',
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
            .addCase(fetchNumberOfUsersPOST.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchNumberOfUsersPOST.fulfilled, (state,action) => {
                state.status = 'succeeded';
                state.usersList = action.payload.data;
            })
            .addCase(fetchNumberOfUsersPOST.rejected, (state) => {
                state.status = 'failed';
            })
    }
})

export default numberOfUSersSlice.reducer;