import { lazy, Suspense, useEffect, useState } from "react";
import ModalLayout from "./Layout";
import Input from "../../ui/Input";
import Text from "../../ui/Text";
import { BillingAdditionalForm } from "../../@types";
import { ControllerRenderProps, SubmitHandler, useForm } from "react-hook-form";
import BillingAdditionalInput from "../BillingForm/BillingAdditionalInput";
import Button from "../../ui/Button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import { modalClose } from "../../redux/slice/billingAdditionalModalSlice";

const BillingFormLazy = lazy(() => import("../BillingForm"));

const BillingAdditionalModal = () => {
  const [billingFormOpen, setBillingFormOpen] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<BillingAdditionalForm>({
    mode: "onSubmit",
    defaultValues: {
      phoneNumber: "",
      email: "",
      name: "",
    },
  });

  const onSubmit: SubmitHandler<BillingAdditionalForm> = (data) => {
    console.log(data);
    //POST 요청 후 성공시

    setBillingFormOpen(true);
  };
  const handleModalClose = () => {
    dispatch(modalClose());
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <>
      {billingFormOpen && (
        <Suspense>
          <BillingFormLazy workspaceId={"45"} />
        </Suspense>
      )}
      <ModalLayout
        isOpen={true}
        onClose={handleModalClose}
        height="400px"
        width="400px"
      >
        <form
          className="w-full h-full flex flex-col gap-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Text variant="primary" className="text-center font-bold text-[20px]">
            결제 추가정보입력
          </Text>
          <BillingAdditionalInput
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            name="phoneNumber"
            renderInput={(
              field: ControllerRenderProps<
                BillingAdditionalForm,
                keyof BillingAdditionalForm
              >
            ) => (
              <div className="flex flex-col gap-2">
                <Text<"label"> as={"label"} className="text-[14px] font-medium">
                  전화번호
                </Text>
                <Input
                  {...field}
                  variant="primary"
                  placeholder="전화번호"
                  className="h-[42px]"
                />
              </div>
            )}
          />
          <BillingAdditionalInput
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            name="email"
            renderInput={(
              field: ControllerRenderProps<
                BillingAdditionalForm,
                keyof BillingAdditionalForm
              >
            ) => (
              <div className="flex flex-col gap-2">
                <Text<"label"> as={"label"} className="text-[14px] font-medium">
                  이메일
                </Text>
                <Input
                  {...field}
                  variant="primary"
                  placeholder="이메일"
                  className="h-[42px]"
                />
              </div>
            )}
          />
          <BillingAdditionalInput
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            name="name"
            renderInput={(
              field: ControllerRenderProps<
                BillingAdditionalForm,
                keyof BillingAdditionalForm
              >
            ) => (
              <div className="flex flex-col gap-2">
                <Text<"label"> as={"label"} className="text-[14px] font-medium">
                  이름
                </Text>
                <Input
                  {...field}
                  variant="primary"
                  placeholder="이름"
                  className="h-[42px]"
                />
              </div>
            )}
          />

          <div className="flex justify-center w-full mt-8">
            <Button type="submit" variant="success">
              결제등록
            </Button>
          </div>
        </form>
      </ModalLayout>
    </>
  );
};

export default BillingAdditionalModal;
