interface Props {
  btnClassName?: string;
  textClassName?: string;
  text: string;
  onClick: () => void;
}

const DetailButton = ({
  text,
  btnClassName,
  textClassName,
  onClick,
}: Props) => {
  return (
    <button
      className={`w-[140px] sm:w-[100px] sm:text-[12px] sm:px-[5px] h-[36px] rounded-[4px] border-[1px] py-[8px] font-noto-sans-kr text-[14px] font-bold pt-[5px] ${
        btnClassName ?? ""
      }`}
      onClick={onClick}
    >
      <span className={`${textClassName ?? ""} sm:text-[11px]`}>{text}</span>
    </button>
  );
};
export default DetailButton;
