import { useTransition } from "react";
import {CheckIcon, EducationBlueIcon} from "../../icons";
import { useTranslation } from "react-i18next";


const EducationAd = () => {

    return (
            <div className="flex flex-row items-center w-[948px] min-h-[140px] rounded-[14px] gap-[38px] bg-[#F2F8FF] border-[1px] border-[#1F62B9] border-solid px-[88px] md:px-[20px] md:w-[90%] md:flex-col md:gap-2 animate-fromTopToBottom">
                <div className="flex flex-col items-center justify-center w-[270px] h-full gap-3">
                    <p className="flex flex-row gap-1 items-center">
                        <EducationBlueIcon/>
                        <span className="text-[#1F62B9] font-extrabold font-noto-sans-kr text-[22px]">教育用です</span>
                    </p>

                    <div
                        className="font-noto-sans-kr text-[15px] tracking-normal font-semibold leading-[22px] text-center w-[200px]">
                        無料·スタンダード·エンタープライズプランに
                        <div className="text-[#FF5038]">教育用機能が基本付属！</div>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center w-[220px] h-full gap-3 md:mt-[20px]">
                    <div className="flex flex-col w-full justify-start gap-1 md:items-center">
                        <p className="font-noto-sans-kr text-[14px] font-semibold tracking-normal leading-[20px]">
                            教育用共通提供機能
                        </p>
                        <div className="flex items-center gap-1">
                            <CheckIcon/>
                            <span>申告</span>
                        </div>
                        <div className="flex items-center gap-1">
                            <CheckIcon/>
                            <span>授業報告書</span>
                        </div>
                        <div className="flex items-center gap-1">
                            <CheckIcon/>
                            <span>相談予約</span>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-1 w-[220px] md:items-start md:mb-[20px]">
                    <div className="flex items-center gap-1">
                        <CheckIcon/>
                        <span>現位置通知</span>
                    </div>
                    <div className="flex items-center gap-1">
                        <CheckIcon/>
                        <span>登下校管理</span>
                    </div>
                </div>
            </div>
    )
}

export default EducationAd;
