import clsx from "clsx";
import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  ElementType,
  ReactNode,
  useEffect,
  useState,
} from "react";

type ButtonVariants =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "none"
  | "blue"
  | "disable";
type ButtonType = "button" | "submit";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  disabled?: boolean;
  type?: ButtonType;
}

const Button = ({
  children,
  type = "button",
  className,
  disabled,
  onClick,
  variant = "none",
  ...props
}: ButtonProps) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const handleMouseDown = () => {
    setClicked(true);
  };
  const handleMouseUp = () => setClicked(false);
  const handleMouseLeave = () => setClicked(false);
  const variantStyles: {
    [key in ButtonVariants]: {
      default: string;
      clicked: string;
      hover: string;
    };
  } = {
    primary: {
      default:
        "sm:text-[12px] h-[36px] rounded-[4px] border-[1px] border-[#1F62B9] text-[14px]",
      clicked: "hover:bg-[#B8D3FE]",
      hover: "hover:bg-[#D9E5F9]",
    },
    blue: {
      default:
        "rounded-[4px] border-[1px] bg-[#FFFFFF] border-[#1F62B9] text-[#1F62B9] text-[14px] px-[37px] py-[8px] h-[36px] text-center sm:px-[16px]",
      clicked: "hover:bg-[#B8D3FE]",
      hover: "hover:bg-[#D9E5F9]",
    },
    secondary: {
      default:
        "rounded-[4px] border-[1px] bg-[#FFFFFF] border-[#555555] text-[#555555] text-[14px] px-[37px] py-[8px] sm:px-[16px]",
      clicked: "hover:bg-[#B8D3FE]",
      hover: "hover:bg-[#D9E5F9]",
    },
    success: {
      default:
        "rounded-[4px] border-[1px] bg-[#1F62B9] border-[#1F62B9] text-[#FFFFFF] text-[14px] px-[37px] py-[8px] sm:px-[16px]",
      clicked: "hover:bg-[#32418E]",
      hover: "hover:bg-[#599AEE]",
    },
    danger: {
      default: "",
      clicked: "",
      hover: "",
    },
    none: {
      default: "",
      clicked: "",
      hover: "",
    },
    disable: {
      default: "bg-[#F2F8FF] text-[#FFF]  px-[37px] py-[8px] rounded-[4px]",
      clicked: "",
      hover: "",
    },
  };
  return (
    <button
      type={type}
      className={clsx(
        variantStyles[variant].default,
        clicked ? variantStyles[variant].clicked : variantStyles[variant].hover,
        className,
        "font-noto-sans-kr font-bold"
      )}
      disabled={disabled}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
