type LICENSEINFO = {
  currencySymbol: string;
  price: number;
  storage: string;
  fileLimit: string;
};

type LICENSETYPE = {
  KO: LICENSEINFO;
  JP: LICENSEINFO;
};

const JAPAN_CURRENCY_SYMBOL = "￥";
const KOREAN_CURRENCY_SYMBOL = "원";

const FREE: LICENSETYPE = {
  KO: {
    currencySymbol: KOREAN_CURRENCY_SYMBOL,
    price: 0,
    storage: "5GB",
    fileLimit: "10MB",
  },
  JP: {
    currencySymbol: JAPAN_CURRENCY_SYMBOL,
    price: 0,
    storage: "5GB",
    fileLimit: "10MB",
  },
};

const STANDARD: LICENSETYPE = {
  KO: {
    currencySymbol: KOREAN_CURRENCY_SYMBOL,
    price: 5000,
    storage: "100GB",
    fileLimit: "500MB",
  },
  JP: {
    currencySymbol: JAPAN_CURRENCY_SYMBOL,
    price: 600,
    storage: "100GB",
    fileLimit: "500MB",
  },
};

const ENTERPRISE: LICENSETYPE = {
  KO: {
    currencySymbol: KOREAN_CURRENCY_SYMBOL,
    price: 9000,
    storage: "1TB",
    fileLimit: "Unlimited",
  },
  JP: {
    currencySymbol: JAPAN_CURRENCY_SYMBOL,
    price: 600,
    storage: "1TB",
    fileLimit: "Unlimited",
  },
};

export { FREE, STANDARD, ENTERPRISE };
