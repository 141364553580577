import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import crypto from "crypto";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import { create } from "domain";
import { number } from "yup";
import { AxiosError } from "axios";
import { toastError } from "../../utils/toast";
import i18n from "../../i18n";

interface WorkspaceState {
  workspaceList: Array<WorkspaceDTO>;
  status: "idle" | "loading" | "succeded" | "failed";
  error: string | null;
}

const initialState: WorkspaceState = {
  workspaceList: [],
  status: "idle",
  error: null,
};

interface ApiResponse {
  status: number;
  data: Array<WorkspaceDTO>;
}

export const fetchWorkspaceGET = createAsyncThunk(
  "/workspace/read",
  async () => {
    try {
      const response = await axiosInstance.get(
        "/workspace/list",
        requestAuth({})
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 403) {
          // toastError(i18n.t('common.sessionExpired'));
          localStorage.removeItem("uc-portal-token");
        }
        return error.response?.status;
      }
      return -1;
    }
  }
);

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWorkspaceGET.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWorkspaceGET.fulfilled, (state, action) => {
        state.status = "succeded";
        if (action.payload) {
          if (
            typeof action.payload.data === "number" ||
            action.payload === 403 ||
            action.payload === 401
          ) {
            state.workspaceList = [];
          } else {
            state.workspaceList = action.payload.data;
          }
        }
      })
      .addCase(fetchWorkspaceGET.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default workspaceSlice.reducer;
