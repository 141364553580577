import React from "react";
import { Hourglass } from "../../icons";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";

const WatingButton = () => {

    const { t } = useTranslation();

    return (
        <Container>
            <Hourglass/>
            <Text>
                {t('common.waiting')}
            </Text>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right:16px;
    gap: 0.2em;
	
    svg {
        animation: rotate 2s linear infinite;

        @keyframes rotate {
            from {
                transform: rotate(0deg); 
            }
            to {
                transform: rotate(360deg); 
            }
        }
    }
`;

const Text = styled.span`
    color: #1F62B9;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-left: 2.5px;

   @media screen and (max-width: 1166px) {
     font-size: 12px;
   }
`;
export default WatingButton;
