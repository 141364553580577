import React from "react";
import styled from "styled-components";


interface Props{
    onClick: (dto: PriceLicense) => void;
    text: string;
    color: string;
    licenseData: PriceLicense;
}

const CardButton = ({ onClick, text, color, licenseData}: Props) => {

    const handleLicenseSelect = () => {
        onClick(licenseData);
    }

    return (
        <span onClick={handleLicenseSelect}>
            <Container $color={color}>
                {text}
            </Container>
        </span>
    )
}

const Container = styled.button<{$color: string}>`
    width: 268px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 4px;
    background: ${props => props.$color};
    border: 0;
    color: white;
    color: #FFF;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 330px) {
        width: 248px;
    }
`;

export default CardButton;
