import React, { useEffect } from "react";
import styled from "styled-components";
import LoginForm from "../../components/Form/LoginForm";
import { LoginImage } from "../../icons";
import MainLayout from "../../components/Layout/MainLayout";
import Loadable from "../../components/Lodable";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import Loader from "../../components/Loader";
import axiosInstance from "../../utils/request/axios.instance";

const LoginPage = () => {
    const memberInfo = useSelector((state: RootState) => state.memberLogin);
    useEffect(() => {
        axiosInstance.get('/member/warm');
    }, []);

    return (
        <MainLayout imgSrc={"/images/login-image.png"}>
            <LoginForm/>
        </MainLayout>
    )
}

const DivTest = styled.div`
    background: linear-gradient(163deg, #E4F7FD 10.53%, #FAF5FF 93.56%);
    width: 100%;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content:center;
    padding-top: 30px;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const ImgaeWrapper = styled.div`
    float: right;
    height: 100%;
    position: absolute;
    right: 0;

    width: 30%;
    overflow: hidden;    

    @media (max-width: 950px) {
        display: none;
    }
`;

const LoginWrapper = styled.div`
    display: flex;
    width: 70%;
    justify-content: center;
    align-items: center;

    @media (max-width: 950px) {
       width:100%;
    }
`;

export default LoginPage;
