import clsx from "clsx";
import {
  Children,
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ElementType,
} from "react";

type TextVariants = "primary" | "secondary" | "success" | "danger" | "none";

type VariantStyles = {
  [key in TextVariants]: {
    default: string;
  };
};
export type TextProps<T extends ElementType> = {
  as?: T;
  children: React.ReactNode;
  variant?: TextVariants;
};

const Text = <T extends ElementType>({
  as,
  children,
  className,
  variant = "none",
  ...props
}: TextProps<T> & ComponentPropsWithoutRef<T>) => {
  const Typograph = as || "div";

  const variantStyles: VariantStyles = {
    primary: {
      default: "",
    },
    secondary: {
      default: "",
    },
    success: {
      default: "",
    },
    danger: {
      default: "text-[red] text-[13px] animate-fadeIn",
    },
    none: {
      default: "",
    },
  };
  return (
    <Typograph
      className={clsx(
        "font-noto-sans-kr leading-[20.27px]",
        className,
        variantStyles[variant].default
      )}
      {...props}
    >
      {children}
    </Typograph>
  );
};

export default Text;
