import {BottomArrow} from "../../icons";
import React from "react";
import styled from "styled-components";


interface Props {
    isExpanded: boolean;
    text: string;
    handleSpredClick: () => void;
}

const SpredComponent = ({ isExpanded, handleSpredClick, text }: Props) => {
    return (
        <SpreadWrapper onClick={handleSpredClick}>
            <Spred>
                {text}
                <Arrow $rotated={isExpanded}>
                    <BottomArrow/>
                </Arrow>
            </Spred>
        </SpreadWrapper>
    )
}
const Arrow = styled.span<{ $rotated: boolean }>`
    transform: ${props => props.$rotated ? 'rotate(180deg)' : 'rotate(0)'};
    transition: transform 0.3s ease-in-out;
`;
const SpreadWrapper = styled.span`
    display: none;
    height: 100%;
    width: 100%;
    
    @media (max-width: 802px) {
        display: block;
    }
`;

const Spred = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: #1F62B9;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export default SpredComponent;