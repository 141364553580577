import { useNavigate } from "react-router-dom";
import ModalLayout from "./Layout";
import { useTranslation } from "react-i18next";



/**
 * @param contentIndex 0: 존재하는 이메일, 1: 존재하지 않는 이메일 
 * @returns 
 */
const PwdMailModal = ({ isOpen, onClose, contentIndex, className }: ModalProps & { contentIndex: number, className: string }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const contentArr = [
        t('pwd.emailSend'),
        t('pwd.emailNotFound')
    ]
    
    return (
        <ModalLayout isOpen={isOpen} onClose={onClose} height={contentIndex === 1 ? '155px' : '112px'}>
            <div className={`flex flex-col items-center gap-[18px] ${className ?? ''}`}>
                {contentArr[contentIndex]}

                {contentIndex === 1 && 
                    <button className="w-[320px] h-[42px] bg-[#1F62B9] rounded-[4px] text-[#FFF] sm:w-[240px] sm:text-[10px]" onClick={() => { navigate('/join') }}>
                        {t('common.join')}
                    </button>
                }
            </div>
        </ModalLayout>
    )
}

export default PwdMailModal;