import { useTranslation } from "react-i18next";
import { WarningIcon } from "../../icons"


const FailButton = () => {
    const { t } = useTranslation();

    return (
        <div className="flex gap-1 justify-center items-center">
            <WarningIcon/> 
            <span className="font-noto-sans-kr text-[14px] leading-[20.27px] text-[#E23A3A] font-semibold">{t('common.fail')}</span>
        </div>
    )
}

export default FailButton;
