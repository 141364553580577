import { useEffect, useState } from "react";
import { number } from "yup";
import { useCalculateAndFormatDates } from "./use.calculate.date";

const useCalculateStorageCost = (
  storageSizeToAdd: number,
  nextPaymentDate: string
): [number, number] => {
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [vatState, setVAT] = useState<number>(0);
  const { getCurrentDifferenceDays } = useCalculateAndFormatDates();

  useEffect(() => {
    const calculateCost = (): void => {
      const daysUntilNextPayment = getCurrentDifferenceDays(nextPaymentDate);
      //TODO: - 값 대응 필요 -0 -1 이런식으로 나올 수 있음
      const pricePerTBPerMonth = 100000;
      const daysInMonth = 30;
      console.log(nextPaymentDate);
      console.log(daysUntilNextPayment);
      let costWithoutVAT = 0;

      if (daysUntilNextPayment > 27) {
        costWithoutVAT = storageSizeToAdd * pricePerTBPerMonth;
      } else {
        const dailyPricePerTB = pricePerTBPerMonth / daysInMonth;
        const totalDailyPrice = dailyPricePerTB * storageSizeToAdd;
        costWithoutVAT = daysUntilNextPayment * totalDailyPrice;
      }

      let vat = costWithoutVAT * 0.1;
      vat = Math.ceil(vat / 10) * 10; // 10의 자리수 올림
      setVAT(vat);

      const tempPrice = costWithoutVAT + vat;

      setTotalPrice(Math.ceil(tempPrice / 10) * 10);
    };

    calculateCost();
  }, [storageSizeToAdd, nextPaymentDate]);

  return [totalPrice, vatState];
};

export default useCalculateStorageCost;
