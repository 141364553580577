import React, { RefObject, useRef, useState, FocusEvent } from "react";
import styled from "styled-components";
import "./input.css";
import { useForm } from "react-hook-form";
import { error } from "console";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  id?: string;
  iconVisible?: boolean;
  iconText?: string;
  onChange?: () => void;
  tabIndex?: number;
  tooltipText?: string;
  tooltipClassName?: string;
  width?: string;
  value?: string;
  readOnly?: boolean;
  keyDownEvent?: (event: any) => void;

  //yup
  register?: ReturnType<typeof useForm<any>>["register"];
  errors?: { [key: string]: any };
  name: string;
}

const InputCustom = ({
  text,
  id,
  iconVisible,
  register,
  errors,
  name,
  keyDownEvent,
  tooltipText,
  tooltipClassName,
  width,
  value,
  readOnly = false,
}: Props) => {
  const { t } = useTranslation();

  const [showHelper, setShowHelper] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const errorExist = errors![name];
  const inputType: boolean =
    name === "password" ||
    name === "adminPwd" ||
    name === "adminPwdConfirm" ||
    name === "passwordConfirm";

  const registerProps = {
    ...(register ? register(name) : {}),
  };
  const border = `${
    focus
      ? errors && name && errors[name]
        ? "1px solid red"
        : "0px"
      : errors && name && errors[name]
      ? "1px solid red"
      : "1px solid"
  }`;

  const handleMouseEnter = () => {
    setShowHelper(true);
  };
  const handleMouseLeave = () => {
    setShowHelper(false);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    if (readOnly) e.target.blur();
  };
  const handleBlur = () => {
    setFocus(false);
  };

  return (
    <div className="form-container" id={id}>
      <div className="google-input">
        <input
          type={inputType ? "password" : "text"}
          autoComplete="true"
          className="template-input"
          placeholder=""
          {...registerProps}
          style={{
            borderColor: errorExist ? "#D20707" : "#C8CACE",
            border: border,
            width: width,
          }}
          onKeyDown={keyDownEvent !== undefined ? keyDownEvent : (e: any) => {}}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...(readOnly ? { value: value || "" } : {})}
          readOnly={readOnly}
        />
        {iconVisible && (
          <>
            <span
              className="tooltip-icon"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {showHelper && (
                <p
                  className={`ml-[17px] bottom-[2px] top-[-6px] absolute bg-black rounded text-[#FFFFFF] text-[13px] flex items-center p-2 z-[100] ${
                    tooltipClassName ?? ""
                  }`}
                >
                  {tooltipText}
                </p>
              )}
            </span>
          </>
        )}
        <label
          htmlFor="template-input"
          style={{ color: errorExist ? "#D20707" : "" }}
        >
          {text}
        </label>
        {errors && name && errors[name] && (
          <p className="absolute text-[#D20707] font-noto-sans-kr text-[0.6rem] font-extrabold mt-1 z-0">
            {errors[name].message}
          </p>
        )}
      </div>
    </div>
  );
};

export default InputCustom;
