import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import axiosInstance, { requestAuth } from "../utils/request/axios.instance";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux";
import { fetchWorkspaceGET } from "../redux/slice/workspaceSlice";


const usePolling = (interval: number, tenantId: string, failCount: () => void) => {

    const [isPolling, setIsPolling] = useState(false);
    const [data, setData] = useState<number>(0);
    const pollingRef = useRef<NodeJS.Timer | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    
    const fetchData =  useCallback(async () => {
        try{
            const response = await axios.post(`https://${tenantId}.ucworks-dev.net/api/v1/crs-id-auth/hello`, {
                "cmd": "1001",
                "nonce": "-=[]{};':,./<>?123456789"
            });
            if(response.data.code === 200) {
                stop();
                setData(response.data.code);
                axiosInstance.put('workspace/tenant/del', {
                    tenantId
                },requestAuth({}));

                dispatch(fetchWorkspaceGET());
               
            }else{
                setIsPolling(false);
            }
            
        }catch(error){
            failCount();
        }
    },[]);

    const start = () => {
        if(!isPolling){
            setIsPolling(true);
            pollingRef.current = setInterval(fetchData, interval);
        }
    }

    const stop = () => {
        if (pollingRef.current) {
          setIsPolling(false);
          clearInterval(pollingRef.current);
          pollingRef.current = null;
        }
    };

    useEffect(() => {
        return () => {
          if (pollingRef.current) {
            clearInterval(pollingRef.current);
          }
        };
    }, []);
    
    return { start, stop, data };
}

export default usePolling;