import { useCallback } from "react";
import { secrets } from "./secret";
import CryptoJS from "crypto-js";

type DataObj = { [key: string]: string };

const encryptionKey = secrets.key;

export const useEncrypt = () => {
  const toEncrypt = useCallback((data: DataObj) => {
    let encryptedData: DataObj = {};

    for (const key in data) {
      const cipherText = CryptoJS.AES.encrypt(data[key], encryptionKey);
      encryptedData[key] = cipherText.toString();
    }

    return encryptedData;
  }, []);

  return { toEncrypt };
};
