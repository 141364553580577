import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WorkspaceDeleteModal from "../../components/Modal/WorkspaceDeleteModal";
import styled from "styled-components";
import CardButton from "../../components/Button/CardButton";
import ProductCard from "../../components/Button/ProductCard";
import ManageButton from "../../components/Button/ManageBtn";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { fetchWorkspaceDetailGET } from "../../redux/slice/workspaceDetailSlice";
import Loader from "../../components/Loader";
import { toastError, toastSuccess } from "../../utils/toast";
import { handleAdminPageLocate } from "../../utils/common/admin.page";
import { useTranslation } from "react-i18next";
import DetailButton from "../../components/Button/DetailButton";
import Text from "../../ui/Text";

import DetailSubTitle from "../../components/Text/DetailSubTitle";
import PaymentHistory from "../../components/Payment/PaymentHistory";
import WorkspaceNameModal from "../../components/Modal/WorkspaceNameModal";
import { useCalculateAndFormatDates } from "../../hooks/use.calculate.date";
import NumberOfMember from "../../components/Detail/NumberOfMember";
import RemainingDays from "../../components/Detail/RemainingDays";
import RemainingCapacity from "../../components/Detail/RemainingCapacity";
import CreditCardInfo from "../../components/Detail/CreditCardInfo";
import StorageAndPersonnel from "../../components/Detail/StorageAndPersonnel";
import ModifyPaymentEmail from "../../components/Modal/ModifyPaymentEmail";
import { useMediaQuery } from "react-responsive";
import axiosInstance from "../../utils/request/axios.instance";
import LicenseDetailCard from "../../components/Detail/LicenseCard";
import { formatDateToYearMonth } from "../../utils/common/dateFormat";
import PaymentInfo from "../../components/Detail/PaymentInfo";
import FullScreenLodable from "../../components/Lodable/FullScreenLoadble";

// 결제 내역 임시데이터
const tableData = [
  { date: "2024-03-01", transaction: "스탠다드 월급제", amount: "280,000 KRW" },
  { date: "2024-03-01", transaction: "스탠다드 월급제", amount: "280,000 KRW" },
  { date: "2024-03-01", transaction: "스탠다드 월급제", amount: "280,000 KRW" },
  { date: "2024-03-01", transaction: "스탠다드 월급제", amount: "280,000 KRW" },
];

const WorkspaceDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams(); // /workspace/detail/4
  const [modalOpen, setModalOpen] = useState(false);
  const [isNameModalOpen, setIsNameModalOpen] = useState<boolean>(false);
  const [isModifyEmailOpen, setIsModifyEmailOpen] = useState<boolean>(false);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const isScreenSM = useMediaQuery({ maxWidth: "640px" });
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const workspaceDetail = useSelector(
    (state: RootState) => state.workspaceDetail.workspaceInfo
  );
  const loading = useSelector(
    (state: RootState) => state.workspaceDetail.status
  );

  const error = useSelector((state: RootState) => state.workspaceDetail.error);
  const handleNameModalIsOpen = (isOpen: boolean) => {
    setIsNameModalOpen(isOpen);
  };
  const handleModifyEmailModalIsOpen = (isOpen: boolean) => {
    setIsModifyEmailOpen(isOpen);
  };

  const handleDeleteClick = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchWorkspaceDetailGET(id))
        .then((data) => {
          if (!data.payload) {
            navigate("/");
          }

          if (
            !data.payload.data.creditCardNumber &&
            data.payload.data.licenseType !== "Free"
          ) {
            toastError(t("detail.paymentRequired"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (error) {
      toastError(t("common.dataNotFound"));
      navigate("/");
    }
    return () => {};
  }, [error]);

  return (
    <>
      {loading !== "succeded" ? (
        <div className="flex justify-center items-cente w-full h-lvh">
          <Loader />
        </div>
      ) : (
        <>
          {requestLoading && <FullScreenLodable visible={true} />}
          <WorkspaceDeleteModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            setRequestLoading={(state) => {
              setRequestLoading(state);
            }}
          />
          <Container>
            {isNameModalOpen && (
              <WorkspaceNameModal
                handleModal={handleNameModalIsOpen}
                tenantId={workspaceDetail?.tenantId!}
                id={id!}
              />
            )}
            {isModifyEmailOpen && (
              <ModifyPaymentEmail
                handleModal={handleModifyEmailModalIsOpen}
                tenantId={workspaceDetail?.tenantId!}
                id={id!}
              />
            )}
            <article className="space-y-6 w-[800px] md:w-[80%]">
              <section className="flex justify-between items-center h-fit">
                <div className="w-[574px] min-h-[52px] h-fit font-noto-sans-kr font-bold leading-[52.13px] text-[32px]">
                  {workspaceDetail?.workspaceName}
                </div>
                <DetailButton
                  btnClassName="border-[#1F62B9] hover:bg-[#D9E5F9] sm:h-[50px]"
                  text={t("detail.nameModify")}
                  textClassName="text-[#1F62B9]"
                  onClick={() => {
                    handleNameModalIsOpen(true);
                  }}
                />
              </section>

              <section className="w-full space-y-2">
                <DetailSubTitle text={t("detail.code")} />
                <div className="border-[#DFDFDF] border-[1px] rounded-[14px] h-[74px] flex items-center pl-5">
                  <span>{workspaceDetail?.tenantId}</span>
                </div>
              </section>

              <section className="space-y-2">
                <DetailSubTitle text={t("detail.plan")} />
                <div className="border-[#DFDFDF] border-[1px] rounded-[14px] h-[106px] flex px-5 items-center justify-between">
                  <div className="flex items-center space-x-20 sm:space-x-10">
                    <div className="flex flex-col gap-3">
                      <span className="font-noto-sans-kr text-[14px] leading-[20.27px]">
                        {t("detail.currentPlan")}
                      </span>
                      <LicenseDetailCard
                        licenseType={workspaceDetail?.licenseType!}
                        educational={Boolean(workspaceDetail?.educationFlag)}
                      />
                      {/* <span className="text-[18px] text-[#1F62B9] font-semibold font-noto-sans-kr leading-[26.06px]">{workspaceDetail?.licenseType}</span> */}
                    </div>

                    <div className="border-[1px] h-[58px] color-[#DFDFDF] sm:hidden"></div>

                    <div className="flex flex-col gap-3 sm:hidden">
                      <span>{t("detail.remainingDays")}</span>

                      <RemainingDays
                        lastPaymentDate={workspaceDetail?.lastPaymentDate!}
                        nextPaymentDate={workspaceDetail?.nextPaymentDate!}
                        licenseType={workspaceDetail?.licenseType!}
                      />
                    </div>
                  </div>

                  {
                    //TODO: 전시회떄까지는 못쓰는 기능
                    // workspaceDetail?.licenseType !== "Enterprise" && (
                    //     <DetailButton btnClassName="border-[#1F62B9] bg-[#1F62B9] w-[160px]" text={t("detail.upgrade")} textClassName="text-[#FFF]" onClick={() => {
                    //         toastSuccess('This feature is not supported yet.');
                    //     }}/>
                    // )
                  }
                </div>
              </section>

              <StorageAndPersonnel
                tenantId={workspaceDetail?.tenantId!}
                isScreenSM={isScreenSM}
              />

              {workspaceDetail?.licenseType !== "Free" && (
                <>
                  {/* 결제예정 */}
                  <PaymentInfo workspaceId={Number(id)} />
                  {/* 결제정보 */}
                  <section className="w-full space-y-2">
                    <DetailSubTitle text={t("detail.paymentInfo")} />
                    <div className="border-[#DFDFDF] border-[1px] rounded-[14px] h-[176px] flex flex-col gap-6 pt-5 px-5 items-center">
                      <div className="h-[44px] w-full flex justify-between items-center">
                        <CreditCardInfo
                          workspaceId={id}
                          creditCardNumber={workspaceDetail?.creditCardNumber!}
                        />
                      </div>

                      <div className="border-[1px] h-[1px] w-[752px] color-[#DFDFDF] md:w-[90%]"></div>

                      <div className="h-[36px] w-full flex justify-between items-center">
                        <div className="leading-[20.27px] text-[14px] text-[#555555] sm:text-[11px]">
                          {workspaceDetail?.buyerEmail ? (
                            <>
                              <strong>{workspaceDetail?.buyerEmail}</strong>{" "}
                              {t("detail.emailBack")}
                            </>
                          ) : (
                            <strong>{t("detail.noRegisteredEmail")}</strong>
                          )}
                        </div>

                        <DetailButton
                          btnClassName="border-[#1F62B9] hover:bg-[#D9E5F9]"
                          text={t("detail.emailModify")}
                          textClassName="text-[#1F62B9]"
                          onClick={() => {
                            if (!workspaceDetail?.buyerEmail) {
                              toastError(t("detail.paymentRequired"));
                            } else {
                              handleModifyEmailModalIsOpen(true);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </section>

                  <section className="space-y-2">
                    <DetailSubTitle text={t("detail.paymentDetails")} />
                    {/* <PaymentHistory tenantId={workspaceDetail?.tenantId!} /> */}
                  </section>
                </>
              )}

              <section className="w-full space-y-2">
                <DetailSubTitle text={t("workspace.deleteBtn")} />
                <div className="border-[#DFDFDF] border-[1px] rounded-[14px] h-[88px] flex items-center px-5 justify-between">
                  <span className="font-noto-sans-kr font-normal text-[14px] leading-[20.27px] sm:text-[11px]">
                    {t("detail.deleteCheck")} <br />
                    {t("detail.deleteText")}
                  </span>

                  <DetailButton
                    btnClassName="border-[#D20707]"
                    text={t("workspace.del.next")}
                    textClassName="text-[#D20707]"
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  />
                </div>
              </section>
            </article>
          </Container>
        </>
      )}
    </>
  );
};

interface Props {
  text: string;
}
const SubTitle = ({ text }: Props) => {
  return (
    <div className="w-[274px] h-[52px] font-noto-sans-kr font-bold leading-[52.13px] text-[32px]">
      {text}
    </div>
  );
};

const Container = styled.section`
  padding-top: 80px;
  width: 100%;
  height: 100%;
  margin-bottom: 80px;
  display: flex;
  position: relative;
  justify-content: center;
`;

export default WorkspaceDetail;
