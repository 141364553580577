import React, { lazy, Suspense, useState } from "react";
import { styled } from "styled-components";
import FormButton from "../Button/FormButton";
import InputCustom from "./Input";
import { useAdminForm } from "../../utils/yupCustom";
import { handleTabOnLastInput } from "../../utils/common/com.fun";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import { modalOpen } from "../../redux/slice/billingAdditionalModalSlice";

const BillingAdditionalModalLazy = lazy(
  () => import("../Modal/BilllingAdditionalModal")
);

interface Props {
  buttonText: string;
  onClick: (dto: AdminDTO) => void;
}

const AdminAccountForm = ({ buttonText, onClick }: Props) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useAdminForm<AdminDTO>();
  const onSubmitFun = (data: AdminDTO) => {
    // dispatch(modalOpen());
    onClick(data);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmitFun)}>
        <InputCustom
          text={t("workspace.form.admin.id")}
          name="adminId"
          register={register}
          errors={errors}
          tabIndex={-1}
        />
        <InputCustom
          text={t("workspace.form.admin.pwd")}
          name="adminPwd"
          register={register}
          errors={errors}
          tabIndex={-1}
        />
        <InputCustom
          text={t("workspace.form.admin.pwdConfirm")}
          name="adminPwdConfirm"
          register={register}
          errors={errors}
          tabIndex={-1}
          keyDownEvent={handleTabOnLastInput}
        />

        <FormButton
          text={buttonText}
          className="rlg:w-[395px] rmd:w-[337px] rsm:w-[297px]"
        ></FormButton>
      </Form>
    </>
  );
};

const Form = styled.form`
  position: absolute;
  top: 15px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 400px;

  div {
    width: 100%;
  }

  div > div > input {
    height: 42px;
    width: 99%;
  }
  @media screen and (max-width: 590px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 340px) {
    padding: 0 20px;
  }

  #country-selected-box {
    width: 99%;
    height: 42px;
  }
`;

export default AdminAccountForm;
