import clsx from "clsx";
import React, { ElementType, ComponentPropsWithRef, forwardRef } from "react";

type InputVariants = "primary" | "secondary" | "readonly" | "danger" | "none";

type VariantStyles = {
  [key in InputVariants]: {
    default: string;
  };
};

export type InputProps<T extends ElementType> = {
  as?: T;
  variant?: InputVariants;
};

const Input = forwardRef(
  <T extends ElementType = "input">(
    {
      as,
      className,
      variant = "none",
      ...props
    }: InputProps<T> & ComponentPropsWithRef<T>,
    ref: React.Ref<any>
  ) => {
    const variantStyles: any = {
      primary: {
        default: "",
      },
      secondary: {
        default: "",
      },
      readonly: {
        default:
          "w-[396px] h-[42px] border-1 border-solid rounded-[4px] bg-[#F1F3F6] border-[#C8CACE] text-[#9D9EA2] text-[14px] font-noto-sans-kr leading-[20.27px] font-semibold",
      },
      danger: {
        default: "",
      },
      none: {
        default: "",
      },
    };

    return (
      <input
        ref={ref}
        className={clsx(
          "border-[1px] rounded-[4px] border-solid border-[#C8CACE] p-[12px]",
          className,
          variantStyles[variant].default
        )}
        readOnly={variant === "readonly"}
        {...props}
      />
    );
  }
);

Input.displayName = "Input";

export default Input;
