import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import Input from "../../ui/Input";
import Text from "../../ui/Text";
import { CardNumberForm, ReactHookFormProps } from "../../@types";
import clsx from "clsx";

const CardNumberInput = ({
  control,
  errors,
  setValue,
  getValues,
  trigger,
}: ReactHookFormProps<CardNumberForm>) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const handleChange = (index: number, value: string) => {
    if (/^\d{0,4}$/.test(value)) {
      const newCardNumber = [...getValues("cardNumber")!];
      newCardNumber[index] = value;
      setValue("cardNumber", newCardNumber);

      if (value.length === 4 && index < 3) {
        inputRefs.current[index + 1]?.focus();
      }

      const allValid = inputRefs.current.every((el) => el?.value.length! > 3);
      if (allValid) {
        trigger("cardNumber");
      }
    }
  };
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (
      e.key === "Backspace" &&
      getValues(`cardNumber.${index}`) === "" &&
      index > 0
    ) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    const errorObj = errors.cardNumber?.find(
      (error: any) => error && error.message
    );
    if (errorObj) {
      setErrorMessage((errorObj as { message: string }).message);
    } else {
      setErrorMessage(null);
    }
  }, [errors.cardNumber]);

  return (
    <div className="flex flex-col gap-1 w-[400px] relative">
      <div className="flex flex-row gap-1 w-[374px] md:w-[300px]">
        {["cardNumber.0", "cardNumber.1", "cardNumber.2", "cardNumber.3"].map(
          (name, index) => (
            <Controller
              key={name}
              name={name}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  ref={(el) => {
                    field.ref(el);
                    inputRefs.current[index] = el;
                  }}
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange(index, e.target.value)
                  }
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    handleKeyDown(e, index)
                  }
                  maxLength={4}
                  className="h-[42px] flex-grow basis-0 min-w-0"
                ></Input>
              )}
            ></Controller>
          )
        )}
      </div>
      {errorMessage && (
        <Text variant="danger" className="-top-5 right-8 absolute">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default CardNumberInput;
