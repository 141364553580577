import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Country {
  value: string;
  label: string;
}

interface Props {
  setStateProps?: (country: string) => void;
}

const SelectBoxComponent = ({ setStateProps }: Props) => {
  const { t } = useTranslation();

  const options: Country[] = [
    { value: "KO", label: t("select.korean") },
    { value: "JP", label: t("select.japanese") },
    { value: "EN", label: t("select.anglosphere") },
  ];

  const [isShowOptions, setShowOptions] = useState(false);
  const [seletedValue, setSelectedValue] = useState(options[0].label);

  const selectBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleActive = (e: MouseEvent) => {
      if (
        selectBoxRef.current &&
        !selectBoxRef.current.contains(e.target as HTMLElement)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mouseup", handleActive);
    return () => {
      document.removeEventListener("mouseup", handleActive);
    };
  }, []);

  return (
    <SelectBox
      onClick={() => setShowOptions((prev) => !prev)}
      ref={selectBoxRef}
      id="country-selected-box"
    >
      <Label>{seletedValue}</Label>
      <SelectOptions $show={isShowOptions}>
        {options.map((option, index) => (
          <Option
            key={index}
            onClick={() => {
              setStateProps?.(option.value);
              setSelectedValue(option.label);
            }}
          >
            {option.label}
          </Option>
        ))}
      </SelectOptions>
    </SelectBox>
  );
};

const SelectBox = styled.div`
  position: relative;
  padding: 8px;
  border: 1px solid #c8cace;
  border-radius: 4px;
  background-color: #ffffff;
  align-self: center;
  cursor: pointer;
  &::before {
    content: url("/images/btn_arrow.svg");
    position: absolute;
    right: 10px;
    top: 5px;
    color: #c8cace;
    font-size: 20px;
    width: 16px;
    height: 16px;
    font-weight: 600;
  }
`;
const Label = styled.label`
  font-size: 14px;
  margin-left: 4px;
  text-align: center;
`;
const SelectOptions = styled.ul<{ $show: boolean }>`
  position: absolute;
  list-style: none;
  top: 40px;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 100px;
  max-height: ${(props) => (props.$show ? "none" : "0")};
  padding: 0;
  border-radius: 8px;
  background-color: #222222;
  color: #fefefe;
  z-index: 100;
`;
const Option = styled.li`
  font-size: 14px;
  padding: 6px 8px;
  transition: background-color 0.2s ease-in;
  &:hover {
    background-color: #595959;
  }
`;
export default React.memo(SelectBoxComponent);
