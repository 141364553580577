import { useEffect, useState } from "react";
import AddHeader from "../../components/Add/AddHeader";
import { BottomArrow } from "../../icons";
import StorageSelectBox from "../../components/Add/StorageSelectBox";
import { useLocation, useNavigate } from "react-router-dom";
import { toastError } from "../../utils/toast";
import { useCalculateAndFormatDates } from "../../hooks/use.calculate.date";
import axiosInstance, { requestAuth } from "../../utils/request/axios.instance";
import { AxiosError } from "axios";
import Skeleton from "react-loading-skeleton";
import useCalculateStorageCost from "../../hooks/use.calculate.storage.cost";
import useModifyStorage from "../../hooks/use.modify.storage.size";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";

interface LocationState {
  tenantId?: string;
  nextPaymentDate: string;
}

const StorageAddPage = () => {
  const [selectedStorage, setSelectedStorage] = useState<number>(1);

  const { t } = useTranslation();
  const location = useLocation();
  const state: LocationState = location.state as LocationState;
  const navigate = useNavigate();

  const [totalPrice, VAT] = useCalculateStorageCost(
    selectedStorage,
    state.nextPaymentDate
  );
  const [increasedStorageCapacity, putLoading] = useModifyStorage();
  const [loading, setLoading] = useState<boolean>(true);
  const [monetaryUnit, setMonetaryUnit] = useState<string>("원");

  const handleStorage = (storage: number) => {
    setSelectedStorage(storage);
  };

  const fetchStorageAddPUT = () => {
    increasedStorageCapacity({
      tenantId: state.tenantId!,
      storageSizeToAdd: selectedStorage,
      totalPrice: totalPrice,
    });
  };

  useEffect(() => {
    if (!state || !state.tenantId || !state.nextPaymentDate) {
      toastError(t("join.auth.fail"));
      navigate("/");
    }
    setLoading(true);
    axiosInstance
      .get(
        "/workspace/payment/add/price",
        requestAuth({
          params: {
            tenantId: state.tenantId,
          },
        })
      )
      .then((result) => {
        setLoading(false);

        setMonetaryUnit(
          result.data.data.monetaryUnit === "WON" ? t("add.unit") : "¥"
        );
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          navigate("/");
        }
      });
  }, [selectedStorage]);

  return (
    <>
      {putLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <article>
          <AddHeader />
          <section className="w-full flex flex-col items-center">
            <div className="w-[504px] flex flex-col items-center gap-4">
              <div className="w-full">
                <h3 className="text-[36px] leading-[52.13px] font-noto-sans-kr text-center font-bold mb-9">
                  {t("storage.title")}
                </h3>
                <StorageSelectBox
                  storage={selectedStorage}
                  onChange={handleStorage}
                />
              </div>

              <div className="w-full flex justify-between">
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  {t("add.productPrice")}
                </span>
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  {selectedStorage}TB X 100,000{monetaryUnit}
                </span>
              </div>

              <div className="w-full flex justify-between">
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  VAT(10%)
                </span>
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  {new Intl.NumberFormat("ko-KR").format(VAT)}
                  {monetaryUnit}
                </span>
              </div>

              <div className="border-solid w-full border-[1px] border-[#555555]"></div>

              <div className="w-full flex justify-between mb-3">
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  {t("add.totalPrice")}
                </span>
                <span className="text-[#555555] leading-[26.06px] text-[18px] font-noto-sans-kr">
                  {new Intl.NumberFormat("ko-KR").format(totalPrice)}
                  {monetaryUnit}
                </span>
              </div>

              <div className="flex flex-col w-full font-noto-sans-kr font-normal text-[14px] leading-[20.27px] text-[#555555] mb-5">
                {
                  <span>
                    {t("storage.storagePriceFront")}{" "}
                    {new Intl.NumberFormat("ko-KR").format(totalPrice)}
                    {monetaryUnit}
                    {t("storage.storagePriceBack")}
                  </span>
                }
              </div>

              <button
                className="w-[320px] h-[42px] bg-[#1F62B9] rounded-[4px] text-[#FFF] leading-[23.17px] text-[16px] font-bold"
                onClick={fetchStorageAddPUT}
              >
                {t("storage.title")}
              </button>
            </div>
          </section>
        </article>
      )}
    </>
  );
};

export default StorageAddPage;
