import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import configStore from "./redux";
import UnAuthorizedListener from "./hooks/use.unauthorized.listener";
import QueryProvider from "./components/QueryProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <QueryProvider>
    <BrowserRouter>
      <Provider store={configStore}>
        <App />
      </Provider>
      <UnAuthorizedListener />
    </BrowserRouter>
  </QueryProvider>
  // </React.StrictMode>
);

reportWebVitals();
