import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  DropDownWrapper,
  Header,
  LangMenu,
  LanguageItem,
  LoginButton,
  Logo,
  LogoImg,
  NavStyle,
  Navbar,
  Profile,
  ProfileButtom,
  ProfileInfo,
  ProfileWrapper,
  StyledMenuItem,
  TransparentButton,
} from "./Header.styles";
import { useSelect } from "downshift";
import { BiUserCircle } from "react-icons/bi";
import Loadable from "../Lodable";
import DropDown from "../DropDown";
import { Arrow, MenuList, UserIcon } from "../../icons";
import { CSSProp } from "styled-components";
import { useMediaQuery } from "react-responsive";
import { toastSuccess } from "../../utils/toast";
import Loader from "../Loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux";
import { fetchMemberInfo } from "../../redux/slice/memberSlice";
import { error } from "console";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import i18next from "i18next";

interface Props {
  toggleMenu: () => void;
}
const languages = ["Korean", "English", "Japanese"];

const Container = ({ toggleMenu }: Props) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<string>();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isScreenSM = useMediaQuery({ maxWidth: 768 });
  const [loginClick, setLoginClick] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [loginBtnState, setLoginBtnState] = useState<string>("inline-flex");

  const memberRequestInfo = useSelector(
    (state: RootState) => state.memberLogin
  );
  const dispatch = useDispatch<AppDispatch>();
  const menuItems = [
    t("workspace.myTeam"),
    t("common.logout"),
    t("common.payment"),
  ];

  const handleSelectedItemChange = (item: string | null) => {
    setSelectedItem(item!);
  };
  const handleOpenChange = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  const handleLoginClick = () => {
    if (pathname !== "/login") {
      setLoginClick((loginClick) => !loginClick);
      navigate("/login", { state: { fonrAuth: true } });
    } else {
    }
  };
  const handleLogout = useCallback((index: number) => {
    if (index === 1) {
      sessionStorage.removeItem("uc-portal-token");
      dispatch(fetchMemberInfo());
      navigate("/login");
    } else if (index === 0) {
      navigate("/team");
    } else if (index === 2) {
      navigate("/payment");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchMemberInfo());
    if (pathname !== "/login") {
      setLoginBtnState("inline-flex");
    } else {
      setLoginBtnState("none");
    }
  }, []);

  return (
    <Header>
      <section>
        <LogoImg
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          onClick={() => {
            navigate("/");
          }}
        />
      </section>

      <section className="flex justify-center items-center">
        {isScreenSM ? (
          <span onClick={toggleMenu} className="z-1500">
            <MenuList />
          </span>
        ) : (
          <div className="relative flex gap-[6px] justify-between">
            <DropDownWrapper>
              <DropDown
                items={languages}
                onChange={handleSelectedItemChange}
                type={"LANG"}
                onClick={handleOpenChange}
              >
                <span style={LangFont}>LANGUAGE</span>
                <span
                // className={`ml-2.5 transition-transform ${
                //   isOpen ? "rotate-180" : "rotate-0"
                // }`}
                >
                  <Arrow
                    override={{
                      width: "16px",
                      height: "16px",
                      marginTop: "2px",
                    }}
                  />
                </span>
              </DropDown>
            </DropDownWrapper>

            <>
              {memberRequestInfo.status !== "succeeded" ? (
                <Loader />
              ) : memberRequestInfo.authentication ? (
                <DropDown
                  items={menuItems}
                  type={"LOGIN"}
                  onChange={() => {}}
                  handleLogout={handleLogout}
                >
                  <UserIcon />
                </DropDown>
              ) : (
                <div className="pt-[2px]">
                  <LoginButton
                    $clicked={loginClick}
                    onClick={handleLoginClick}
                    $show={loginBtnState}
                  >
                    {t("common.login")}
                  </LoginButton>
                </div>
              )}
            </>
          </div>
        )}
      </section>
    </Header>
  );
};

const LangButtonStyle: CSSProperties = {
  backgroundColor: "white",
  border: "2px solid #0000FF",
  color: "#0000FF",
  padding: "8px 20px",
  borderRadius: "25px",
  cursor: "pointer",
  display: "inline-flex",
  alignItems: "center",
  flexShrink: "0",
  height: "36px",
};

const LangFont: CSSProperties = {
  color: "#1F62B9",
  textAlign: "center",
  fontFamily: "Noto Sans KR",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
};

const LoginFont: CSSProperties = {
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Noto Sans KR",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
};

export default Container;
