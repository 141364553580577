import { string } from "yup";
import axiosInstance, { requestAuth } from "./axios.instance";
import { AxiosError } from "axios";

type RequestProps<T> = {
  setLoading?: React.Dispatch<React.SetStateAction<T>>;
};

/**
 * @returns 토큰을 기반으로 현재 사용자의 결제 수단을 가져옴 (떙떙처리되어있음)
 */
export const paymentAdminPageFetchData = async ({
  setLoading,
}: RequestProps<boolean>) => {
  if (setLoading) setLoading(true);
  const response = await axiosInstance.get(
    "/payment/info/list",
    requestAuth({})
  );

  if (typeof response.data.data === "number") {
    //인증되지 않은 사용자일경우
    return {
      data: [],
    };
  }
  return response.data;
};

export const paymentCardInifoSave = async ({
  cardNumber,
  cardExpire,
  regNo,
  cardPw,
  cvc,
  buyerName,
  identification,
  setLoading,
}: RequestProps<boolean> & {
  cardNumber: string;
  cardExpire: string;
  regNo: string;
  cvc: string;
  cardPw: string;
  identification: string;
  buyerName: string;
}) => {
  if (setLoading) setLoading(true);
  const response = await axiosInstance.post(
    "/payment/inicis/billkey",
    {
      cardNumber: cardNumber,
      cardExpire: cardExpire,
      regNo: regNo,
      cardPw: cardPw,
      buyerName: buyerName,
      identification: identification,
      cvc: cvc,
    },
    requestAuth({})
  );

  return response.data;
};

export const billingDataSet = async ({
  setLoading,
  workspaceId,
}: RequestProps<boolean> & { workspaceId: string }) => {
  if (setLoading) setLoading(true);

  const response = await axiosInstance.post(
    "/payment/inicis/pay/data",
    {
      workspaceId: workspaceId,
    },
    requestAuth({})
  );

  return response.data;
};

/**
 * @note 라이센스 비용 평균 값 구하기 (라이센스 변경사항 값 포함함)
 */
export const averageLicensePayment = async ({
  workspaceId,
  setLoading,
}: RequestProps<boolean> & {
  workspaceId: number;
}) => {
  if (setLoading) setLoading(true);
  const response = await axiosInstance.post(
    "payment/license/avg",
    {
      workspaceId: workspaceId,
    },
    requestAuth({})
  );

  return response.data;
};

/**
 * @note 워크스페이스 생성 전 첫 결제 시도하는 api
 */
export const attemptToPayBeforeCreatingWorkspace = async ({
  selectedCardKey,
  personnelLimit,
  storage,
  license,
  setLoading,
}: RequestProps<boolean> & {
  selectedCardKey: string;
  personnelLimit: number;
  storage: number;
  license: "Free" | "Standard" | "Enterprise" | "NONE";
}) => {
  if (setLoading) setLoading(true);
  const response = await axiosInstance.post(
    "payment/card/pay",
    {
      cardKey: selectedCardKey,
      personnelLimit: personnelLimit,
      storage: storage,
      license: license,
    },
    requestAuth({})
  );

  return response.data;
};

export const paymentHistoryList = async (tenantId: string) => {
  try {
    const response = await axiosInstance.get(
      "/workspace/payment/history?tenantId=" + tenantId,
      requestAuth({})
    );

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return error.response?.status;
    }
    return -1;
  }
};
