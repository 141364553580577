import { createSlice } from "@reduxjs/toolkit";

interface CardInfoFormState {
  isOpen: boolean;
}

const initialState: CardInfoFormState = {
  isOpen: false,
};

const cardInfoFormSlice = createSlice({
  name: "cardInfoFormSlice",
  initialState,
  reducers: {
    cardFormOpen(state) {
      state.isOpen = true;
    },
    cardFormClose(state) {
      state.isOpen = false;
    },
  },
});

export const { cardFormOpen, cardFormClose } = cardInfoFormSlice.actions;
export default cardInfoFormSlice.reducer;
