import { useState, useCallback } from 'react';

const useCheckbox = () => {
  const [allChecked, setAllChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const handleAllCheckedChange = useCallback(() => {
    setAllChecked((prev) => {
      const newState = !prev;
      setTermsChecked(newState);
      setPrivacyChecked(newState);
      return newState;
    });
  }, []);

  const handleTermsCheckedChange = useCallback(() => {
    if(privacyChecked){
        setAllChecked(true);
    }
    if(termsChecked){
        setAllChecked(false);
    }

    setTermsChecked((prev) => {
        return !prev
    });
  }, [termsChecked,privacyChecked]);

  const handlePrivacyCheckedChange = useCallback(() => {
    if(privacyChecked){
        setAllChecked(false);
    }
    if(termsChecked){
        setAllChecked(true);
    }
    if(privacyChecked){
        setAllChecked(false);
    }
    setPrivacyChecked((prev) => {
        return !prev;
    });
  }, [privacyChecked,termsChecked]);

  const allCheckingChange = useCallback(() => {
     setAllChecked((prev) => {
   
        return prev;
     })
     updateAllCheckedState();
  }, []);

  const updateAllCheckedState = useCallback(() => {
    setAllChecked(termsChecked && privacyChecked);
  }, [termsChecked, privacyChecked]);

  return {
    allChecked,
    termsChecked,
    privacyChecked,
    handleAllCheckedChange,
    handleTermsCheckedChange,
    handlePrivacyCheckedChange,
    updateAllCheckedState,
  };
};

export default useCheckbox;
