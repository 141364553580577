import clsx from "clsx";
import { VscFoldDown } from "react-icons/vsc";
import Text from "../../ui/Text";
import CardNumberInput from "../Card/CardNumberInput";
import { cardRegisterSchema, useCardRegisterForm } from "../../utils/yupCustom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../ui/Input";
import { CardNumberForm } from "../../@types";
import ExpireDate from "../Card/ExpireDate";
import Cvc from "../Card/Cvc";
import CardPw from "../Card/CardPw";
import Button from "../../ui/Button";
import { useEffect, useState } from "react";
import { useEncrypt } from "../../hooks/use.encrypt";
import NameInput from "../Card/NameInput";
import { paymentCardInifoSave } from "../../utils/request";
import Loader from "../Loader";
import { toastError, toastSuccess } from "../../utils/toast";
import Identification from "../Card/Identification";
import axios from "axios";

interface Props {
  isVisible: boolean;
  setIsVisible: (state: boolean) => void;
}

const PaymentMethodBox = ({ isVisible, setIsVisible }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useForm<CardNumberForm>({
    mode: "onSubmit",
    resolver: yupResolver(cardRegisterSchema),
    defaultValues: {
      cardNumber: ["", "", "", ""],
      expireDate: ["", ""],
      cvc: "",
      buyerName: "",
      cardPw: "",
      identification: "",
    },
  });

  const { toEncrypt } = useEncrypt();

  const onSubmit = (data: CardNumberForm) => {
    setLoading(true);
    const unencryptedData = {
      cardNumber: data.cardNumber?.join("")!,
      expireDate: data.expireDate?.join("")!,
      cvc: data.cvc!,
      buyerName: data.buyerName!,
      cardPw: data.cardPw,
      identification: data.identification,
    };
    const encryptedData = toEncrypt(unencryptedData);

    const requestData = {
      cardNumber: encryptedData.cardNumber,
      cardExpire: encryptedData.expireDate,
      cvc: encryptedData.cvc,
      regNo: encryptedData.expireDate,
      buyerName: encryptedData.buyerName,
      cardPw: encryptedData.cardPw,
      identification: encryptedData.identification,
    };

    paymentCardInifoSave({ ...requestData, setLoading })
      .then((data) => {
        onClose();
        toastSuccess("카드가 등록되었습니다.");
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);

        if (axios.isAxiosError(error)) {
          if (error.response?.data.data === "EXISTS") {
            toastError("이미 존재하는 카드번호입니다.");
          } else if (error.response?.status === 400) {
            toastError(error.response?.data.data);
          } else {
            toastError("카드 등록 실패했습니다.");
          }
        } else {
          toastError("카드 등록 중 에러가 발생했습니다.");
        }
      });
  };

  const onClose = () => {
    setIsVisible(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }

    return () => {
      document.body.style.overflowY = "auto";
    };
  });

  return (
    <>
      <div
        className={clsx(
          "fixed w-full  bg-[rgba(0,0,0,.8)] inset-0 z-[600] overflow-hidden transform transition-transform duration-500",
          {
            hidden: !isVisible,
          }
        )}
      />
      <div
        className={clsx(
          "bg-white w-full h-[92%] md:h-full fixed bottom-0 transform transition-transform duration-500 shadow-workspace-name z-[601] flex items-center justify-center",
          {
            "translate-y-[100%]": !isVisible,
            "translate-y-[0%]": isVisible,
          }
        )}
      >
        <VscFoldDown
          size={30}
          onClick={onClose}
          className="mr-2 absolute w-full h-[30px] left-1/2 top-5 transform -translate-x-1/2 duration-500 cursor-pointer hover:scale-125"
        />

        <div className="p-0 w-2/3 md:w-[95%] h-3/4 flex flex-row justify-center px-20 md:px-1">
          {loading ? (
            <div className="h-full flex items-center">
              <Loader />
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="shadow-workspace-name w-[710px] h-[551px] flex flex-col items-center gap-1"
            >
              <Text className="font-bold text-[#555555] text-[19px] py-5">
                결제 수단 등록
              </Text>

              <div className="flex flex-col gap-01 w-[374px] md:w-[300px]">
                <Text className="text-left text-[#555555] text-[14px] font-extrabold">
                  카드번호
                </Text>

                <CardNumberInput
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  trigger={trigger}
                />
              </div>

              <div className="flex flex-col gap-1 w-[374px] md:w-[300px]">
                <Text className="text-left text-[#555555] text-[14px] font-extrabold">
                  유효기간
                </Text>
                <ExpireDate
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  trigger={trigger}
                />
              </div>
              <div className="flex flex-col gap-1 w-[374px] md:w-[300px]">
                <Text className="text-left text-[#555555] text-[14px] font-extrabold">
                  CVC
                </Text>
                <Cvc
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>
              <div className="flex flex-col gap-1 w-[374px] md:w-[300px]">
                <Text className="text-left text-[#555555] text-[14px] font-extrabold">
                  이름
                </Text>

                <NameInput
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>
              <div className="flex flex-col gap-1 w-[374px] md:w-[300px]">
                <Text className="text-left text-[#555555] text-[14px] font-extrabold">
                  카드 비밀번호
                </Text>

                <CardPw
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>
              <div className="flex flex-col gap-1 w-[374px] md:w-[300px]">
                <Text className="text-left text-[#555555] text-[14px] font-extrabold">
                  생년월일 또는 사업자번호
                </Text>

                <Identification
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>

              <div className="flex flex-row py-4 gap-8">
                <Button variant="secondary" onClick={() => onClose()}>
                  취소
                </Button>
                <Button variant="success" type="submit">
                  등록
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentMethodBox;
