import { useMediaQuery } from "react-responsive";
import ModalLayout from "./Layout";
import TermLayout from "./TermLayout";
import styled from "styled-components";
import { termsEN, termsJP, termsKO } from "../../utils/content";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";

const TermofService = ({ isOpen, onClose, height, width }: ModalProps & { height: string, width: string}) => {
    const isScreenSM = useMediaQuery({maxWidth: 768});
    const { i18n } = useTranslation();
    const scrollRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if(scrollRef.current && isOpen){
            scrollRef.current.scrollTop = 0;
        }
    }, [isOpen]);

    return (
        <TermLayout isOpen={isOpen} onClose={onClose} height={height} width={isScreenSM ? '400px' : width} isScreenSM={isScreenSM}>
            <div className={`flex flex-col items-center overflow-auto ${isScreenSM ? 'w-[400px]' : 'w-[580px]'} text-[14px] scroll_layout`} ref={scrollRef}>
                <div className={`px-7 ${isScreenSM ? '300px' : '400px'} h-[900px]`} dangerouslySetInnerHTML={{ __html: langTerms(i18n.language)}}>
                </div>
            </div>
            <div>
                <button className="w-[320px] h-[42px] bg-[#1F62B9] text-[#FFF] rounded-[4px] font-bold font-sans-kr" onClick={() => { onClose() }}>확인</button>
            </div>
        </TermLayout>
    )
}

const langTerms = (lang: string)  => {
    lang = lang.toUpperCase();
    if(lang === 'EN'){
        return termsEN;
    }else if(lang === 'JA'){
        return termsJP
    }else {
        return termsKO;
    }
}

const SubTitle = styled.div`
    font-weight: 700;
`;

export default TermofService;