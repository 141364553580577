import React, { useEffect } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/request/axios.instance";
import { error } from "console";
import { toastError } from "../../utils/toast";
import { useTranslation } from "react-i18next";

const AuthSuccessPage = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const code: string = searchParams.get("code")!;
  const fetchData = () => {
    if (code) {
      axiosInstance
        .get("/member/auth", {
          params: { code },
        })
        .then((data) => {
          sessionStorage.removeItem("uc-portal-token");
        })
        .catch((error) => {
          const statusCode = error.response?.data?.status;
          if (statusCode === 400) {
            toastError(t("join.auth.exist"));
          } else {
            toastError(t("join.auth.fail"));
          }
          navigate("/");
        });
    } else {
      toastError(t("join.auth.fail"));
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MainLayout imgSrc={"/images/auth_bg.png"}>
      <Wrapper>
        <H3>{t("join.auth.success")}</H3>
        <EmailText>{t("join.auth.congratulation")}</EmailText>
        <Link to={"/login"}>
          <LinkSpan
            dangerouslySetInnerHTML={{
              __html: t("join.auth.goToLogin"),
            }}
          ></LinkSpan>
        </Link>
      </Wrapper>
    </MainLayout>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  a {
    text-decoration-line: none;
  }
`;

const H3 = styled.h3`
  color: #000;
  font-family: Noto Sans KR;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 25px;

  @media (max-width: 590px) {
    font-size: 22px;
  }
`;

const EmailText = styled.div`
  color: #555;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
  margin-top: 30px;
  @media (max-width: 590px) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const LinkSpan = styled.span`
  color: #1f62b9;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export default AuthSuccessPage;
